angular.module("vgresiduos").directive("linkUserGrid", function () {
	return {
		scope: true,
		controller: "LinkUserGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/user/linkGrid/linkUserGrid_template.html",
		bindToController: {
			linkModel: "=",
			gridOptions: "="
		}
	};
});
