angular.module("vgresiduos").directive("masterUserCustomizedFilter", function () {
	return {
		scope: true,
		controller: "MasterUserCustomizedFilterCtrl",
		controllerAs: "ctrl",
		templateUrl:
			"views/masterUser/masterUserList/components/masterUserCustomizedFilter/masterUserCustomizedFilter_template.html",
		bindToController: {
			isFilterActive: "=",
			onFilter: "=",
			onCleanFilters: "=",
			hasActiveFilters: "="
		}
	};
});
