angular.module("vgresiduos").controller("AuditCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"$stateParams",
	"$state",
	"httpService",
	"dialogService",
	"auditService",
	"accountService",
	"fileService",
	"clientService",
	"supplierService",
	"preferencesService",
	"reportService",
	function (
		$rootScope,
		$scope,
		$q,
		$stateParams,
		$state,
		httpService,
		dialogService,
		auditService,
		accountService,
		fileService,
		clientService,
		supplierService,
		preferencesService,
		reportService
	) {
		"use strict";

		const fileOptionsDeferred = $q.defer();

		$scope.labels = $rootScope.labels;
		$scope.supplierCode = $stateParams.supplierCode;
		$scope.corporateOrigin = $stateParams.corporateOrigin;
		let supplierId = $stateParams.supplierId;
		$scope.hasDocumentCreatePermission = false;
		$scope.auditId = $stateParams.auditId;
		const currentClient = accountService.getCurrentClient();
		$scope.isSupplier = clientService.isSupplier();
		let sendEmailOnCompleteAudit = false;
		$scope.auditPermission = false;
		$scope.isDisableSupplierTypeSelect = false;
		$scope.isDisableClientSelect = false;
		$scope.isDisableLevelsSelect = false;
		$scope.isAuditSupplierOnlyByOrganization = false;

		this.$onInit = function () {
			if ($scope.corporateOrigin) {
				loadSupplierPreferences();
				httpService
					.getDTOFromServiceV2(supplierService.getOrganizationSupplierByCode, {
						supplierCode: $scope.supplierCode,
						includeProfiles: true
					})
					.then(function (supplier) {
						$scope.supplierProfileIds = supplier.profiles.map((p) => p.id);
						supplierId = supplier.supplierId;
						loadSupplierAuditById(supplier);
					});
			} else {
				loadSupplierAuditById();
			}
		};

		function initializeContentNavBar() {
			$rootScope.contentNavBar = {
				hasPermission: function () {
					return $scope.auditPermission;
				},
				title: $scope.labels.AUDIT,
				breadcrumb: $scope.auditId,
				removeButtonBar: true,
				save: function () {
					if (!$scope.isSupplier) {
						if (validateAuditToSave()) {
							return processAudit();
						} else {
							$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.DEFAULT_VALIDATION_MESSAGE);
							return $q.reject();
						}
					} else {
						return processAudit();
					}
				},
				onSaveError: function () {
					//Nothing to be done
				},
				back: function () {
					internalGoBack(true);
				},
				valid: function () {
					return $scope.audit.ExpirationDate;
				},
				customButtons: [
					{
						label: $scope.labels.PRINT,
						buttonClasses: "md-primary",
						icon: "print",
						supressToast: true,
						onClick: printAudit
					},
					{
						label: $scope.labels.REQUEST_TO_SUPPLIER,
						buttonClasses: "md-primary",
						notRaised: true,
						icon: "arrow_forward",
						supressToast: true,
						hasPermission: function () {
							return $scope.auditPermission && isOwner() && !isSelfAudit();
						},
						onClick: function () {
							$scope.popupType = Vgr.enumerations.audit.Notification.SupplierSolicitation;
							const dialog = dialogService
								.showDialogFromTemplateWithScope(
									null,
									"views/audit/popups/auditNotificationPopup_template.html",
									"auditNotificationPopupCtrl",
									$scope,
									"#supplier-solicitation-popup",
									false,
									function () {
										//Nothing to be done
									}
								)
								.then(
									function () {
										//Nothing to be done
									},
									function () {
										//Nothing to be done
									}
								);
							$scope.dialog = dialog;
							const deferred = $q.defer();
							deferred.resolve();
							return deferred.promise;
						}
					},
					{
						label: $scope.labels.AUDIT_CONTROL_BACK,
						buttonClasses: "md-primary",
						notRaised: true,
						icon: "edit",
						supressToast: true,
						onClick: function () {
							const deferred = $q.defer();

							transferAuditControl().then(function () {
								deferred.resolve();
								reloadPage();
							});

							return deferred.promise;
						}
					},
					{
						label: $scope.labels.SEND_AUDIT_BACK_TO_GENERATOR,
						buttonClasses: "md-primary",
						notRaised: true,
						icon: "arrow_forward",
						supressToast: true,
						hasPermission: function () {
							return isAuditedSupplier() && $scope.audit.Status == Vgr.enumerations.audit.Status.WaitingSupplier;
						},
						onClick: function () {
							const deferred = $q.defer();
							$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, {
								text: $rootScope.labels.SEND_AUDIT_BACK_CONFIRMATION
							});
							deferred.resolve();
							return deferred.promise;
						}
					},
					{
						label: $scope.labels.EFFECT,
						buttonClasses: "md-primary",
						icon: "check",
						supressToast: true,
						onClick: function () {
							const deferred = $q.defer();
							$rootScope.$broadcast(Vgr.constants.evtConfirmOperation, {
								text: $rootScope.labels.EFFECT_AUDIT_CONFIRMATION,
								id: $scope.auditId
							});
							deferred.resolve();
							return deferred.promise;
						}
					}
				],
				buttons: []
			};
			configureAvailableActions();
		}

		function printAudit() {
			const dto = {
				auditId: $scope.auditId,
				filters: {
					Extension: Vgr.enumerations.reportExtension.Pdf
				}
			};

			return httpService.getDTOFromServiceV2(reportService.generateClientAuditDocument, dto).then(
				function (data) {
					const fileDTO = Vgr.util.fileDtoToFile(data.File);
					saveAs(fileDTO, fileDTO.FileName);
				},
				function () {
					//Nothing to be done
				}
			);
		}

		function transferAuditControl() {
			const deferred = $q.defer();
			const dto = {
				AuditId: $scope.auditId
			};

			httpService.postDTOToServiceV2(auditService.transferAuditControl, dto).then(function () {
				deferred.resolve();
			});

			return deferred.promise;
		}

		function loadSupplierAuditById(supplier) {
			httpService.getDTOFromServiceV2(auditService.getSupplierAuditById, $scope.auditId).then(
				function (data) {
					loadDates(data.Audit);
					$scope.audit = data.Audit;
					$scope.setterResponsible = buildSetterUser($scope.audit.Responsible);
					$scope.setterSupervisor = $scope.audit.Supervisor ? buildSetterUser($scope.audit.Supervisor) : null;
					$scope.setterSupplierContactId = $scope.audit.SupplierContact ? $scope.audit.SupplierContact.Id : null;

					$scope.isDisableLevelsSelect = $scope.audit.Status != Vgr.enumerations.audit.Status.Pending;
					$scope.audit.ValidForGroup =
						$scope.audit.OrganizationUnitId == null || $scope.audit.OrganizationUnitId == undefined;
					$scope.audit.ValidForAllProfiles = $scope.audit.ProfileIds == undefined || !$scope.audit.ProfileIds?.length;
					$scope.isDisableClientSelect = $scope.audit.ValidForGroup;
					$scope.isDisableSupplierTypeSelect = $scope.audit.ValidForAllProfiles;

					fileOptionsDeferred.resolve($scope.audit.Attachments);

					checkAuditStatus();
					$scope.isOwner = isOwner();
					configurePermissions(supplier);
					initializeContentNavBar();
					$scope.loadingFinished = true;
				},
				function () {
					$scope.goBack();
				}
			);
		}

		function configurePermissions(supplier) {
			$scope.auditPermission = false;
			if ($scope.isSupplier) {
				if (isAuditedSupplier()) {
					//Fornecedor acessando auditoria própria ou
					//Fornecedor acessando auditoria do gerador para ele na situação aguardando fornecedor
					$scope.auditPermission =
						$scope.isOwner || $scope.audit.Status == Vgr.enumerations.audit.Status.WaitingSupplier;
				}
			} else if (isOwner() && supplier) {
				//Gerador acessando auditoria
				$scope.hasDocumentCreatePermission = supplierService.hasSupplierPermission(
					supplier,
					Vgr.systemPermissions.SupplierOrganizationDocument,
					Vgr.systemPermissions.SupplierDocument
				);
				$scope.auditPermission = auditService.hasPermission($scope.audit, supplier);
			}
		}

		function buildSetterUser(user) {
			return {
				id: user.IdentityId,
				code: user.Id,
				name: user.Name
			};
		}

		function configureAvailableActions() {
			$rootScope.contentNavBar.buttons = [];

			$scope.canEditGeneralData = false;
			$scope.canEditChecklist = false;

			if (isOwner()) {
				if ($scope.auditPermission) {
					if ($scope.audit.Status == Vgr.enumerations.audit.Status.Pending) {
						$rootScope.contentNavBar.buttons.push($scope.labels.PRINT);

						$rootScope.contentNavBar.buttons.push($scope.labels.REQUEST_TO_SUPPLIER);
						$rootScope.contentNavBar.buttons.push($scope.labels.EFFECT);
						$rootScope.contentNavBar.buttons.push($scope.labels.SAVE);

						$scope.canEditGeneralData = true;
						$scope.canEditChecklist = true;
					} else if ($scope.audit.Status == Vgr.enumerations.audit.Status.WaitingSupplier) {
						$rootScope.contentNavBar.buttons.push($scope.labels.PRINT);

						$rootScope.contentNavBar.buttons.push($scope.labels.AUDIT_CONTROL_BACK);
					} else if ($scope.audit.Status != Vgr.enumerations.audit.Status.Canceled) {
						$rootScope.contentNavBar.buttons.push($scope.labels.PRINT);

						$rootScope.contentNavBar.buttons.push($scope.labels.SAVE);

						$scope.canEditGeneralData = true;
						$scope.canEditChecklist = true;
					}
				}
			} else if (isAuditedSupplier()) {
				if ($scope.audit.Status == Vgr.enumerations.audit.Status.WaitingSupplier) {
					$rootScope.contentNavBar.buttons.push($scope.labels.SEND_AUDIT_BACK_TO_GENERATOR);
					$rootScope.contentNavBar.buttons.push($scope.labels.SAVE);

					$scope.canEditChecklist = true;
				}
			}

			if ($rootScope.contentNavBar.buttons.length > 0) {
				$rootScope.contentNavBar.removeButtonBar = false;
			}
		}

		function isSelfAudit() {
			return $scope.audit && $scope.audit.IsSelfAudit;
		}

		function isOwner() {
			return $scope.audit && auditService.isOwner($scope.audit, currentClient);
		}

		function isAuditedSupplier() {
			return $scope.audit.AuditedClientId == currentClient.id;
		}

		function processAudit() {
			const deferred = $q.defer();
			const auditDTO = buildAuditDTO();
			httpService.postDTOToServiceV2(auditService.updateSupplierAudit, auditDTO).then(
				function () {
					reloadPage();
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function reloadPage() {
			$state.go(
				"auditEdit",
				{
					supplierCode: $scope.supplierCode,
					auditId: $scope.auditId,
					corporateOrigin: $scope.corporateOrigin
				},
				{
					reload: true
				}
			);
		}

		function buildAuditDTO() {
			const dto = {
				auditId: $scope.audit.Id,
				Attachments: $scope.fileOptions.fileList,
				toCancel: false,
				toApply: false,
				organizationUnitId: $scope.audit.Unit ? $scope.audit.Unit.id : undefined,
				audit: $scope.audit
			};
			if (dto.audit.SupplierContact) {
				dto.audit.SupplierContact.Id = dto.audit.SupplierContact.id;
			}

			if (dto.audit.ValidForAllProfiles) {
				dto.audit.ProfileIds = [];
			}

			if (dto.audit.ValidForGroup) {
				dto.audit.OrganizationUnitId = undefined;
			}
			return dto;
		}

		function loadDates(audit) {
			audit.ExpirationDate = new Date(audit.ExpirationDate);
			audit.AuditDate = new Date(audit.AuditDate);
		}

		$scope.canShowDashboard = function () {
			if (!$scope.audit) {
				return false;
			}

			return (
				$scope.audit.Status != Vgr.enumerations.audit.Status.Pending &&
				$scope.audit.Status != Vgr.enumerations.audit.Status.WaitingSupplier
			);
		};

		$scope.goBack = function () {
			internalGoBack(false);
		};

		function internalGoBack(forceAuditTab) {
			if ($scope.isSupplier) {
				if ($scope.audit.IsDefault) {
					$state.go("transporterEditWithContext", {
						id: currentClient.id,
						context: Vgr.enumerations.supplier.tabPageRoute.audits
					});
				} else {
					$state.go("transporterEditWithContext", {
						id: currentClient.id,
						context: Vgr.enumerations.supplier.tabPageRoute.clientAudits
					});
				}
			} else {
				$state.go("supplierEdit", {
					id: supplierId,
					tab: !forceAuditTab && $scope.corporateOrigin == "actionPlan" ? 7 : 6
				});
			}
		}

		function loadSupplierPreferences() {
			preferencesService.getSupplierPreferences().then(
				function (preferences) {
					sendEmailOnCompleteAudit = preferences && preferences.auditSendEmailOnComplete;
					$scope.isAuditSupplierOnlyByOrganization = preferences && preferences.auditSupplierOnlyByOrganization;
					$scope.ValidForGroup = $scope.isAuditSupplierOnlyByOrganization;
				},
				function () {
					sendEmailOnCompleteAudit = false;
				}
			);
		}

		$scope.toggleDateInput = function () {
			$scope.dateInputEnabled = !$scope.dateInputEnabled;
		};

		function checkAuditStatus() {
			$scope.isStatusWaitingSupplier = $scope.audit.Status == Vgr.enumerations.audit.Status.WaitingSupplier;
		}

		$scope.$on(Vgr.constants.evtConfirmYes, function (event, data) {
			if (data && data.text == $rootScope.labels.EFFECT_AUDIT_CONFIRMATION && data.id) {
				if (sendEmailOnCompleteAudit) {
					$scope.popupType = Vgr.enumerations.audit.Notification.Effect;
					const dialog = dialogService
						.showDialogFromTemplateWithScope(
							null,
							"views/audit/popups/auditNotificationPopup_template.html",
							"auditNotificationPopupCtrl",
							$scope,
							"#supplier-solicitation-popup",
							false,
							function () {
								//Nothing to be done
							}
						)
						.then(
							function () {
								//Nothing to be done
							},
							function () {
								//Nothing to be done
							}
						);
					$scope.dialog = dialog;
				} else {
					processAudit().then(function () {
						const dto = {
							auditId: data.id
						};

						httpService.postDTOToServiceV2(auditService.applySupplierAudit, dto).then(function () {
							$scope.goBack();
						});
					});
				}
			} else if (data && data.text == $rootScope.labels.SEND_AUDIT_BACK_CONFIRMATION) {
				processAudit().then(function () {
					transferAuditControl().then(function () {
						$scope.goBack();
					});
				});
			}
		});

		$scope.fileOptions = {
			fileService: "mixed",
			get: function () {
				return fileOptionsDeferred.promise;
			},
			update: function (newFiles, deletedIds) {
				const deferred = $q.defer();
				const path = "Audits/" + $scope.audit.Id;
				fileService.uploadAll(newFiles, path).then(
					function (response) {
						deferred.resolve(response);
					},
					function () {
						deferred.reject();
					}
				);
				return deferred.promise;
			},
			fileList: []
		};

		$scope.onChangeValidForAllUnits = function () {
			$scope.isDisableClientSelect = !$scope.isDisableClientSelect;

			if ($scope.isDisableClientSelect) {
				$scope.audit.OrganizationUnitId = null;
			}
		};

		$scope.onChangeOrganizationUnit = function (newVal) {
			$scope.audit.OrganizationUnitId = newVal ? newVal.id : undefined;
		};

		$scope.onChangeValidForAllProfiles = function () {
			$scope.isDisableSupplierTypeSelect = !$scope.isDisableSupplierTypeSelect;

			if ($scope.isDisableSupplierTypeSelect) {
				$scope.audit.ProfileIds = [];
			}
		};

		$scope.onChangeProfiles = function (selected) {
			$scope.audit.Profiles = selected.map((profile) => profile.id);
		};

		const validateAuditToSave = function () {
			return (
				($scope.audit.Unit || $scope.audit.ValidForGroup) &&
				$scope.audit.Level &&
				($scope.audit.Profiles?.length || $scope.audit.ValidForAllProfiles)
			);
		};

		$scope.showActiveAudit = function () {
			return (
				$scope.canEditGeneralData &&
				($scope.audit.Status == Vgr.enumerations.audit.Status.Valid ||
					$scope.audit.Status == Vgr.enumerations.audit.Status.Expired ||
					$scope.audit.Status == Vgr.enumerations.audit.Status.Inactive)
			);
		};
	}
]);
