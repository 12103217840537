function config(
	$stateProvider,
	$urlRouterProvider,
	$locationProvider,
	$httpProvider,
	NotificationProvider,
	$provide,
	applicationInsightsServiceProvider
) {
	"use strict";

	// eslint-disable-next-line no-undef
	moment.locale("pt-br");
	$locationProvider.html5Mode(true).hashPrefix("#");
	$urlRouterProvider.when("", "/");
	$urlRouterProvider.otherwise("/error");
	$stateProvider
		.state("home", {
			cache: false,
			url: "/",
			templateUrl: "views/home/home.html",
			isFree: true
		})
		.state("outdatedVersion", {
			url: "/atualizando",
			templateUrl: "views/outdatedVersion/outdatedVersion.html",
			noAuth: true
		})
		.state("error", {
			url: "/erro",
			templateUrl: "views/Error/error.html",
			isFree: true
		})
		.state("blockedPage", {
			url: "/upgrade",
			templateUrl: "views/blockedPage/blockedPage_template.html",
			isFree: true
		})
		//---------------------------------------------------------------------------------------------------//
		///////////////////////////////////////////LOGIN AND ACCOUNT///////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("accountEdit", {
			url: "/meus-dados",
			templateUrl: "views/newAngular/onboarding/user/account/edit/editAccount.html",
			newSingleton: true
		})
		.state("register", {
			url: "/registro?perfil&nome&email&usuario&telefone",
			templateUrl: "views/register/register.html",
			noPadding: true,
			isFree: true,
			noAuth: true
		})
		.state("changeTemporaryPassword", {
			url: "/alterar-senha",
			templateUrl: "views/register/changeTemporaryPassword.html",
			noPadding: true,
			isFree: true
		})
		.state("completeSignup", {
			url: "/completar-cadastro",
			templateUrl: "views/register/completeSignUp.html",
			noPadding: true,
			isFree: true
		})
		.state("registerConfirmation", {
			url: "/confirmacao-registro",
			templateUrl: "views/register/registerConfirmation.html",
			noPadding: true,
			isFree: true,
			noAuth: true,
			forceLogout: true
		})
		.state("login", {
			url: "/login?username&error&showBack",
			templateUrl: "views/login/login.html",
			noPadding: true,
			isFree: true,
			noAuth: true
		})
		.state("chooseLogin", {
			url: "/login-sso?idp&error&appLogin",
			templateUrl: "views/login/loginOptions.html",
			noPadding: true,
			isFree: true,
			noAuth: true
		})
		.state("forgottenPassword", {
			url: "/resetar",
			templateUrl: "views/login/forgottenPassword.html",
			noPadding: true,
			isFree: true,
			noAuth: true
		})
		.state("resetPassword", {
			url: "/nova-senha",
			templateUrl: "views/login/resetPassword.html",
			noPadding: true,
			isFree: true,
			noAuth: true,
			forceLogout: true,
			params: {
				username: null
			}
		})
		.state("firstLogin", {
			cache: false,
			url: "/inicio/:welcome?profile",
			templateUrl: "views/dashboard/dashboard.html"
		})
		.state("afterLoginIdentityProvider", {
			url: "/autenticacao",
			templateUrl: "views/afterLoginIdentityProvider/afterLoginIdentityProvider.html"
		})
		.state("loginResponse", {
			url: "/login-response",
			templateUrl: "views/afterLoginIdentityProvider/loginResponse.html",
			noAuth: true
		})
		//---------------------------------------------------------------------------------------------------//
		///////////////////////////////////////////LOGIN AND ACCOUNT///////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		////////////////////////////////////////////////SYSTEM/////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("featureToggleList", {
			url: "/master/feature-toggles",
			templateUrl: "views/newAngular/master/featureToggles/list/featureToggleList.html",
			isMaster: true,
			newSingleton: true
		})
		.state("featureToggleEdit", {
			url: "/master/feature-toggles/:id",
			templateUrl: "views/featureToggle/edit/featureToggleEdit.html",
			isMaster: true
		})
		//---------------------------------------------------------------------------------------------------//
		////////////////////////////////////////////////SYSTEM/////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////RESIDUE MARKET////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("residueMarketNew", {
			url: "/mercado/home",
			templateUrl: "views/newAngular/residueMarket/residueMarketHome.html",
			noPadding: true,
			isFree: true,
			newSingleton: true
		})
		.state("residueMarketIndicationRequestRedirect", {
			url: "/mercado/redirect",
			templateUrl: "views/newAngular/residueMarket/residueMarketRedirect.html",
			noPadding: true,
			isFree: true,
			newSingleton: true
		})
		.state("residueMarketIndicationRequestCreateProspective", {
			url: "/mercado/criar-solicitacao-prospect",
			templateUrl: "views/newAngular/residueMarket/indicationRequest/indicationRequestProspectiveCreate.html",
			noPadding: true,
			isFree: true,
			newSingleton: true
		})
		.state("residueMarketIndicationRequestCreate", {
			url: "/mercado/criar-solicitacao",
			templateUrl: "views/newAngular/residueMarket/indicationRequest/indicationRequestCreate.html",
			noPadding: true,
			isFree: true,
			newSingleton: true
		})
		.state("residueMarketIndicationRequestEdit", {
			url: "/mercado/solicitacao-indicacao/:id",
			templateUrl: "views/newAngular/residueMarket/indicationRequest/indicationRequestEdit.html",
			noPadding: true,
			isFree: true,
			newSingleton: true
		})
		.state("residueMarketBackOfficeSupplierListGeneration", {
			url: "/mercado/back-office/quadro-de-demanda",
			templateUrl: "views/newAngular/residueMarket/backOffice/supplierListGeneration.html",
			noPadding: true,
			isMaster: true,
			newSingleton: true
		})
		.state("residueMarketBackOfficeSuccess", {
			url: "/mercado/back-office/sucesso",
			templateUrl: "views/newAngular/residueMarket/backOffice/residueMarketSuccess.html",
			noPadding: true,
			isMaster: true,
			newSingleton: true
		})
		.state("residueMarketBackOfficeListSupplierByRegion", {
			url: "/mercado/back-office/fornecedores-por-regiao",
			templateUrl: "views/newAngular/residueMarket/backOffice/listSupplierByRegion.html",
			noPadding: true,
			isMaster: true,
			newSingleton: true
		})
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////RESIDUE MARKET////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////SUPPLIER VIEW/////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("homeSupplier", {
			cache: false,
			url: "/inicio-fornecedor",
			templateUrl: "views/home/homeSuppliers.html",
			isFree: true
		})
		.state("documentList", {
			url: "/documentos",
			templateUrl: "views/newAngular/userSupplier/documents/documentList.html",
			isFree: true,
			newSingleton: true
		})
		.state("documentEdit", {
			url: "/documentos/:documentId",
			templateUrl: "views/newAngular/userSupplier/documents/documentEdit.html",
			isFree: true,
			newSingleton: true
		})
		.state("documentCreate", {
			url: "/documentos-cadastro?documentType",
			templateUrl: "views/newAngular/userSupplier/documents/documentCreate.html",
			isFree: true,
			newSingleton: true
		})
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////SUPPLIER VIEW/////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		////////////////////////////////////////////////SUPPLIERS//////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("transporterList", {
			url: "/fornecedores?pendencia",
			templateUrl: "views/transporter/list/transporterList.html"
		})
		.state("transporterEdit", {
			url: "/fornecedor/:id",
			templateUrl: "views/transporter/edit/transporterEdit.html",
			isFree: true
		})
		.state("transporterEditWithContext", {
			url: "/fornecedor/:id/:context",
			templateUrl: "views/transporter/edit/transporterEdit.html",
			isFree: true
		})
		.state("auditEdit", {
			url: "/fornecedor/:supplierId/auditoria/:auditId?supplierCode&corporateOrigin",
			templateUrl: "views/audit/audit_template.html",
			isFree: true
		})
		.state("actionPlanEdit", {
			url: "/fornecedor/:supplierId/plano-de-acao/:actionPlanId?supplierCode&corporateOrigin",
			templateUrl: "views/actionPlan/actionPlan_template.html",
			isFree: true
		})
		.state("supplierList", {
			url: "/organizacao/fornecedores?pendencia&unidade&vincular",
			templateUrl: "views/newAngular/suppliers/list/supplierList.html",
			newSingleton: true
		})
		.state("supplierCreate", {
			url: "/organizacao/cadastrar-fornecedor?preselectUnit",
			templateUrl: "views/newAngular/suppliers/create/supplierCreate.html",
			newSingleton: true
		})
		.state("supplierEdit", {
			url: "/organizacao/fornecedores/:id?tab",
			templateUrl: "views/newAngular/suppliers/edit/supplierEdit.html",
			newSingleton: true
		})
		.state("newOrganizationAuthDocument", {
			url: "/organizacao/fornecedores/:supplierCode/documento?docType&supDocId&requestId",
			templateUrl: "views/newAngular/suppliers/documents/create/createAuthDocument.html",
			newSingleton: true
		})
		.state("supplierLinkUnit", {
			url: "/organizacao/fornecedores/:id/vincular-unidade",
			templateUrl: "views/newAngular/suppliers/link-unit/supplierLinkUnit.html",
			newSingleton: true
		})
		.state("editSupplierDocument", {
			url: "/organizacao/fornecedores/:supplierCode/documento/:docId?supDocId&requestId",
			templateUrl: "views/newAngular/suppliers/documents/edit/editAuthDocument.html",
			newSingleton: true
		})
		.state("editSupplierContact", {
			url: "/organizacao/fornecedores/:supplierCode/contato/:contactId?supplierId",
			templateUrl: "views/newAngular/suppliers/contact/edit/editContact.html",
			newSingleton: true
		})
		.state("createSupplierContact", {
			url: "/organizacao/fornecedores/:supplierCode/contato?supplierId",
			templateUrl: "views/newAngular/suppliers/contact/create/createContact.html",
			newSingleton: true
		})
		.state("editSupplierTransporterVehicle", {
			url: "/organizacao/fornecedores/:supplierCode/veiculo/:vehicleId?supplierId",
			templateUrl: "views/newAngular/suppliers/vehicle/edit/editVehicle.html",
			newSingleton: true
		})
		.state("createSupplierTransporterVehicle", {
			url: "/organizacao/fornecedores/:supplierCode/veiculo?supplierId",
			templateUrl: "views/newAngular/suppliers/vehicle/create/createVehicle.html",
			newSingleton: true
		})
		.state("editSupplierTransporterDriver", {
			url: "/organizacao/fornecedores/:supplierCode/condutor/:driverId?supplierId",
			templateUrl: "views/newAngular/suppliers/driver/edit/editDriver.html",
			newSingleton: true
		})
		.state("createSupplierTransporterDriver", {
			url: "/organizacao/fornecedores/:supplierCode/condutor?supplierId",
			templateUrl: "views/newAngular/suppliers/driver/create/createDriver.html",
			newSingleton: true
		})
		.state("listDocumentType", {
			url: "/organizacao/fornecedores/tipos-documento",
			templateUrl: "views/newAngular/suppliers/documentTypes/listDocumentType.html",
			newSingleton: true
		})
		.state("listSupplierProfile", {
			url: "/organizacao/fornecedores/perfis",
			templateUrl: "views/newAngular/suppliers/profiles/listSupplierProfile.html",
			newSingleton: true
		})
		.state("editDocumentType", {
			url: "/organizacao/fornecedores/tipos-documento/:id",
			templateUrl: "views/newAngular/suppliers/documentTypes/editDocumentType.html",
			newSingleton: true
		})
		.state("editSupplierProfile", {
			url: "/organizacao/fornecedores/perfis/:id",
			templateUrl: "views/newAngular/suppliers/profiles/editSupplierProfile.html",
			newSingleton: true
		})
		//---------------------------------------------------------------------------------------------------//
		///////////////////////////////////////////////SUPPLIERS///////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		//////////////////////////////////////////////ONBOARDING///////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("clientOrgEdit", {
			cache: false,
			url: "/master/organizacao",
			templateUrl: "views/client/clientOrgEdit/clientOrgEdit_template.html",
			isMaster: true
		})
		.state("organizationEdit", {
			url: "/organizacao",
			templateUrl: "views/organization/organizationEdit_template.html",
			isFree: true
		})
		.state("unitList", {
			url: "/organizacao/unidades",
			templateUrl: "views/newAngular/onboarding/organizationUnits/list/organizationUnitList.html",
			isFree: true,
			newSingleton: true
		})
		.state("organizationAdditionalPropertiesList", {
			url: "/organizacao/informacoes-adicionais",
			templateUrl: "views/newAngular/onboarding/properties/list/propertiesList.html",
			isFree: true,
			newSingleton: true
		})
		.state("organizationAdditionalPropertiesEdit", {
			url: "/organizacao/informacoes-adicionais/:id",
			templateUrl: "views/additionalProperties/additionalPropertiesEdit/additionalPropertiesEdit_template.html",
			isFree: true
		})
		.state("organizationAdditionalPropertiesCreate", {
			url: "/organizacao/informacoes-adicionais/novo",
			templateUrl: "views/additionalProperties/additionalPropertiesCreate/additionalPropertiesCreate_template.html",
			isFree: true
		})
		.state("residueOrganizationList", {
			url: "/organizacao/residuos",
			templateUrl: "views/newAngular/onboarding/residues/list/residuesList.html",
			newSingleton: true
		})
		.state("areaOrganizationList", {
			url: "/organizacao/areas",
			templateUrl: "views/newAngular/onboarding/areas/list/areaList.html"
		})
		.state("userList", {
			url: "/organizacao/usuarios?tipo",
			templateUrl: "views/user/userList/userList.html",
			isFree: true
		})
		.state("userPermissions", {
			url: "/organizacao/usuarios/:id/permissoes",
			templateUrl: "views/user/userPermissionList/userPermissionList.html",
			isFree: true
		})
		.state("organizationPermissionList", {
			url: "/organizacao/perfis-acesso",
			templateUrl: "views/newAngular/onboarding/permissions/list/permissionGroupList.html",
			isFree: true,
			newSingleton: true
		})
		.state("organizationPermissionEdit", {
			url: "/organizacao/perfis-acesso/:id",
			templateUrl: "views/permission/permissionGroupEdit_template.html",
			isFree: true
		})
		.state("clientEdit", {
			url: "/unidade/:id?tab",
			templateUrl: "views/client/clientEdit/clientEdit.html",
			isFree: true
		})
		.state("emergencySheetPhones", {
			url: "/unidade/telefones-ficha-emergencia",
			templateUrl: "views/residue/emergencySheetPhones/emergencySheetPhones.html"
		})
		.state("residueList", {
			url: "/unidade/residuos",
			templateUrl: "views/newAngular/onboarding/residues/list/organizationUnitResidueList.html",
			newSingleton: true
		})
		.state("residueEdit", {
			url: "/unidade/residuo/:id",
			templateUrl: "views/residue/edit/residueEdit.html"
		})
		.state("recipientList", {
			url: "/unidade/acondicionamentos",
			templateUrl: "views/newAngular/onboarding/recipients/list/recipientList.html",
			newSingleton: true
		})
		.state("recipientEdit", {
			url: "/unidade/acondicionamento/:id",
			templateUrl: "views/recipient/recipientEdit/recipientEdit.html"
		})
		.state("unitAreaList", {
			url: "/unidade/areas",
			templateUrl: "views/newAngular/onboarding/areas/list/organizationUnitAreaList.html",
			newSingleton: true
		})
		.state("areaEdit", {
			url: "/unidade/area/:id",
			templateUrl: "views/area/edit/areaEdit.html"
		})
		.state("unitPermissionList", {
			url: "/unidade/perfis-acesso",
			templateUrl: "views/newAngular/onboarding/permissions/list/organizationUnitPermissionGroupList.html",
			isFree: true,
			newSingleton: true
		})
		.state("unitPermissionEdit", {
			url: "/unidade/perfis-acesso/:id",
			templateUrl: "views/permission/permissionGroupEdit_template.html",
			isFree: true
		})
		.state("productEdit", {
			url: "/unidade/produto/:id",
			templateUrl: "views/product/productEdit/productEdit.html"
		})
		.state("productList", {
			url: "/unidade/produtos",
			templateUrl: "views/newAngular/onboarding/processes/products/list/productList.html",
			newSingleton: true
		})
		.state("rawMaterialList", {
			url: "/unidade/materias-primas",
			templateUrl: "views/newAngular/onboarding/processes/rawMaterials/list/rawMaterialList.html",
			newSingleton: true
		})
		.state("rawMaterialEdit", {
			url: "/unidade/materia-prima/:id",
			templateUrl: "views/newAngular/onboarding/processes/rawMaterials/edit/rawMaterialEdit.html",
			newSingleton: true
		})
		.state("processStepList", {
			url: "/unidade/etapas",
			templateUrl: "views/newAngular/onboarding/processes/processSteps/list/processStepList.html",
			newSingleton: true
		})
		.state("processStepEdit", {
			url: "/unidade/etapa/:id",
			templateUrl: "views/newAngular/onboarding/processes/processSteps/edit/processStepEdit.html",
			newSingleton: true
		})
		.state("processList", {
			url: "/unidade/processos",
			templateUrl: "views/newAngular/onboarding/processes/processes/list/processList.html",
			newSingleton: true
		})
		.state("processEdit", {
			url: "/unidade/processo/:id",
			templateUrl: "views/newAngular/onboarding/processes/processes/edit/processEdit.html",
			newSingleton: true
		})
		.state("masterUserList", {
			url: "/master/usuarios",
			templateUrl: "views/masterUser/masterUserList/masterUserList.html",
			isMaster: true
		})
		.state("masterUserEdit", {
			url: "/master/usuarios/:id",
			templateUrl: "views/masterUser/masterUserEdit/masterUserEdit.html",
			isMaster: true
		})
		//---------------------------------------------------------------------------------------------------//
		//////////////////////////////////////////////ONBOARDING///////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		///////////////////////////////////////////////PRICING/////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("pricingCreate", {
			url: "/master/preco-manual?duplicar",
			templateUrl: "views/pricing/form/pricingForm_template.html",
			isMaster: true
		})
		.state("pricingEdit", {
			url: "/master/preco-manual/:id",
			templateUrl: "views/pricing/form/pricingForm_template.html",
			isMaster: true
		})
		.state("pricingList", {
			url: "/master/precos-manuais",
			templateUrl: "views/pricing/pricings_template.html",
			isMaster: true
		})
		.state("pricingConsult", {
			url: "/consulta-de-precos",
			templateUrl: "views/pricing/consult/pricingConsult_template.html"
		})
		//---------------------------------------------------------------------------------------------------//
		///////////////////////////////////////////////PRICING/////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////////BI////////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("dashboard", {
			cache: false,
			url: "/dashboard",
			templateUrl: "views/dashboard/dashboard.html"
		})
		.state("customReport", {
			url: "/bi/relatorios",
			templateUrl: "views/customReports/customReports_template.html"
		})
		.state("customReportCreate", {
			url: "/bi/relatorios/novo",
			templateUrl: "views/customReports/customReportCreate/customReportCreate_template.html"
		})
		.state("customReportEdit", {
			url: "/bi/relatorios/:reportId?modo",
			templateUrl: "views/customReports/customReportEdit/customReportEdit_template.html"
		})
		.state("genericKpiReport", {
			url: "/bi/relatorios/kpi",
			templateUrl: "views/customReports/genericKpiReport/genericKpiReport_template.html"
		})
		.state("relativeKpis", {
			url: "/bi/relatorios/kpi-relativo",
			templateUrl: "views/report/relativeKpis/relativeKpis_template.html"
		})
		.state("goalsReport", {
			url: "/bi/relatorios/metas",
			templateUrl: "views/report/goals/goalsReport_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(
					Vgr.systemPermissions.GoalView,
					Vgr.constants.featureToggles.permission.goalModule,
					null
				);
			}
		})
		.state("cadriBalanceReport", {
			url: "/bi/relatorios/saldo-cadri",
			templateUrl: "views/report/residue/cadriBalanceReport.html"
		})
		.state("cadriBalanceCorporateReport", {
			url: "/bi/relatorios/saldo-cadri-corporativo",
			templateUrl: "views/report/residue/cadriBalanceReport.html"
		})
		.state("generatingManagementInventoryReport", {
			url: "/bi/relatorios/inventario-gerencial",
			templateUrl: "views/report/residue/generatingManagementInventoryReport.html"
		})
		.state("generatingCorporateManagementInventoryReport", {
			url: "/bi/relatorios/inventario-gerencial-corporativo",
			templateUrl: "views/report/residue/generatingManagementInventoryReport.html"
		})
		.state("residueMovimentationReport", {
			url: "/bi/relatorios/movimentacao-residuos",
			templateUrl: "views/report/residue/movimentationReport.html"
		})
		.state("residueMovimentationReportCorporate", {
			url: "/bi/relatorios/movimentacao-residuos-corporativo",
			templateUrl: "views/report/residue/movimentationReportCorporate.html"
		})
		.state("residueTraceabilityReport", {
			url: "/bi/relatorios/relatorio-rastreabilidade-residuos",
			templateUrl: "views/newAngular/businessIntelligence/residueTraceabilityReport/residueTraceabilityReport.html"
		})
		.state("bi", {
			url: "/bi",
			templateUrl: "views/newAngular/businessIntelligence/embed-powerbi/embed-powerbi.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, null, Vgr.constants.featureToggles.release.biModule);
			},
			newSingleton: true
		})
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////////BI////////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////////ESG///////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("dashboardEsg", {
			url: "/painel-esg",
			templateUrl: "views/newAngular/businessIntelligence/esg/dashboard/dashboardEsg.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.esgModule);
			},
			newSingleton: true
		})
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////////ESG///////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		////////////////////////////////////////////////GOALS//////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("goalsList", {
			url: "/metas",
			templateUrl: "views/goals/list/goalsList_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(
					Vgr.systemPermissions.GoalView,
					Vgr.constants.featureToggles.permission.goalModule,
					null
				);
			}
		})
		.state("goalsEdit", {
			url: "/metas/:year/:residueCode",
			templateUrl: "views/goals/edit/goalsEdit_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(
					Vgr.systemPermissions.GoalView,
					Vgr.constants.featureToggles.permission.goalModule,
					null
				);
			}
		})
		//---------------------------------------------------------------------------------------------------//
		////////////////////////////////////////////////GOALS//////////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////DECLARATIONS//////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("dnitReport", {
			url: "/declaracoes/dnit",
			templateUrl: "views/report/dnit/dnitReport_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.dnitIntegration);
			}
		})
		.state("conamaInvetoryReport", {
			url: "/declaracoes/conama313/inventario",
			templateUrl: "views/report/conama313/inventoryReport.html"
		})
		.state("ibamaReport", {
			url: "/declaracoes/ibama-rapp/relatorio",
			templateUrl: "views/report/ibama/ibamaReport_template.html"
		})
		.state("ibamaRappCreate", {
			url: "/declaracoes/ibama-rapp/integracao/novo",
			templateUrl: "views/ibama/ibamaRappCreate/ibamaRappCreate_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.ibamaRappIntegration);
			}
		})
		.state("ibamaRappList", {
			url: "/declaracoes/ibama-rapp/integracao/acompanhamento?ano",
			templateUrl: "views/ibama/ibamaRappList/ibamaRappList_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.ibamaRappIntegration);
			}
		})
		.state("ibamaRappFollowup", {
			url: "/declaracoes/ibama-rapp/integracao/acompanhamento/:id",
			templateUrl: "views/ibama/ibamaRappFollowup/ibamaRappFollowup_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.ibamaRappIntegration);
			}
		})
		.state("dmrReport", {
			url: "/declaracoes/dmr",
			templateUrl: "views/newAngular/residuesManagement/declarations/dmrReport/dmrReport.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(Vgr.systemPermissions.GenerateDmr);
			},
			newSingleton: true
		})
		//---------------------------------------------------------------------------------------------------//
		/////////////////////////////////////////////DECLARATIONS//////////////////////////////////////////////
		//---------------------------------------------------------------------------------------------------//
		.state("internalGatheringList", {
			url: "/historico-geracao?residuo&nomeResiduo&tab",
			templateUrl: "views/newAngular/residuesManagement/storages/list/storageList_template.html",
			newSingleton: true
		})
		.state("internalGatheringEdit", {
			url: "/geracao/:id",
			templateUrl: "views/newAngular/residuesManagement/storages/edit/storageEdit_template.html",
			newSingleton: true
		})
		.state("internalGatheringCreateByModel", {
			url: "/geracao?modelId",
			templateUrl: "views/newAngular/residuesManagement/storages/createByModel/storageCreateByModel_template.html"
		})
		.state("internalGatheringCreate", {
			url: "/geracao",
			templateUrl: "views/newAngular/residuesManagement/storages/create/storageCreate_template.html",
			getAccess: function (userAccessService) {
				const canCreateNewResidueGeneration =
					userAccessService.hasAccess(Vgr.systemPermissions.CreateAndEditResiduesGenerationInAllAreas) ||
					userAccessService.hasAccess(Vgr.systemPermissions.CreateAndEditResiduesGenerationInUserAreas);

				return canCreateNewResidueGeneration ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
			},
			newSingleton: true
		})
		.state("clientDestinationReport", {
			url: "/controle-destinacoes",
			templateUrl: "views/disposalHistory/disposalHistory_template.html"
		})
		.state("destinationNew", {
			url: "/destinacao?modelo",
			templateUrl: "views/destination/destination_template.html"
		})
		.state("disposalFollowup", {
			url: "/acompanhamento-destinacoes",
			templateUrl: "views/destinationFollowup/destinationFollowup_template.html"
		})
		.state("mtrFollowUp", {
			url: "/acompanhamento-mtrs",
			templateUrl: "views/newAngular/residuesManagement/disposals/mtrFollowUp/mtrFollowUp.html",
			newSingleton: true
		})
		.state("disposalHistory", {
			url: "/historico-destinacoes",
			templateUrl: "views/disposalHistory/disposalHistory_template.html"
		})
		.state("destinationEdit", {
			url: "/destinacao/:id?createdFromDisposalModel?forceIntegrations",
			templateUrl: "views/destination/destination_template.html"
		})
		.state("disposalModels", {
			url: "/modelo-destinacoes?modelo",
			templateUrl: "views/newAngular/residuesManagement/disposals/disposalModels/disposalModels.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, Vgr.constants.featureToggles.release.disposalModels);
			},
			newSingleton: true
		})
		.state("destinationEditModel", {
			url: "/modelo-destinacoes/:id",
			templateUrl: "views/newAngular/residuesManagement/disposals/disposalModels/edit/disposalEditModels.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(
					Vgr.systemPermissions.CrudDisposalModels,
					Vgr.constants.featureToggles.release.disposalModels
				);
			},
			newSingleton: true
		})
		.state("destinationCreateModel", {
			url: "/modelo-destinacoes/novo?modelo?disposal",
			templateUrl: "views/newAngular/residuesManagement/disposals/disposalModels/create/disposalCreateModels.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(
					Vgr.systemPermissions.CrudDisposalModels,
					Vgr.constants.featureToggles.release.disposalModels
				);
			},
			newSingleton: true
		})
		.state("createDisposalFromModel", {
			url: "/modelo-destinacoes/:id/nova-destinacao",
			templateUrl:
				"views/newAngular/residuesManagement/disposals/disposalModels/createDisposal/createDisposalFromModel.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(Vgr.systemPermissions.CreateEditDeleteDisposalUnfinished, null);
			},
			newSingleton: true
		})
		.state("destinatorDisposalManifestList", {
			url: "/acompanhamento-destinador",
			templateUrl: "views/newAngular/residuesManagement/destinator/destinatorModule.html",
			noPadding: true,
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(
					Vgr.systemPermissions.DestinatorDisposalManifestDestinatorView,
					Vgr.constants.featureToggles.permission.destinatorModule
				);
			},
			newSingleton: true
		})
		.state("disposalLoads", {
			url: "/importacao-destinacoes",
			templateUrl: "views/newAngular/residuesManagement/disposals/disposalLoads/disposalLoads.html",
			noPadding: true,
			getAccess: function (userAccessService, permissionService) {
				return (
					userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.disposalLoadScreen) &&
					permissionService.isMaster()
				);
			},
			newSingleton: true
		})
		.state("residuesManagement", {
			url: "/gestao-residuos?tab",
			templateUrl: "views/newAngular/residuesManagement/lowerGeneration/residuesManagement_template.html",
			getAccess: function (userAccessService) {
				return userAccessService.getAccess(null, Vgr.constants.featureToggles.permission.lowerGenerationPlan, null);
			},
			newSingleton: true
		})
		.state("storageList", {
			url: "/listagem-geracoes?residuo&nomeResiduo&tab",
			templateUrl: "views/newAngular/residuesManagement/storages/list/storageList_template.html",
			newSingleton: true
		})
		.state("storageCreate", {
			url: "/criar-geracao",
			templateUrl: "views/newAngular/residuesManagement/storages/create/storageCreate_template.html",
			getAccess: function (userAccessService) {
				const canCreateNewResidueGeneration =
					userAccessService.hasAccess(Vgr.systemPermissions.CreateAndEditResiduesGenerationInAllAreas) ||
					userAccessService.hasAccess(Vgr.systemPermissions.CreateAndEditResiduesGenerationInUserAreas);

				return canCreateNewResidueGeneration ? Vgr.enumerations.userAccess.Enabled : Vgr.enumerations.userAccess.Hidden;
			},
			newSingleton: true
		})
		.state("storageListInternalTransfer", {
			url: "/movimentacao-interna/:id/listagem-geracoes",
			templateUrl:
				"views/newAngular/residuesManagement/storages/listInternalTransfer/storageListInternalTransfer_template.html",
			newSingleton: true
		})
		.state("storageEdit", {
			url: "/editar-geracao/:id",
			templateUrl: "views/newAngular/residuesManagement/storages/edit/storageEdit_template.html",
			newSingleton: true
		})
		.state("storageStore", {
			url: "/armazenar-geracao/:id",
			templateUrl: "views/newAngular/residuesManagement/storages/store/storageStore_template.html",
			newSingleton: true
		});

	applicationInsightsServiceProvider.configure({
		instrumentationKey: Vgr.constants.applicationInsightsKey,
		applicationName: "PortalAngularJs"
	});
	// this is in order to work on ie. Without this, ajax calls behave unexpectedly
	if (!$httpProvider.defaults.headers.get) {
		$httpProvider.defaults.headers.common = {};
	}
	$httpProvider.defaults.headers.common["Cache-Control"] = "no-cache";
	$httpProvider.defaults.headers.common.Pragma = "no-cache";
	$httpProvider.defaults.headers.common["If-Modified-Since"] = "Sat, 01 Jan 2000 00:00:00 GMT";

	$httpProvider.interceptors.push("responseErrorInterceptor");
	$httpProvider.interceptors.push("cultureHeaderInjector");
	$httpProvider.interceptors.push("buildVersionInjector");
	$httpProvider.interceptors.push("organizationHeaderInjector");
	$httpProvider.interceptors.push("vgrProtectedResourceInterceptor");

	NotificationProvider.setOptions({
		positionX: "left",
		positionY: "bottom",
		priority: 1000,
		maxCount: 1
	});

	$provide.decorator("mdAutocompleteDirective", mdAutoCompleteDirectiveOverride);

	mdAutoCompleteDirectiveOverride.$inject = ["$delegate"];

	function mdAutoCompleteDirectiveOverride($delegate) {
		const directive = $delegate[0];
		// need to append to base compile function
		const compile = directive.compile;

		// add our custom attribute to the directive's scope
		angular.extend(directive.scope, {
			menuContainerClass: "@?mdMenuContainerClass"
		});

		// recompile directive and add our class to the virtual repeat container
		directive.compile = function (element, attr) {
			const template = compile.apply(this, arguments);
			const menuContainerClass = attr.mdMenuContainerClass ? attr.mdMenuContainerClass : "";
			const menuContainer = element.find("md-virtual-repeat-container");

			menuContainer.addClass(menuContainerClass);

			// recompile the template
			return function () {
				template.apply(this, arguments);
			};
		};

		return $delegate;
	}

	$provide.decorator("$q", [
		"$delegate",
		function ($delegate) {
			const $q = $delegate;

			// Extention for q
			$q.allSettled =
				$q.allSettled ||
				function (promises) {
					const deferred = $q.defer();
					if (angular.isArray(promises)) {
						const states = [];
						const results = [];
						let didAPromiseFail = false;
						if (promises.length === 0) {
							deferred.resolve(results);
							return deferred.promise;
						}

						// First create an array for all promises with their state
						angular.forEach(promises, function (promise, key) {
							states[key] = false;
						});

						// Helper to check if all states are finished
						const checkStates = function (states, results, deferred, failed) {
							let allFinished = true;
							angular.forEach(states, function (state, key) {
								if (!state) {
									allFinished = false;
								}
							});
							if (allFinished) {
								if (failed) {
									deferred.reject(results);
								} else {
									deferred.resolve(results);
								}
							}
						};

						// Loop through the promises
						// a second loop to be sure that checkStates is called when all states are set to false first
						angular.forEach(promises, function (promise, key) {
							$q.when(promise).then(
								function (result) {
									states[key] = true;
									results[key] = result;
									checkStates(states, results, deferred, didAPromiseFail);
								},
								function (reason) {
									states[key] = true;
									results[key] = reason;
									didAPromiseFail = true;
									checkStates(states, results, deferred, didAPromiseFail);
								}
							);
						});
					} else {
						throw "allSettled can only handle an array of promises (for now)";
					}

					return deferred.promise;
				};

			return $q;
		}
	]);
}

angular
	.module("vgresiduos")
	.config([
		"$stateProvider",
		"$urlRouterProvider",
		"$locationProvider",
		"$httpProvider",
		"NotificationProvider",
		"$provide",
		"applicationInsightsServiceProvider",
		config
	])
	.run([
		"$rootScope",
		"$state",
		"accountService",
		"userAccessService",
		"permissionService",
		function ($rootScope, $state, accountService, userAccessService, permissionService) {
			"use strict";

			$rootScope.$on("$stateChangeError", function () {
				console.log("$stateChangeError - fired when an error occurs during transition.");
			});

			$rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams) {
				$rootScope.$broadcast(Vgr.constants.evtStartLoadingPage);

				$rootScope.$state = $state;
				const isLoggedInIdentityServer = accountService.isLoggedInIdp();

				$rootScope.paddingOnView = !toState.noPadding;
				$rootScope.contentNavBar = false;
				$rootScope.contentNavBarGrid = false;
				$rootScope.onlyContent = false;
				$rootScope.storagePermissionsToHide = [];

				$rootScope.storagePermissionsToHide.push(Vgr.systemPermissions.ViewGathering.Id);
				$rootScope.storagePermissionsToHide.push(Vgr.systemPermissions.CreateEditDeleteGathering.Id);

				if (toState.redirectTo) {
					$state.go(toState.redirectTo);
					event.preventDefault();
					return;
				}

				if (toState.forceLogout && isLoggedInIdentityServer) {
					accountService.logout();
				}

				if (toState.name == "loginResponse") {
					// commented. Error on login sso. This was added in case user with sso is deactivated, but not working properly. Fix.
					// if (!isLoggedInIdentityServer) {
					//     accountService.goToLogin(null, true);
					//     event.preventDefault();
					//     return;
					// }

					$rootScope.loginWithExternalIdentityProvider = true;
				}

				if (!toState.newSingleton) {
					$rootScope.$broadcast("REDIRECT_FRAME_SINGLETON", { route: Vgr.constants.newAngularUrl });
				}

				if (!isLoggedInIdentityServer) {
					const sso = getUrlParameter("idp");
					if (toState.name == "home" && sso) {
						//permite logar automaticamente quando é SSO e chama o endereço da home com o parametro de idp
						accountService.loginWithFederatedIdentity(sso);
						event.preventDefault();
						return;
					}
					if (!toState.noAuth) {
						accountService.goToLogin();
						event.preventDefault();
						return;
					}
				}

				if (isLoggedInIdentityServer) {
					if (
						accountService.isLogoutInProgress() ||
						toState.name == "completeSignup" ||
						toState.name == "changeTemporaryPassword"
					) {
						return;
					}

					//logou na AWS cognito mas nao logou o usuario na plataforma
					if (
						toState.name != "afterLoginIdentityProvider" &&
						(!accountService.getLoggedAccount() || !accountService.getCurrentClient())
					) {
						$state.go("afterLoginIdentityProvider");
						event.preventDefault();
						return;
					}

					if (toState.noAuth) {
						accountService.goHome();
						event.preventDefault();
						return;
					}

					if (toState.getAccess) {
						const access = toState.getAccess(userAccessService, permissionService);
						if (access == Vgr.enumerations.userAccess.Disabled) {
							$state.go("blockedPage");
							event.preventDefault();
							return;
						}
						if (access == Vgr.enumerations.userAccess.Hidden) {
							accountService.goHome();
							event.preventDefault();
							return;
						}
					}

					if (!toState.isFree && !toState.isMaster && accountService.isMarketProfile()) {
						$state.go("blockedPage");
						event.preventDefault();
						return;
					}
				}
			});

			function getUrlParameter(param, dummyPath) {
				const sPageURL = dummyPath || window.location.search.substring(1);
				const sURLVariables = sPageURL.split(/[&||?]/);
				let res;
				for (let i = 0; i < sURLVariables.length; i += 1) {
					const paramName = sURLVariables[i],
						sParameterName = (paramName || "").split("=");

					if (sParameterName[0] === param) {
						res = sParameterName[1];
					}
				}

				return res;
			}

			$rootScope.$on("$stateChangeSuccess", function (event, state) {
				$rootScope.$broadcast(Vgr.constants.evtFinishLoadingPage);
			});
		}
	]);
