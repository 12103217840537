angular.module("vgresiduos").factory("residuesGenerationService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		const _getResidueGenerationModel = function (residueGenerationModelId) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "get",
				url:
					Vgr.constants.residuesManagementHostUrl +
					Vgr.resources.residuesGeneration.model
						.replace("[ORGANIZATION_ID]", organizationId)
						.replace("[ORGANIZATION_UNIT]", accountService.getCurrentClient().code)
						.replace("[RESIDUE_GENERATION_MODEL_ID]", residueGenerationModelId)
			};
			return $http(req);
		};

		return {
			getResidueGenerationModel: _getResidueGenerationModel
		};
	}
]);
