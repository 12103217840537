angular.module("componentes").controller("CustomReportGridCtrl", [
	"$rootScope",
	"$scope",
	"httpService",
	"customReportService",
	"accountService",
	"dialogService",
	"$state",
	"userAccessService",
	function (
		$rootScope,
		$scope,
		httpService,
		customReportService,
		accountService,
		dialogService,
		$state,
		userAccessService
	) {
		"use strict";

		$scope.labels = $rootScope.labels;
		let loggedUser;

		this.$onInit = function () {
			loggedUser = accountService.getLoggedAccount();

			$scope.grid = {};

			defineColumns();
			defineRowActions();
			loadReports();
		};

		function defineColumns() {
			$scope.grid.columns = [
				{
					headerDescription: $rootScope.labels.TYPE,
					width: 50,
					getIcon: function (row) {
						return row && row.typeImage;
					},
					getTooltip: function (row) {
						return row && row.typeDescription;
					}
				},
				{
					headerDescription: $rootScope.labels.REPORT_NAME,
					property: "description"
				},
				{
					headerDescription: $rootScope.labels.CREATED_BY,
					property: "creationUser.name"
				},
				{
					headerDescription: $rootScope.labels.LAST_EDITION,
					property: "updateDate",
					type: Vgr.enumerations.gridSimple.columnType.date
				}
			];
		}

		function defineRowActions() {
			$scope.grid.defaultActions = null;

			$scope.grid.actionButtons = [
				{
					label: $scope.labels.VIEW,
					icon: "visibility",
					onClick: function (item) {
						$state.go("customReportEdit", { reportId: item.id });
					}
				},
				{
					label: $scope.labels.EDIT,
					icon: "edit",
					hasPermission: function (item) {
						return (
							item.creationUser.id === loggedUser.code ||
							userAccessService.hasAccess(Vgr.systemPermissions.BiModuleReportEdit, null, null)
						);
					},
					onClick: function (item) {
						$state.go("customReportEdit", { reportId: item.id, modo: "edicao" });
					}
				},
				{
					label: $scope.labels.DELETE,
					icon: "delete",
					hasPermission: function (item) {
						return (
							item.creationUser.id === loggedUser.code ||
							userAccessService.hasAccess(Vgr.systemPermissions.BiModuleReportDelete, null, null)
						);
					},
					onClick: function (item) {
						openConfirmationPopup(
							$rootScope.labels.DELETE_REPORT_POPUP.TITLE,
							$rootScope.labels.DELETE_REPORT_POPUP.DESCRIPTION,
							[item],
							customReportService.deleteReport
						);
					}
				}
			];
		}

		function loadReports() {
			$scope.grid.loading = true;
			httpService.getDTOFromServiceV2(customReportService.listReports).then(
				function (list) {
					for (const iterator of list) {
						iterator.typeDescription = $rootScope.labels.SYSTEM_REPORT_TYPE_ENUM[iterator.type];
						iterator.typeImage = getTypeImage(iterator.type);
					}

					$scope.grid.data = list;
					$scope.grid.loading = false;
				},
				function () {
					$scope.grid.loading = false;
				}
			);
		}

		function getTypeImage(type) {
			if (type === Vgr.enumerations.customReport.type.Kpi) {
				return "img/icons/awesome-chart-bar.png";
			} else if (type === Vgr.enumerations.customReport.type.Goal) {
				return "img/icons/open-target.png";
			}
		}

		function openConfirmationPopup(title, text, list, method) {
			let params = {
				localVariables: {
					popupTitle: title,
					popupText: text,
					list: list,
					operationMethod: method,
					idField: "id",
					descriptionField: "description",
					confirmLabel: null,
					keepPopupOpenOnFinish: false
				}
			};

			let reportId = list[0].id;

			let promise = dialogService.showDialogFromTemplateV2(
				"views/components/popups/operationConfirmationPopup_template.html",
				"OperationConfirmationPopupCtrl",
				null,
				params,
				true
			);
			promise.then(function () {
				$scope.grid.data = $scope.grid.data.filter((report) => report.id != reportId);

				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
			});
			return promise;
		}
	}
]);
