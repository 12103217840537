angular.module("vgresiduos").directive("residueOrganizationSimpleGrid", function () {
	return {
		scope: true,
		controller: "residueOrganizationSimpleGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/residue/components/simpleGrid/residueOrganizationSimpleGrid_template.html",
		bindToController: {
			list: "=",
			residuesSwitch: "=?"
		}
	};
});
