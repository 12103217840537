angular.module("vgresiduos").controller("ClientResiduesPricesCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$scope.columns = [
			{
				field: "TimeSpan",
				displayName: function () {
					return $rootScope.labels.INTERVAL;
				},
				width: 20
			},
			{
				field: "Type",
				displayName: function () {
					return $rootScope.labels.TYPE;
				},
				width: 30
			},
			{
				field: "State",
				displayName: function () {
					return $rootScope.labels.STATE;
				},
				width: 30
			},
			{
				field: "DisposalTecnology",
				displayName: function () {
					return $rootScope.labels.DISPOSAL_TECH;
				},
				width: 30
			},
			{
				field: "Price",
				displayName: function () {
					return $rootScope.labels.PRICE;
				},
				width: 30
			}
		];

		$scope.gridOptions = {
			columnDefinitions: $scope.columns,
			filterFields: ["TimeSpan", "Type", "State", "DisposalTecnology", "Price"],
			tagList: [],
			title: "Not yet defined",
			query: {
				order: "-TimeSpan",
				limit: 10,
				page: 1,
				serverPagination: true,
				onPaginate: function (page, limit, query, column, order, tagList, dateFilter) {
					$scope.gridOptions.query.page = page;
					$scope.gridOptions.query.limit = limit;
					loadList(query, column, order, tagList, dateFilter);
				},
				onReorder: function (query, column, order, tagList, dateFilter) {
					loadList(query, column, order, tagList, dateFilter);
				},
				pageSize: 20
			},
			onFilter: function (query, column, order, tagList, dateFilter) {
				loadList(query, column, order, tagList, dateFilter);
			},
			data: [],
			editState: "", //yet to define
			promise: {},
			hasData: false,
			errorLoading: false,
			cantDelete: function (item) {
				return item.Status === "DESTINADO" || item.Status === "PARCIALMENTE DESTINADO";
			}
		};
	}
]);
