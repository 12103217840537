angular.module("vgresiduos").directive("supplierSituationFilter", function () {
	return {
		scope: true,
		controller: "SupplierSituationFilterCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/transporter/components/grid/general/supplierSituationFilter_template.html",
		bindToController: {
			situationFilterOptions: "=?",
			gridOptions: "=?"
		}
	};
});
