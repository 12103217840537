angular.module("vgresiduos").directive("auditDashboardClassification", function () {
	return {
		scope: true,
		controller: "AuditDashboardClassificationCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/audit/dashboard/auditDashboardClassification_template.html",
		bindToController: {
			audit: "="
		}
	};
});
