angular.module("vgresiduos").directive("actionPlanGrid", function () {
	return {
		scope: true,
		controller: "ActionPlanGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/transporter/components/grid/actionPlan/actionPlanGrid_template.html",
		bindToController: {
			supplierId: "="
		}
	};
});
