angular.module("vgresiduos").factory("dashboardService", [
	"$http",
	function ($http) {
		"use strict";

		var reportService = {};

		var _getHoursSaved = function GetHoursSaved() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetHoursSaved"
			};
			return $http(req);
		};

		var _getLicensesToExpire = function GetLicensesToExpire() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetLicensesToExpire"
			};
			return $http(req);
		};

		var _getUsersMoreAccesses = function GetUsersMoreAccesses() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetUsersMoreAccesses"
			};
			return $http(req);
		};

		var _getUserAccessCount = function GetUserAccessCount() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetUserAccessCount"
			};
			return $http(req);
		};

		var _getResiduesGenerated = function GetResiduesGenerated() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetResiduesGenerated"
			};
			return $http(req);
		};

		var _getResiduesDestinated = function GetResiduesDestinated() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetResiduesDestinated"
			};
			return $http(req);
		};

		var _getExpenseSummary = function GetExpenseSummary() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetExpenseSummary"
			};
			return $http(req);
		};

		var _getExpenseManagement = function GetExpenseManagement(dto) {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetExpenseManagement",
				data: dto
			};
			return $http(req);
		};

		var _getOpportunities = function GetOpportunities() {
			var req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.dashboard + "GetOpportunities"
			};
			return $http(req);
		};

		reportService.getHoursSaved = _getHoursSaved;
		reportService.getResiduesDestinated = _getResiduesDestinated;
		reportService.getResiduesGenerated = _getResiduesGenerated;
		reportService.getUserAccessCount = _getUserAccessCount;
		reportService.getUsersMoreAccesses = _getUsersMoreAccesses;
		reportService.getLicensesToExpire = _getLicensesToExpire;
		reportService.getExpenseSummary = _getExpenseSummary;
		reportService.getExpenseManagement = _getExpenseManagement;

		reportService.getOpportunities = _getOpportunities;

		return reportService;
	}
]);
