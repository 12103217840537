angular.module("vgresiduos").component("permissionGroupSelectField", {
	controller: "PermissionGroupSelectFieldCtrl",
	templateUrl: "views/components/fields/permissionGroupSelectField/permissionGroupSelectField_template.html",
	bindings: {
		isDisabled: "<",
		unitCode: "<?",
		model: "=",
		excluded: "<?",
		isRequired: "<?",
		permissionType: "<"
	}
});
