angular.module("vgresiduos").factory("masterService", [
	"$http",
	function ($http) {
		"use strict";

		const masterService = {};

		//users
		const _listUsers = function (params) {
			const parameters = params ? $.param(params) : "";

			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "?" + parameters
			};

			return $http(req);
		};

		const _getUser = function (userId) {
			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "/" + userId + "?loadFullData=true"
			};

			return $http(req);
		};

		const _updateUser = function (dto) {
			const req = {
				method: "put",
				data: dto,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "/" + dto.id
			};

			return $http(req);
		};

		const _forceLogoutUser = function (userId) {
			const req = {
				method: "put",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "/" + userId + "/logout"
			};

			return $http(req);
		};

		const _enableUser = function (userId) {
			const req = {
				method: "post",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "/" + userId + "/active"
			};

			return $http(req);
		};

		const _disableUser = function (userId) {
			const req = {
				method: "delete",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "/" + userId + "/active"
			};

			return $http(req);
		};

		const _resetPasswordUser = function (userId) {
			const req = {
				method: "post",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "/" + userId + "/reset-password"
			};

			return $http(req);
		};

		const _newPasswordUser = function (dto) {
			const req = {
				method: "post",
				data: dto.password,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterUsers + "/" + dto.id + "/new-password"
			};

			return $http(req);
		};

		masterService.listUsers = _listUsers;
		masterService.getUser = _getUser;
		masterService.updateUser = _updateUser;
		masterService.forceLogoutUser = _forceLogoutUser;
		masterService.enableUser = _enableUser;
		masterService.disableUser = _disableUser;
		masterService.resetPasswordUser = _resetPasswordUser;
		masterService.newPasswordUser = _newPasswordUser;

		return masterService;
	}
]);
