angular.module("vgresiduos").controller("productionGridCtrl", [
	"$scope",
	"productV2Service",
	"httpService",
	"$rootScope",
	"clientService",
	function ($scope, productV2Service, httpService, $rootScope, clientService) {
		"use strict";

		var decimalPlaces = clientService.getDecimalPlaces();
		var labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.columns = [
				{
					field: "area.description",
					displayName: function () {
						return $rootScope.labels.AREA;
					}
				},
				{
					field: "date",
					displayName: function () {
						return $rootScope.labels.DATE;
					},
					width: 150
				},
				{
					field: "quantityFormatted",
					displayName: function () {
						return $rootScope.labels.QUANTITY;
					},
					width: 150
				},
				{
					field: "product.measureUnit.abbreviation",
					displayName: function () {
						return $rootScope.labels.MEASUREMENT_UNIT;
					},
					width: 100
				},
				{
					field: "priceFormatted",
					displayName: function () {
						return $rootScope.labels.COST;
					},
					width: 200
				},
				{ editOptions: getRowEditOptions(), width: 60 }
			];

			var gridOptions = {
				columnDefinitions: $scope.columns,
				filterFields: [
					"area.description",
					"date",
					"quantityFormatted",
					"product.measureUnit.abbreviation",
					"priceFormatted"
				],
				title: $scope.ctrl.product
					? $rootScope.labels.PRODUCTIONS + ": " + $scope.ctrl.product.Name
					: $rootScope.labels.PRODUCTIONS,
				query: {
					order: "area.description",
					limit: 10,
					page: 1
				},
				data: [],
				rowClick: $scope.ctrl.productionEdit,
				create: $scope.ctrl.productionEdit,
				promise: {},
				hasData: false,
				errorLoading: false,
				showDeleteOnSelectedItens: true,
				deletePopup: {
					title: labels.DELETE_PRODUCTION_POPUPTITLE,
					text: labels.DELETE_PRODUCTION_POPUPTEXT,
					deleteMethod: productV2Service.deleteProduction,
					idField: "id",
					descriptionField: "descriptionForDeletion",
					onClose: listProductions
				}
			};

			if (!$scope.ctrl.gridOptions) {
				$scope.ctrl.gridOptions = {};
			}
			Vgr.util.merge($scope.ctrl.gridOptions, gridOptions, true);

			listProductions();

			$rootScope.contentNavBarGrid = $scope.ctrl.gridOptions;
		};

		function getRowEditOptions() {
			return [
				{
					type: "delete",
					icon: "delete",
					label: $scope.labels.DELETE,
					disable: function (row) {
						return false;
					}
				}
			];
		}

		function listProductions() {
			if ($scope.ctrl.productionEdit && !$scope.ctrl.product) {
				return;
			}

			var params = getParamsForList();
			$scope.ctrl.gridOptions.promise = httpService.getListFromServiceCore(productV2Service.listProductions, params);
			$scope.ctrl.gridOptions.promise.then(function (response) {
				$scope.ctrl.gridOptions.data = formatToGrid(response.list);
				$scope.ctrl.gridOptions.hasData = true;
			});
			return $scope.ctrl.gridOptions.promise;
		}

		function getParamsForList() {
			var params = "";
			if ($scope.ctrl.product) {
				params = {
					productCode: $scope.ctrl.product.code
				};
			}
			return params;
		}

		function formatToGrid(productions) {
			for (const production of productions) {
				production.quantityFormatted = Vgr.util.formatUnitDecimalPlaces(production.quantity, decimalPlaces);
				production.priceFormatted = Vgr.util.formatCurrency(production.price, labels.BRL_CURRENCY);
				production.date = Vgr.date.fromISOToString(production.date);
				production.descriptionForDeletion = getDescriptionForDeletion(production);
			}
			return productions;
		}

		function getDescriptionForDeletion(production) {
			var measureUnitAbbreviation = production.product.measureUnit ? production.product.measureUnit.abbreviation : "";
			var areaDescription = production.area ? production.area.description : " - ";
			return `Área: ${areaDescription} / Data: ${production.date} / Quantidade: ${production.quantityFormatted}${measureUnitAbbreviation} / Preço: ${production.priceFormatted}`;
		}
	}
]);
