angular.module("vgresiduos").component("areaMultiSelect", {
	controller: "AreaMultiSelectCtrl",
	templateUrl: "views/components/fields/areaMultiSelect/areaMultiSelect_template.html",
	bindings: {
		initialValues: "<?",
		onSelectedValuesChanged: "<",
		showSelectAll: "<?",
		isRequired: "<?"
	}
});
