angular.module("vgresiduos").directive("productionForm", function () {
	return {
		scope: true,
		controller: "ProductionFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/Product/production/productionComponents/productionForm/productionForm_template.html",
		bindToController: {
			inputProduction: "=",
			saveOptions: "=",
			disableProduct: "=?",
			disableArea: "=?"
		}
	};
});
