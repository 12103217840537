angular.module("vgresiduos").factory("scalingIntegrationService", [
	"$http",
	"$q",
	function ($http, $q) {
		"use strict";

		var scalingIntegrationService = {};

		var _getWeight = function GetWeight(scale) {
			var req = {
				method: "get",
				url: scale.LocalServerUrl + Vgr.resources.local.apiV1.scales
			};
			return $http(req);
		};

		scalingIntegrationService.getWeight = _getWeight;

		return scalingIntegrationService;
	}
]);
