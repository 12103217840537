angular.module("vgresiduos").controller("ProductPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"measureUnitService",
	"$q",
	"httpService",
	"productService",
	"clientService",
	function ($scope, $rootScope, dialogService, measureUnitService, $q, httpService, productService, clientService) {
		"use strict";

		var cancel = function () {
			dialogService.hide($scope.dialog);
		};

		var save = function () {
			if (!$scope.productDTO.MaxCapacity || !$scope.productDTO.Name || !$scope.productDTO.MeasureUnitDTO.ID) {
				var deferred = $q.defer();
				deferred.reject({ errorCode: "VALIDATION_ERROR_MESSAGE" });
				return deferred.promise;
			}
			$scope.selectedProductName = $scope.productDTO.Name;
			var promise = httpService.postDTOToService(productService.updateProduct, $scope.productDTO);
			promise.then(function (product) {
				$scope.product = product.content;
				$scope.productList.push($scope.product);
				dialogService.hide($scope.dialog);
			});
			return promise;
		};

		function loadMeasureUnits() {
			$scope.measureUnits = [];
			httpService.getListFromServiceV2(measureUnitService.listMeasureUnits, null, "MeasureUnits").then(
				function (list) {
					$scope.measureUnits = list;
				},
				function () {
					$scope.measureUnits = [];
				}
			);
		}

		function initializePopup() {
			$scope.labels = $rootScope.labels;
			$scope.productDTO = { MaxCapacity: "", Name: "", MeasureUnitDTO: { ID: "" } };

			$scope.decimals = clientService.getDecimalPlaces();

			$scope.productDTO.Name = $scope.selectedProductName;

			$scope.popupButtonOptions = {
				validate: function () {
					return true;
				},
				buttons: [$rootScope.labels.CANCEL, $rootScope.labels.SAVE2],
				customButtons: [
					new Cmp.constructors.ActionButton(
						$rootScope.labels.CANCEL,
						"",
						false,
						cancel,
						"",
						"",
						true,
						"no-bg-btn c-grey flex f-w-n",
						"",
						true
					),
					new Cmp.constructors.ActionButton(
						$rootScope.labels.SAVE2,
						"",
						false,
						save,
						"",
						"",
						true,
						"no-bg-btn flex strong-green",
						"",
						true
					)
				],
				closePopup: function () {
					dialogService.hide($scope.dialog);
				},
				removeButtonBar: false,
				isPopupContainer: true
			};

			loadMeasureUnits();
		}

		initializePopup();
	}
]);
