angular.module("vgresiduos").controller("RecipientFormCtrl", [
	"$scope",
	"recipientService",
	"httpService",
	"clientService",
	"residueService",
	"conama313Service",
	"userAccessService",
	function ($scope, recipientService, httpService, clientService, residueService, conama313Service, userAccessService) {
		"use strict";

		this.$onInit = function () {
			$scope.isNewRecipient = isNewRecipient();
			$scope.decimals = clientService.getDecimalPlaces();
			$scope.maxLength = 12 + $scope.decimals;
			$scope.measureUnitGroupAllowed = Vgr.enumerations.measureUnit.Group.Volume;
			$scope.recipientModel = {};
			$scope.$watch("recipientForm.$valid", setValid);
			$scope.onChangeSelectRecipient = onChangeSelectRecipient;
			$scope.onChangeRecipientVolumeMeasureUnitId = onChangeRecipientVolumeMeasureUnitId;
			$scope.conama313Type = Vgr.enumerations.conama313.type.Recipient;
			$scope.isLowerGenerationPlan = userAccessService.hasAccess(
				null,
				Vgr.constants.featureToggles.permission.lowerGenerationPlan
			);

			if ($scope.ctrl.model) {
				$scope.recipientModel.selectedRecipient = {};
				$scope.recipientModel.selectedRecipient.GeneralRecipientName = $scope.ctrl.model.GeneralRecipientName;
				$scope.recipientModel.selectedRecipient.Id = $scope.ctrl.model.GeneralRecipientId;
			} else {
				$scope.ctrl.model.IsDestinationDefined = false;
				$scope.ctrl.model.IsIndustryArea = false;
			}

			httpService.getListFromServiceCore(recipientService.listSystemRecipients, null).then(function (response) {
				$scope.defaultRecipients = response.list;
			});
		};

		function onChangeSelectRecipient(recipientId) {
			if (recipientId) {
				const recipient = $scope.defaultRecipients.find((r) => r.id == recipientId);
				$scope.ctrl.model.GeneralRecipientId = recipient.id;
				buildSugestionConama313(recipient.conama313Id);
				$scope.$broadcast("singleBaseChanged", recipient.description);
			}
		}

		function onChangeRecipientVolumeMeasureUnitId(measureUnit) {
			$scope.ctrl.model.VolumeMeasureUnitId = measureUnit.Id;
		}

		function buildSugestionConama313(generalRecipientConama313Id) {
			httpService.getListFromService(residueService.getConama313List, $scope.conama313Type).then(function (list) {
				const sugestionConama313 = list.find((c) => c.ID == generalRecipientConama313Id);
				if (sugestionConama313) {
					$scope.ctrl.model.Conama313 = {
						ID: sugestionConama313.ID,
						Code1: sugestionConama313.Code1,
						Code2: sugestionConama313.Code2,
						Description: sugestionConama313.Description
					};
					$scope.ctrl.model.Conama313.Label = conama313Service.formatConama313LabelField(
						$scope.ctrl.model.Conama313,
						$scope.conama313Type
					);
				}
			});
		}

		function setValid(newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		}

		function isNewRecipient() {
			return $scope.ctrl.model.Id ? false : true;
		}
	}
]);
