angular.module("vgresiduos").directive("masterUserGrid", function () {
	return {
		scope: true,
		controller: "MasterUserGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/masterUser/masterUserList/components/masterUserGrid/masterUserGrid_template.html",
		bindToController: {
			gridOptions: "=?"
		}
	};
});
