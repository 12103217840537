angular.module("vgresiduos").controller("CollapsibleDivCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.showDivContent = false;
		};

		$scope.toggleFilters = function () {
			$scope.showDivContent = !$scope.showDivContent;
		};
	}
]);
