angular.module("vgresiduos").controller("CustomReportCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"$state",
	"$window",
	"engagementScoreService",
	function ($scope, $rootScope, $q, $state, $window, engagementScoreService) {
		$scope.labels = $rootScope.labels;

		$rootScope.contentNavBar = {
			title: $scope.labels.REPORTS,
			valid: true,
			customButtons: [
				{
					label: $scope.labels.NEW_REPORT,
					buttonClasses: "md-primary",
					icon: "add",
					supressToast: true,
					onClick: function () {
						const deferred = $q.defer();
						$state.go("customReportCreate");
						deferred.resolve();
						return deferred.promise;
					}
				}
			],
			buttons: [$scope.labels.NEW_REPORT]
		};

		$scope.onClickKnowMore = function () {
			const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.customReports;
			$window.open(url, "_blank");
		};
	}
]);
