angular.module("vgresiduos").directive("featureTogglePermissionGrid", function () {
	return {
		scope: true,
		controller: "featureTogglePermissionGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/featureToggle/components/grid/featureTogglePermissionGrid_template.html",
		bindToController: {
			gridOptions: "=?",
			model: "="
		}
	};
});
