angular.module("vgresiduos").controller("ClientInformationsCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"dialogService",
	"additionalPropertyUtilService",
	"permissionService",
	function ($rootScope, $scope, $q, dialogService, additionalPropertyUtilService, permissionService) {
		"use strict";

		let editPermission = false;

		this.$onInit = function () {
			$scope.additionalInfo = {
				columns: [
					{
						description: $rootScope.labels.PROPERTY,
						property: "PropertyName"
					},
					{
						description: $rootScope.labels.VALUE,
						property: "InfoValue"
					}
				],
				defaultActions: [],
				actionButtons: [],
				list: $scope.ctrl.informations,
				btnAddClick: function () {
					const deferred = $q.defer();
					openAdditionalInformationPopup({}, true).then(
						function (additionalInfo) {
							deferred.resolve(buildGridAdditionalInfo(additionalInfo));
						},
						function () {
							deferred.reject();
						}
					);
					return deferred.promise;
				}
			};

			editPermission = getEditPermission();

			if (editPermission) {
				$scope.additionalInfo.defaultActions = ["add", "delete"];
				$scope.additionalInfo.actionButtons = [
					{
						label: "Editar",
						icon: "edit",
						click: function (row) {
							const deferred = $q.defer();
							openAdditionalInformationPopup(row, false).then(
								function (additionalInfo) {
									onClientPropertiesPopupConfirmEdit(additionalInfo);
									deferred.resolve();
								},
								function () {
									deferred.reject();
								}
							);
							return deferred.promise;
						}
					}
				];
			}
		};

		$scope.$watch("ctrl.informations", function (newValue, oldValue) {
			$scope.additionalInfo.list = newValue;
		});

		function getPermission(systemPermission) {
			return permissionService.hasPermissionV2(systemPermission);
		}

		function getEditPermission() {
			if ($scope.ctrl.getEditPermission) {
				return $scope.ctrl.getEditPermission();
			}

			switch ($scope.ctrl.processId) {
				case Vgr.enumerations.additionalProperty.process.InternalGathering:
					return (
						getPermission(Vgr.systemPermissions.CreateAndEditResiduesGenerationInAllAreas) ||
						getPermission(Vgr.systemPermissions.CreateAndEditResiduesGenerationInUserAreas)
					);
				case Vgr.enumerations.additionalProperty.process.Disposal:
					return (
						getPermission(Vgr.systemPermissions.CreateEditDeleteDisposalUnfinished) ||
						getPermission(Vgr.systemPermissions.EditDeleteDisposalFinished)
					);
				case Vgr.enumerations.additionalProperty.process.Area:
					return getPermission(Vgr.systemPermissions.CreateEditDeleteArea);
				case Vgr.enumerations.additionalProperty.process.OrganizationUnit:
					return getPermission(Vgr.systemPermissions.EditUnit);
			}
		}

		function onClientPropertiesPopupConfirmEdit(additionalInfo) {
			const infoIndex = getIndexFromGridInfo(additionalInfo.Id);
			$scope.additionalInfo.list[infoIndex] = buildGridAdditionalInfo(additionalInfo);
		}

		function openAdditionalInformationPopup(infoRow, isAddButton) {
			const popupInfo = {
				propertyData: infoRow,
				additionalPropertyProcess: $scope.ctrl.processId,
				popupTitle: isAddButton
					? $rootScope.labels.ADD_ADDITIONAL_INFORMATION
					: $rootScope.labels.EDIT_ADDITIONAL_INFORMATION
			};
			const deferred = $q.defer();
			dialogService
				.showDialogFromTemplateV2(
					"views/clientProperties/clientPropertiesPopup_template.html",
					"ClientPropertiesPopupCtrl",
					null,
					popupInfo
				)
				.then(deferred.resolve, deferred.reject);

			return deferred.promise;
		}

		function buildGridAdditionalInfo(additionalInfo) {
			return additionalPropertyUtilService.buildAdditionalPropertiesFromPopupToGrid(additionalInfo);
		}

		function getIndexFromGridInfo(id) {
			for (const i in $scope.additionalInfo.list) {
				if ($scope.additionalInfo.list[i].Id == id) {
					return i;
				}
			}
			return -1;
		}
	}
]);
