angular.module("vgresiduos").directive("residueForm", function () {
	return {
		scope: true,
		controller: "ResidueFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/residue/components/form/residueForm_template.html",
		bindToController: {
			inputResidue: "=",
			saveOptions: "=",
			loadUnits: "="
		}
	};
});
