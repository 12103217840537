angular.module("vgresiduos").directive("emergencySheet", function () {
	return {
		scope: true,
		controller: "EmergencySheetCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/residue/emergencySheet/emergencySheet_template.html",
		bindToController: {
			model: "=",
			saveOptions: "="
		}
	};
});
