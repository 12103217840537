angular.module("vgresiduos").controller("MasterUserGridCtrl", [
	"$scope",
	"masterService",
	"$rootScope",
	"httpService",
	"$q",
	"permissionService",
	"accountService",
	"localStorageService",
	"dialogService",
	"reportService",
	"$state",
	function (
		$scope,
		masterService,
		$rootScope,
		httpService,
		$q,
		permissionService,
		accountService,
		localStorageService,
		dialogService,
		reportService,
		$state
	) {
		"use strict";

		const editClientDataPermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.MasterCreateEditClientData
		);
		const viewClientDataPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterViewClientData);

		$scope.labels = $rootScope.labels;

		let filters = {};

		const editOptionsColumn = {
			editOptions: [
				{
					type: "custom",
					icon: "edit",
					label: $scope.labels.userForm.EDIT_USER,
					disable: function () {
						return !editClientDataPermission;
					},
					click: function (row) {
						$state.go("masterUserEdit", { id: row.code });
					}
				},
				{
					type: "custom",
					icon: "exit_to_app",
					label: $scope.labels.userForm.FORCE_LOGOUT,
					disable: function (row) {
						return !editClientDataPermission || row.forceLogout;
					},
					click: function (row) {
						return openConfirmationPopup(
							$scope.labels.FORCE_LOGOUT,
							$scope.labels.FORCE_LOGOUT_USER_MESSAGE,
							[row],
							masterService.forceLogoutUser
						);
					}
				},
				{
					type: "custom",
					isSvg: true,
					icon: "img/icons/toggle_on.svg",
					label: $scope.labels.ENABLE_DISABLE_USER,
					disable: function () {
						return !editClientDataPermission;
					},
					click: function (row) {
						return openConfirmationPopup(
							row.active ? $scope.labels.DISABLE_USER : $scope.labels.ENABLE_USER,
							row.active ? $scope.labels.DISABLE_USER_MESSAGE : $scope.labels.ENABLE_USER_MESSAGE,
							[row],
							row.active ? masterService.disableUser : masterService.enableUser
						);
					}
				},
				{
					type: "custom",
					isSvg: true,
					icon: "img/icons/vpn_key.svg",
					label: $scope.labels.NEW_PASSWORD_USER,
					disable: function (row) {
						return !editClientDataPermission || row.ssoId;
					},
					click: function (row) {
						const password = Vgr.util.generatePassword();
						return openConfirmationPopup(
							$scope.labels.NEW_PASSWORD_USER_MESSAGE,
							$scope.labels.PASSWORD + ": " + password,
							[row],
							masterService.newPasswordUser,
							function () {
								return {
									id: row.code,
									password: {
										password
									}
								};
							}
						);
					}
				}
			],
			displayName: function () {
				return $scope.labels.ACTIONS;
			}
		};

		const gridColumns = [
			{
				field: "code",
				displayName: function () {
					return $scope.labels.commonFields.ID;
				},
				notSortable: true,
				width: 25
			},
			{
				field: "id",
				displayName: function () {
					return $scope.labels.IDENTIFIER;
				},
				notSortable: true
			},
			{
				field: "name",
				displayName: function () {
					return $scope.labels.userForm.NAME;
				},
				notSortable: true
			},
			{
				field: "email",
				displayName: function () {
					return $scope.labels.userForm.EMAIL;
				},
				notSortable: true
			},
			{
				field: "organizations",
				displayName: function () {
					return $scope.labels.ORGANIZATIONS;
				},
				notSortable: true
			},
			editOptionsColumn
		];

		const gridOptions = {
			hasPermission: function () {
				return editClientDataPermission;
			},
			enableSelect: editClientDataPermission,
			columnDefinitions: gridColumns,
			title: $scope.labels.USER_QUERY,
			filterFields: ["code", "name", "email", "organizations"],
			data: [],
			query: {
				order: "name",
				limit: 10,
				page: 1,
				serverPagination: true,
				onPaginate: function (page, limit) {
					$scope.ctrl.gridOptions.query.page = page;
					$scope.ctrl.gridOptions.query.limit = limit;
					loadUsers();
				}
			},
			rowClick: function (row) {
				$state.go("masterUserEdit", { id: row.code });
			},
			promise: {},
			hasData: false,
			errorLoading: false,
			removeNew: true,
			removeDelete: true,
			removeFreeTextFilter: true,
			showSettingsButton: false,
			settingsButton: {
				iconName: "expand_more",
				iconClass: "material-icons",
				buttonLabel: $scope.labels.ACTIONS,
				class: "md-primary md-raised",
				menuItems: [
					{
						title: $scope.labels.userForm.FORCE_LOGOUT,
						usedIcon: "exit_to_app",
						onClick: function () {
							const selectedUsers = $scope.ctrl.gridOptions.selected;
							return forceLogoutUsers(selectedUsers);
						}
					},
					{
						title: $scope.labels.INACTIVATE,
						isSvg: true,
						usedIcon: "img/icons/toggle_on.svg",
						onClick: function () {
							const selectedUsers = $scope.ctrl.gridOptions.selected;
							return disableUsers(selectedUsers);
						}
					}
				]
			},
			gridFilter: {
				text: ""
			},
			onShowAllFilters: function (open) {
				$scope.isFilterActive = !open;
			},
			hasActiveFilters: function () {
				return $scope.hasActiveFilters;
			},
			isDisabled: function (user, column) {
				return !user.active && column != editOptionsColumn;
			}
		};

		$rootScope.contentNavBar = {
			customButtons: [
				{
					label: $scope.labels.EXPORT_CSV,
					buttonClasses: "md-primary",
					supressToast: true,
					onClick: function () {
						return $scope.exportToCsv();
					}
				}
			],
			validate: false,
			valid: true,
			buttons: [$scope.labels.EXPORT_CSV]
		};

		$scope.exportToCsv = function () {
			const dto = getFilters();
			dto.extension = Vgr.enumerations.reportExtension.Csv;

			const promise = httpService.getDTOFromServiceV2(reportService.generateMasterUserReport, dto);
			promise.then(function (data) {
				const fileDTO = Vgr.util.fileDtoToFile(data.File);
				saveAs(fileDTO, fileDTO.FileName);
			});
			return promise;
		};

		this.$onInit = function () {
			$scope.ctrl.gridOptions = {};
			Vgr.util.merge($scope.ctrl.gridOptions, gridOptions, true);

			if (!viewClientDataPermission) {
				accountService.goHome();
				return;
			}
			$rootScope.contentNavBarGrid = $scope.ctrl.gridOptions;
			$rootScope.contentNavBarGrid.showSettingsButtonFunc = shouldShowSettingsButton;
			loadInitialList();
		};

		$scope.isFilterActive = true;
		$scope.hasActiveFilters = false;
		let hasSelectedUsers;

		$scope.$watch("ctrl.gridOptions.selected.length", function () {
			if ($scope.ctrl.gridOptions.selected && $scope.ctrl.gridOptions.selected.length > 0) {
				hasSelectedUsers = true;
			} else {
				hasSelectedUsers = false;
			}
			return hasSelectedUsers;
		});

		$scope.cleanFilters = function () {
			$rootScope.$broadcast(Vgr.constants.evtCleanTagFilter);
			resetGridQuery();
			loadInitialList();
		};

		$scope.applyFilters = function (filter) {
			$rootScope.$broadcast(Vgr.constants.evtApplyAllFilters);
			resetGridQuery();
			filters = filter;
			loadUsers();
		};

		function resetGridQuery() {
			filters = {};
			$scope.ctrl.gridOptions.selected = [];
			$scope.ctrl.gridOptions.query.page = 1;
		}

		function shouldShowSettingsButton() {
			return hasSelectedUsers;
		}

		function loadInitialList() {
			loadUsers();
		}

		function loadUsers() {
			const deferred = $q.defer();
			$scope.ctrl.gridOptions.hasData = false;

			const params = getFilterParams();

			httpService.getListFromServiceCore(masterService.listUsers, params).then(
				function (response) {
					const gridRows = getRowsWithOrganizationFormatted(response.list);

					$scope.ctrl.gridOptions.data = gridRows;
					$scope.ctrl.gridOptions.hasData = true;
					$scope.ctrl.gridOptions.query.total = response.count;

					deferred.resolve();
				},
				function () {
					$scope.ctrl.gridOptions.errorLoading = true;
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function getRowsWithOrganizationFormatted(users) {
			const usersDataWithOrganizationsFormatted = [];
			for (const user of users) {
				user.nameEmail = user.name + " - " + user.email;
				const row = getGridRow(user);
				usersDataWithOrganizationsFormatted.push(row);
			}
			return usersDataWithOrganizationsFormatted;
		}

		function getGridRow(user) {
			const row = { ...user };
			row.organizations = getOrganizationsInString(user.organizations);
			return row;
		}

		function getOrganizationsInString(organizations) {
			const namesAndIdOrganizations = [];
			organizations.forEach((org) => {
				namesAndIdOrganizations.push(org.id + " - " + org.name);
			});
			return namesAndIdOrganizations.join(" / ");
		}

		function getFilterParams() {
			return {
				skip: $scope.ctrl.gridOptions.query.page - 1,
				take: localStorageService.get("grid.pageLimit") || Vgr.constants.DEFAULT_ROWS_PER_PAGE,
				...getFilters()
			};
		}

		function getFilters() {
			const params = {};

			if (filters.code) {
				params.code = filters.code;
			}
			if (filters.name) {
				params.name = filters.name;
			}
			if (filters.email) {
				params.email = filters.email;
			}
			if (filters.organizationId) {
				params.organizationId = filters.organizationId;
			}
			if (filters.organizationUnitId) {
				params.organizationUnitId = filters.organizationUnitId;
			}

			return params;
		}

		function openConfirmationPopup(title, text, list, method, getDto) {
			const params = {
				localVariables: {
					popupTitle: title,
					popupText: text,
					list: list,
					operationMethod: method,
					getDto: getDto,
					idField: "code",
					descriptionField: "nameEmail",
					confirmLabel: null,
					keepPopupOpenOnFinish: false
				}
			};

			const promise = dialogService.showDialogFromTemplateV2(
				"views/components/popups/operationConfirmationPopup_template.html",
				"OperationConfirmationPopupCtrl",
				null,
				params,
				true
			);
			promise.then(function () {
				$scope.ctrl.gridOptions.selected = [];
				loadUsers();
				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
			});
			return promise;
		}

		function forceLogoutUsers(selectedUsersList) {
			if (!hasSelectedUsers) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_SELECTED_REGISTER);
			} else {
				openConfirmationPopup(
					$scope.labels.FORCE_LOGOUT,
					selectedUsersList.length > 1
						? $scope.labels.FORCE_LOGOUT_USERS_MESSAGE
						: $scope.labels.FORCE_LOGOUT_USER_MESSAGE,
					selectedUsersList,
					masterService.forceLogoutUser
				);
			}
		}

		function disableUsers(selectedUsersList) {
			if (!hasSelectedUsers) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.NO_SELECTED_REGISTER);
			} else {
				openConfirmationPopup(
					selectedUsersList.length > 1 ? $scope.labels.DISABLE_USERS : $scope.labels.DISABLE_USER,
					selectedUsersList.length > 1 ? $scope.labels.DISABLE_USERS_MESSAGE : $scope.labels.DISABLE_USER_MESSAGE,
					selectedUsersList,
					masterService.disableUser
				);
			}
		}
	}
]);
