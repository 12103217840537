Vgr.trackings = {
	general: {
		blockPageInterest: "imInterested",
		login: "login"
	},
	destination: {
		messageCost: "Destinação.MensagemCustos.Clique"
	},
	disposal: {
		generateReportClientDestination: "generateReportClientDestination",
		integration: {
			sgors: {
				createIntegration: "Popup.DivulgacaoFeature.IntegracaoSgors.Criar",
				closePopup: "Popup.DivulgacaoFeature.IntegracaoSgors.Fechar"
			},
			notifySupplierExpiringMtr: {
				closePopup: "Popup.DivulgacaoFeature.NotificarFornecedorMtrAExpirar.Fechar",
				accessManual: "Popup.DivulgacaoFeature.NotificarFornecedorMtrAExpirar.VerManual",
				accessPage: "Popup.DivulgacaoFeature.NotificarFornecedorMtrAExpirar.AcessouPaginas"
			}
		},
		followupAccess: {
			usingMenu: "goToDisposalsFollowupUsingMenu",
			usingShortcut: "goToDisposalsFollowupUsingShortcut"
		},
		flow: {
			durationCreateDestination: "durationToCreateDestination"
		},
		manifest: {
			followupAccessUsingMenu: "goToDisposalManifestsFollowupUsingMenu",
			followupAccessUsingHomeShortcut: "goToDisposalManifestsFollowupUsingHomeShortcut",
			followupAccessUsingDisposalFollowupScreen: "goToDisposalManifestsFollowupUsingDisposalFollowupScreen"
		},
		gatherOnCreateFromModel: {
			accessPage: "Popup.DivulgacaoFeature.ColetarDestinaçãoModeloDestinações.Habilitar",
			closePopup: "Popup.DivulgacaoFeature.ColetarDestinaçãoModeloDestinações.Fechar"
		},
		history: {
			attachCdf: "HistoricoDestinacao.Listagem.AnexarCdf",
			exportHistory: "HistoricoDestinacao.Listagem.ExportarCsv"
		}
	},
	internal: {
		gathering: {
			exportCsv: "Geracao.TelaAcompanhamento.ExportarCsv",
			usingShortcutNewPage: "Geracao.TelaAntiga.AtalhoNovaTela",
			usingShortcutOldPage: "Geracao.TelaAntiga.AtalhoTelaAntiga",
			usingMenuNewPage: "Geracao.TelaAntiga.MenuNovaTela",
			usingMenuOldPage: "Geracao.TelaAntiga.MenuTelaAntiga",
			goToNewPage: "Geracao.TelaAntiga.AcessarNovaTela",
			create: "Geracao.TelaAntiga.Criar",
			createSuccess: "Geracao.TelaAntiga.Criar.Sucesso",
			print: "Geracao.TelaAntiga.Imprimir",
			edit: "Geracao.TelaAntiga.Editar",
			closePopupNewFeature: "Geracao.TelaNova.FecharPopup",
			userInterestedNewFeature: "Geracao.TelaNova.UsuarioInteressado",
			userTriedNewFeature: "Geracao.TelaNova.TestadaPeloUsuario",
			accessManualNewFeature: "Geracao.TelaNova.ManualAcessado"
		},
		transfer: {
			closePopupNewFeature: "MovimentacaoInterna.FecharPopup",
			userInterestedNewFeature: "MovimentacaoInterna.UsuarioInteressado",
			accessManualNewFeature: "MovimentacaoInterna.ManualAcessado"
		}
	},
	residueTraceability: {
		usingMenu: "RastreabilidadeResiduos.Abrir.Menu"
	},
	supplier: {
		actionPlan: {
			create: "Fornecedores.Editar.PlanoDeAcao.Criar"
		},
		audit: {
			print: "Fornecedores.Editar.Auditorias.Imprimir",
			requestSupplier: "Fornecedores.Editar.Auditorias.SolicitarPreenchimentoFornecedor"
		},
		integrationCtfIbamaDivulgation: {
			compliance: {
				accessPage: "Popup.DivulgacaoFeatureCompliance.IntegracaoCTF.VerManual",
				closePopup: "Popup.DivulgacaoFeatureCompliance.IntegracaoCTF.Fechar"
			},
			noCompliance: {
				markConversation: "Popup.DivulgacaoFeatureSemCompliance.IntegracaoCTF.MarcarConversa",
				closePopup: "Popup.DivulgacaoFeatureSemCompliance.IntegracaoCTF.Fechar"
			}
		},
		costsModule: {
			accessPage: "Popup.DivulgacaoFeatureModuloCustos.Cadastrar",
			closePopup: "Popup.DivulgacaoFeatureModuloCustos.Fechar",
			accessManual: "Popup.DivulgacaoFeatureModuloCustos.VerManual"
		}
	},
	area: {
		create: "Area.Criar"
	},
	residue: {
		create: "Residuo.Criar"
	},
	mtrFollowup: {
		accessPageByDestination: "goToMtrFollowupByDestination"
	},
	dashboard: {
		usingMenu: "goToDashboardUsingMenu",
		usingShortcut: "goToDashboardUsingShortcut"
	},
	bi: {
		metrics: {
			residueGenerationQuantity: "BI.Metrica.VisualizarDadosGeracaoQuantidade",
			residueDisposalQuantity: "BI.Metrica.VisualizarDadosDestinacaoQuantidade",
			residueDisposalCosts: "BI.Metrica.VisualizarDadosDestinacaoCustos",
			residueDisposalRecipientEfficiency: "BI.Metrica.VisualizarDadosDestinacaoEficienciaAcondicionamento",
			productionQuantity: "BI.Metrica.VisualizarDadosProducaoQuantidade",
			goalResidueGenerationQuantity: "BI.Metrica.VisualizarDadosMetasGeracaoQuantidade",
			goalResidueDisposalCosts: "BI.Metrica.VisualizarDadosMetasDestinacaoCusto"
		},
		reports: {
			createSavedReport: "BI.Relatorios.CriarRelatorioSalvo",
			generateSavedReport: "BI.Relatorios.GerarRelatorioSalvo",
			generateKpi: "BI.Relatorios.GerarIndicador",
			generateRelativeKpi: "BI.Relatorios.GerarComparacaoIndicadores",
			generateGoal: "BI.Relatorios.GerarMetaRealizado",
			generateManagementInventory: "BI.Relatorios.GerarInventarioGerencial",
			generateCadriBalance: "BI.Relatorios.GerarSaldoFornecedor",
			generateResidueMovimentation: "BI.Relatorios.GerarMovimentacaoResiduos"
		}
	},
	declarations: {
		rappIbamaGenerateReport: "Declaracao.RappIbama.GerarRelatorio",
		conama313GenerateReport: "Declaracao.Conama313.GerarRelatorio",
		dnitGenerateReport: "Declaracao.Dnit.GerarRelatorio"
	},
	market: {
		residueMarketSupplierIndicationRequest: "residueMarketSupplierIndicationRequest"
	},
	opportunities: {
		clickedOnAnOpportunity: "clickedOnAnOpportunity"
	},
	pendencies: {
		clickedOnAPendency: "clickedOnAPendency"
	},
	supplierProfile: {
		goToGeneralData: "supplierHomeGoToGeneralData",
		goToDocuments: "supplierHomeGoToDocuments",
		goToAudits: "supplierHomeGoToAudits"
	}
};
