angular.module("vgresiduos").factory("supplierService", [
	"$http",
	"$rootScope",
	"accountService",
	"permissionService",
	function ($http, $rootScope, accountService, permissionService) {
		"use strict";

		function _hasSupplierPermission(
			supplier,
			organizationPermission,
			organizationUnitPermission,
			forceHasUnitPermission
		) {
			if (permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierOrganizationMaster)) {
				return true;
			}
			const userResponsibleCode = getSupplierUserResponsibleCode(supplier);
			if (userResponsibleCode) {
				const currentUser = accountService.getLoggedAccount();
				if (userResponsibleCode == currentUser.code) {
					return true;
				} else if (!permissionService.hasPermissionV2(Vgr.systemPermissions.SupplierNotOwnedEdit)) {
					return false;
				}
			}
			if (organizationPermission && permissionService.hasPermissionV2(organizationPermission)) {
				return true;
			}
			if (organizationUnitPermission) {
				return forceHasUnitPermission || hasOrganizationUnitPermission(organizationUnitPermission, supplier);
			}
			return false;
		}

		function hasOrganizationUnitPermission(specificUnitsPermission, supplier) {
			return (
				permissionService.hasPermissionV2(specificUnitsPermission) &&
				supplier.organizationUnitIds.some((unitId) => permissionService.hasAccessToOrganizationUnit(unitId))
			);
		}

		function getSupplierUserResponsibleCode(supplier) {
			if (supplier) {
				if (supplier.UserResponsibleCode) {
					return supplier.UserResponsibleCode;
				}
				if (supplier.userResponsibleCode) {
					return supplier.userResponsibleCode;
				}
			}
			return null;
		}

		function getSupplierApiBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			return (
				Vgr.constants.suppliersHostUrl + Vgr.resources.suppliers.suppliers.replace("[ORGANIZATION]", organizationId)
			);
		}

		function getSupplierOrganizationUnitBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;
			return (
				Vgr.constants.suppliersHostUrl +
				"v1/organizations/" +
				organizationId +
				"/organization-units/" +
				organizationUnitCode +
				"/suppliers"
			);
		}

		const _getOrganizationUnitSupplier = function (supplierCode) {
			const req = {
				method: "get",
				url: getSupplierOrganizationUnitBaseRequestUrl() + "/" + supplierCode
			};

			return $http(req);
		};

		const _listOrganizationUnitSupplier = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);
			const req = {
				method: "get",
				url: getSupplierOrganizationUnitBaseRequestUrl() + parameters
			};
			return $http(req);
		};

		const _updateOrganizationUnitSupplier = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: getSupplierOrganizationUnitBaseRequestUrl() + "/" + dto.supplierCode
			};
			return $http(req);
		};

		const _deleteOrganizationUnitSuppliers = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);
			const req = {
				method: "delete",
				url: getSupplierOrganizationUnitBaseRequestUrl() + parameters
			};
			return $http(req);
		};

		const _listOrganizationSupplier = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);
			return $http({
				method: "get",
				url: getSupplierApiBaseRequestUrl() + parameters
			});
		};

		const _getOrganizationSupplier = function (supplierId) {
			const req = {
				method: "get",
				url: getSupplierApiBaseRequestUrl() + supplierId
			};

			return $http(req);
		};

		const _getOrganizationSupplierByCode = function (params) {
			const req = {
				method: "get",
				url: `${getSupplierApiBaseRequestUrl()}code/${params.supplierCode}?includeProfiles=${
					params.includeProfiles ?? false
				}`
			};

			return $http(req);
		};

		const _updateSupplierOrganizationUnits = function (params) {
			const dto = {
				ToAddOrganizationUnitsIds: params.organizationUnitIdsToAdd,
				ToRemoveOrganizationUnitsIds: params.organizationUnitIdsToRemove
			};

			const req = {
				method: "put",
				data: dto,
				url: getSupplierApiBaseRequestUrl() + params.supplierId + "/organization-units"
			};

			return $http(req);
		};

		const _getTreatmentTypes = function () {
			return [
				{
					id: Vgr.enumerations.authDocument.treatmentType.Treatment,
					label: $rootScope.labels.TREATMENT_TYPES[Vgr.enumerations.authDocument.treatmentType.Treatment]
				},
				{
					id: Vgr.enumerations.authDocument.treatmentType.FinalDestination,
					label: $rootScope.labels.TREATMENT_TYPES[Vgr.enumerations.authDocument.treatmentType.FinalDestination]
				},
				{
					id: Vgr.enumerations.authDocument.treatmentType.Automatic,
					label: $rootScope.labels.TREATMENT_TYPES[Vgr.enumerations.authDocument.treatmentType.Automatic],
					tooltip: $rootScope.labels.AUTOMATIC_TREATMENT_TYPE_TOOLTIP
				}
			];
		};

		const _listSuppliers = function (params) {
			let parameters = "";
			if (params) {
				parameters = $.param(params);
			}

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.suppliers + "?" + parameters
			};

			return $http(req);
		};

		return {
			//Obsolete API - Should be replaced by listOrganizationUnitSupplier
			listSuppliers: _listSuppliers,

			//Permission
			hasSupplierPermission: _hasSupplierPermission,

			//OrganizationUnitSupplier
			getOrganizationUnitSupplier: _getOrganizationUnitSupplier,
			deleteOrganizationUnitSuppliers: _deleteOrganizationUnitSuppliers,
			updateOrganizationUnitSupplier: _updateOrganizationUnitSupplier,
			listOrganizationUnitSupplier: _listOrganizationUnitSupplier,

			//OrganizationSupplier
			listOrganizationSupplier: _listOrganizationSupplier,
			getOrganizationSupplier: _getOrganizationSupplier,
			getOrganizationSupplierByCode: _getOrganizationSupplierByCode,
			updateSupplierOrganizationUnits: _updateSupplierOrganizationUnits,

			getTreatmentTypes: _getTreatmentTypes
		};
	}
]);
