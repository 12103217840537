angular.module("vgresiduos").directive("actionPlanItem", function () {
	return {
		scope: true,
		controller: "ActionPlanItemCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/actionPlan/actionPlanItem/actionPlanItem_template.html",
		bindToController: {
			item: "=",
			supplierContacts: "=",
			supplierId: "=",
			supplier: "=",
			actionPlan: "=",
			identifier: "<?",
			onDelete: "=?"
		}
	};
});
