angular.module("vgresiduos").factory("conama313Service", [
	"$http",
	"engagementScoreService",
	function ($http, engagementScoreService) {
		"use strict";

		var conama313ServiceFactory = {};

		var _getYearReportFilterList = function GetYearReportFilterList() {
			var currentYear = new Date().getFullYear();
			return [{ year: currentYear }, { year: currentYear - 1 }, { year: currentYear - 2 }, { year: currentYear - 3 }];
		};

		var _generateInventoryReport = function GetInventoryReport(filter) {
			var req = {
				method: "post",
				data: {
					format: filter.format,
					year: filter.year
				},
				url: Vgr.constants.newHostUrl + Vgr.resources.api.report + "GenerateCONAMA313Report"
			};
			engagementScoreService.track(Vgr.trackings.declarations.conama313GenerateReport);
			return $http(req);
		};

		var _formatConama313LabelField = function FormatConama313LabelField(element, conama313Type) {
			if (element && conama313Type == Vgr.enumerations.conama313.type.DangerousAndNotDangerousResidues) {
				return element.Code1 + " - " + element.Description;
			} else if (element && conama313Type == Vgr.enumerations.conama313.type.Recipient) {
				return element.Code1 + " - " + element.Code2 + " - " + element.Description;
			} else {
				return null;
			}
		};

		conama313ServiceFactory.getYearReportFilterList = _getYearReportFilterList;
		conama313ServiceFactory.generateInventoryReport = _generateInventoryReport;
		conama313ServiceFactory.formatConama313LabelField = _formatConama313LabelField;

		return conama313ServiceFactory;
	}
]);
