angular.module("vgresiduos").controller("ProductEditCtrl", [
	"$rootScope",
	"$scope",
	"$stateParams",
	"$q",
	"$state",
	"productService",
	"productV2Service",
	"httpService",
	"fileService",
	"productIngredientService",
	function (
		$rootScope,
		$scope,
		$stateParams,
		$q,
		$state,
		productService,
		productV2Service,
		httpService,
		fileService,
		productIngredientService
	) {
		"use strict";

		this.$onInit = function () {
			$scope.currentProductCode = $stateParams.id;

			$scope.saveFispq = function (deferred) {
				const service = $scope.fispq.ID ? productService.updateFISPQ : productService.createFISPQ;
				httpService.postDTOToService(service, $scope.fispq).then(
					function (data) {
						$scope.fispq = data.content;
						deferred.resolve(data);
					},
					function () {
						deferred.reject();
					}
				);
			};

			$scope.saveOptions = {
				currentContext: "product",
				back: function () {
					$state.go("productList");
				},
				product: {
					valid: false,
					returnState: "productList",
					save: function () {
						const dto = $scope.isEditingProduct() ? getDtoForUpdateProduct() : getDtoForCreateProduct();
						const service = $scope.isEditingProduct() ? productV2Service.updateProduct : productV2Service.createProduct;
						const promise = httpService.postDTOToServiceV2(service, dto);
						promise.then(function (productCode) {
							let redirectCode = $scope.currentProductCode;
							if (productCode) {
								redirectCode = productCode;
							} else if (dto.newCode) {
								redirectCode = dto.newCode;
							}

							$state.go("productEdit", { code: redirectCode });
						});

						return promise;
					}
				},
				production: {
					removeButtonBar: true,
					buttons: []
				},
				productionEdit: {
					valid: true,
					back: function () {
						$scope.changeContext("production");
					},
					save: function () {
						const isEditingProduction = $scope.inputProduction && $scope.inputProduction.id;
						const dto = isEditingProduction ? getDtoForUpdateProduction() : getDtoForCreateProduction();
						const service = isEditingProduction ? productV2Service.updateProduction : productV2Service.createProduction;

						const promise = httpService.postDTOToServiceV2(service, dto);
						promise.then(function (productionId) {
							if (productionId) {
								$scope.inputProduction.id = productionId;
							}
						});

						return promise;
					},
					removeButtonBar: true
				},
				ingredients: {
					removeButtonBar: true,
					buttons: []
				},
				ingredientEdit: {
					valid: true,
					back: function () {
						$scope.changeContext("ingredient");
					},
					save: function () {
						const service = $scope.inputIngredient.id
							? productIngredientService.updateIngredient
							: productIngredientService.createIngredient;

						const ingredientDTO = $scope.inputIngredient;
						ingredientDTO.productCode = $scope.product.code;

						const promise = httpService.postDTOToServiceV2(service, ingredientDTO).then(function (response) {
							$scope.inputIngredient.id = $scope.inputIngredient.id ? $scope.inputIngredient.id : response;
							$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $rootScope.labels.SAVE_SUCCESS_MESSAGE);
						});
						return promise;
					},
					removeButtonBar: true
				},
				fispq: {
					valid: true,
					save: function () {
						const deferred = $q.defer();
						if ($scope.fispqTemplateFileOptions.updateFiles) {
							$q.all([$scope.fispqTemplateFileOptions.updateFiles(), $scope.fispqFinalFileOptions.updateFiles()]).then(
								function (paths) {
									$scope.fispq.TemplateS3Key = paths[0][0];
									$scope.fispq.FinalS3Key = paths[1][0];
									$scope.saveFispq(deferred);
								},
								function () {
									deferred.reject();
								}
							);
						} else {
							$scope.fispqFinalFileOptions.updateFiles().then(
								function (paths) {
									$scope.fispq.FinalS3Key = paths[0];
									$scope.saveFispq(deferred);
								},
								function () {
									deferred.reject();
								}
							);
						}

						return deferred.promise;
					},
					validate: function () {
						const allFilled =
							$scope.fispq.Identification &&
							$scope.fispq.DangersIdentification &&
							$scope.fispq.ProductIngredientsInformation &&
							$scope.fispq.FirstAidMeasures &&
							$scope.fispq.FireFightMeasures &&
							$scope.fispq.LeakageControlMeasures &&
							$scope.fispq.HandlingAndStorage &&
							$scope.fispq.ExpositionControlAndIndividualProtection &&
							$scope.fispq.PhysicalAndChemicalProperties &&
							$scope.fispq.StabilityAndReativity &&
							$scope.fispq.ToxicologicalInformations &&
							$scope.fispq.EcologicalInformations &&
							$scope.fispq.FinalDisposalConsiderations &&
							$scope.fispq.TransportInformations &&
							$scope.fispq.RegulatoryInformation &&
							$scope.fispq.OtherInformations;

						if (!allFilled) {
							$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
							return false;
						}
						return true;
					},
					removeButtonBar: true
				}
			};

			$rootScope.contentNavBar = $scope.saveOptions;
			$rootScope.contentNavBar.title = $rootScope.labels.PRODUCTS;
			$rootScope.contentNavBarGrid = false;

			if ($scope.currentProductCode) {
				$rootScope.contentNavBar.breadcrumb = $scope.currentProductCode;
				httpService.getDTOFromServiceV2(productV2Service.getProduct, $scope.currentProductCode).then(
					function (product) {
						$scope.product = formatProductDtoToModel(product);
						$scope.fispq = $scope.fispq || {};
						$scope.fispq.ProductDTO = $scope.product;

						loadFISPQ();
					},
					function (err) {
						onProductNotFoundError(err);
					}
				);
			} else {
				$rootScope.contentNavBar.breadcrumb = $rootScope.labels.NEW;
				$scope.product = {};
				$scope.fispq = { ProductDTO: $scope.product };
			}

			$scope.changeContext = function (context) {
				$scope.saveOptions.currentContext = context;
				$rootScope.contentNavBarGrid = false;
			};

			// This part is for production handling
			$scope.editProduction = function (event) {
				$scope.saveOptions.currentContext = "productionEdit";
				$rootScope.contentNavBarGrid = null;
				$scope.inputProduction = null;
				if (event) {
					httpService.getDTOFromServiceV2(productV2Service.getProduction, event.id).then(function (production) {
						$scope.inputProduction = production;
						$scope.inputProduction.date = Vgr.util.getDateFromString(production.date);
					});
				} else {
					httpService
						.getDTOFromServiceV2(productV2Service.getProduct, $scope.currentProductCode)
						.then(function (product) {
							if (!$scope.inputProduction) {
								$scope.inputProduction = {};
							}
							$scope.inputProduction.product = formatProductDtoToModel(product);
						});
				}
			};

			$scope.editIngredient = function (event) {
				$scope.saveOptions.currentContext = "ingredientEdit";

				$scope.inputIngredient = {};
				if (event) {
					productIngredientService.getIngredientByCode($scope.product.code, event.id).then(function (response) {
						const data = response.data;
						if (data) {
							$scope.inputIngredient = data;
							$scope.inputIngredient.ProductDTO = $scope.product;
							Vgr.date.fromISOStringsToDate($scope.inputIngredient);
						} else {
							Vgr.util.showServerErrorMessage($rootScope, data);
						}
					});
				} else {
					productIngredientService.getIngredientByCode($scope.rawMaterialCode).then(function (response) {
						const data = response.data;
						if (data) {
							$scope.inputIngredient.ProductDTO = formatProductDtoToModel(data);
						} else {
							$scope.saveOptions.currentContext = "ingredientEdit";
							Vgr.util.showServerErrorMessage($rootScope, data);
						}
					});
				}
			};

			$scope.fispqTemplateFileOptions = {
				fileService: "s3",

				update: function (newFiles, deletedIds) {
					return fileService.uploadAll(newFiles, "FISPQs");
				}
			};

			$scope.fispqFinalFileOptions = {
				fileService: "s3",

				update: function (newFiles, deletedIds) {
					return fileService.uploadAll(newFiles, "FISPQRevisadoFinal");
				}
			};

			$scope.saveOptions.fispq.fileOptions = {
				template: $scope.fispqTemplateFileOptions,
				final: $scope.fispqFinalFileOptions
			};

			$scope.isEditingProduct = function () {
				return $scope.currentProductCode && $scope.product && $scope.product.code;
			};
		};

		function getDtoForUpdateProduct() {
			return {
				id: $scope.currentProductCode,
				newCode: $scope.currentProductCode != $scope.product.code ? $scope.product.code : "",
				description: $scope.product.description,
				measureUnitId: $scope.product.measureUnit.id,
				areaCodes: $scope.product.areaCodes,
				maxProductionCapacity: $scope.product.maxProductionCapacity
			};
		}

		function getDtoForCreateProduct() {
			return {
				code: $scope.product.code,
				description: $scope.product.description,
				measureUnitId: $scope.product.measureUnit.id,
				areaCodes: $scope.product.areaCodes,
				maxProductionCapacity: $scope.product.maxProductionCapacity
			};
		}

		function getDtoForCreateProduction() {
			return {
				areaCode: $scope.inputProduction.area.code,
				productCode: $scope.inputProduction.product.code,
				date: $scope.inputProduction.date,
				quantity: $scope.inputProduction.quantity,
				price: $scope.inputProduction.price
			};
		}

		function getDtoForUpdateProduction() {
			return {
				id: $scope.inputProduction.id,
				date: $scope.inputProduction.date,
				quantity: $scope.inputProduction.quantity,
				price: $scope.inputProduction.price
			};
		}

		function loadFISPQ() {
			httpService
				.getDTOFromServiceById(productService.getFISPQByProduct, $scope.currentProductCode)
				.then(function (fispq) {
					$scope.fispq = fispq || {};
					$scope.fispq.ProductDTO = $scope.product;

					$scope.fispqTemplateFileOptions.pathList = [$scope.fispq.TemplateS3Key];
					$scope.fispqFinalFileOptions.pathList = [$scope.fispq.FinalS3Key];
				});
		}

		function onProductNotFoundError(err) {
			$scope.saveOptions.back();
		}

		function formatProductDtoToModel(product) {
			const ret = product;
			if (product.areas) {
				ret.areaCodes = product.areas.map((a) => a.code);
			}
			return ret;
		}
	}
]);
