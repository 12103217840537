angular.module("vgresiduos").directive("inviteUserCustomizedForm", function () {
	return {
		scope: true,
		controller: "InviteUserCustomizedFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/usergrid/inviteUserCustomizedForm/inviteUserCustomizedForm_template.html",
		bindToController: {
			model: "=",
			invitationMode: "="
		}
	};
});
