angular.module("vgresiduos").factory("userService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getBaseCoreRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;

			return Vgr.constants.coreHostUrl + Vgr.resources.core.organizationUsers.replace("[ORGANIZATION]", organizationId);
		}

		const _listUsers = function ListUsers(dto) {
			const parameters = Vgr.util.serializeObjectToQueryString(dto);

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.users + parameters
			};
			return $http(req);
		};

		const _listUsersV2 = function (dto) {
			const parameters = Vgr.util.serializeObjectToQueryString(dto);

			const req = {
				method: "get",
				url: getBaseCoreRequestUrl() + parameters
			};
			return $http(req);
		};

		const _getUser = function (userId) {
			const req = {
				method: "get",
				url: getBaseCoreRequestUrl() + userId
			};
			return $http(req);
		};

		const _inviteUsers = function (model) {
			const req = {
				method: "post",
				data: model,
				url: getBaseCoreRequestUrl()
			};
			return $http(req);
		};

		const _removeUserFromOrganization = function (id) {
			const req = {
				method: "delete",
				url: getBaseCoreRequestUrl() + id
			};
			return $http(req);
		};

		const _listUserPermissionGroups = function (userId) {
			const req = {
				method: "get",
				url: getBaseCoreRequestUrl() + userId + "/permission-groups"
			};
			return $http(req);
		};

		const _generateUsersReport = function () {
			const req = {
				method: "post",
				data: { extension: "CSV" },
				url: getBaseCoreRequestUrl() + "report"
			};
			return $http(req);
		};

		return {
			listUsers: _listUsers,

			listUsersV2: _listUsersV2,
			getUser: _getUser,

			inviteUsers: _inviteUsers,
			removeUserFromOrganization: _removeUserFromOrganization,

			listUserPermissionGroups: _listUserPermissionGroups,

			generateUsersReport: _generateUsersReport
		};
	}
]);
