angular.module("vgresiduos").directive("transporterForm", function () {
	return {
		scope: true,
		controller: "TransporterFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/transporter/components/form/general/transporterForm_template.html",
		bindToController: {
			model: "=",
			organizationSupplier: "=",
			saveOptions: "=",
			hasPermission: "="
		}
	};
});
