angular.module("vgresiduos").directive("auditClassificationSmall", function () {
	return {
		scope: true,
		controller: "AuditClassificationSmallCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/audit/components/auditClassificationSmall_template.html",
		bindToController: {
			audit: "=?"
		}
	};
});
