angular.module("vgresiduos").directive("productForm", function () {
	return {
		scope: true,
		controller: "ProductFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/product/productComponents/productForm/productForm_template.html",
		bindToController: {
			inputProduct: "=",
			saveOptions: "=",
			loadUnits: "="
		}
	};
});
