angular.module("vgresiduos").directive("ingredientGrid", function () {
	return {
		scope: true,
		controller: "IngredientGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/product/ingredients/ingredientsComponents/ingredientGrid/ingredientGrid_template.html",
		bindToController: {
			product: "=?",
			ingredientEdit: "=",
			gridOptions: "=?"
		}
	};
});
