angular.module("vgresiduos").directive("auditClassificationSelectField", function () {
	return {
		scope: true,
		controller: "auditClassificationSelectFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/fields/auditClassificationSelectField/auditClassificationSelectField_template.html",
		bindToController: {
			situation: "=",
			label: "@",
			emptyFieldDescription: "@"
		}
	};
});
