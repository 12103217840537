angular.module("vgresiduos").controller("DisposalCostsFormCtrl", [
	"$scope",
	"$rootScope",
	"disposalCostService",
	"$q",
	"clientService",
	"httpService",
	"$state",
	"$window",
	"supplierDocumentService",

	function (
		$scope,
		$rootScope,
		disposalCostService,
		$q,
		clientService,
		httpService,
		$state,
		$window,
		supplierDocumentService
	) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.priceDecimals = clientService.getPriceDecimalPlaces();
		$scope.createEditPermission = $scope.ctrl.hasPermission;

		this.$onInit = function () {
			$scope.disposalConfirmationCost = {
				radio: {}
			};

			$scope.disposalModelCost = angular.copy(
				disposalCostService.fillDisposalCostResidueByGrid(
					$scope.ctrl.disposalModel.DisposalCost,
					$scope.ctrl.disposalModel.Residues
				)
			);
			$scope.disposalCostTypes = Vgr.enumerations.destination.disposalCostType;
			calculateAllCosts();
			updateTotalResiduesCostLabel($scope.disposalModelCost);
			updateTotalValueLabels($scope.disposalModelCost.totalCost);

			$scope.radioOptions = [
				{
					Id: Vgr.enumerations.destination.disposalCostType.CostByDisposal,
					Label: $rootScope.labels.DESTINATION_COST
				},
				{
					Id: Vgr.enumerations.destination.disposalCostType.CostByResidue,
					Label: $rootScope.labels.RESIDUE_COST
				}
			];

			$scope.disposalModelCost.pricingType ??
				setDisposalCostType(Vgr.enumerations.destination.disposalCostType.CostByDisposal);

			configDisposalCostType();
			configFormEvents();
		};

		function calculateAllCosts() {
			$scope.calculateDisposalGeneral();
			$scope.calculateDisposalResidues();
		}

		$scope.calculateDisposalGeneral = function () {
			$scope.disposalModelCost.totalDisposalValueAndShipping = calculateTotalDisposalCurrent();
			$scope.disposalModelCost.shippingCost = ($scope.disposalModelCost.absoluteShippingCost ?? 0) * -1;

			updateTotalValueLabels($scope.disposalModelCost.totalDisposalValueAndShipping);
		};

		$scope.calculateDisposalResidues = function () {
			calculateGeneralDisposalResidues();
			$scope.disposalModelCost.shippingCost = ($scope.disposalModelCost.absoluteShippingCost ?? 0) * -1;

			updateTotalValueLabels($scope.disposalModelCost.totalResidueValueAndShipping);
		};

		function calculateGeneralDisposalResidues() {
			$scope.disposalModelCost.residueCosts = $scope.disposalModelCost.residueCosts.map((r) => {
				return {
					...r,
					cost: r.residueCostInCome
						? r.residueQuantity * r.absoluteUnitaryPrice
						: r.residueQuantity * r.absoluteUnitaryPrice * -1,
					unitaryPrice: r.residueCostInCome ? r.absoluteUnitaryPrice : r.absoluteUnitaryPrice * -1
				};
			});

			$scope.disposalModelCost.totalResidueValue = $scope.disposalModelCost.residueCosts.reduce(
				(ac, cc) => ac + cc.cost,
				0
			);

			$scope.disposalModelCost.totalResidueValueAndShipping = calculateTotalResidueCostCurrent();

			updateTotalResiduesCostLabel($scope.disposalModelCost);
		}

		function calculateTotalDisposalCurrent() {
			if (!$scope.disposalModelCost.disposalCostInCome) {
				$scope.disposalModelCost.disposalCost = $scope.disposalModelCost.absoluteDisposalCost * -1;
			} else {
				$scope.disposalModelCost.disposalCost = $scope.disposalModelCost.absoluteDisposalCost;
			}

			return $scope.disposalModelCost.disposalCost + getShippingCost() + getOtherCost();
		}

		function calculateTotalResidueCostCurrent() {
			return $scope.disposalModelCost.totalResidueValue + getShippingCost() + getOtherCost();
		}

		function getShippingCost() {
			return $scope.disposalModelCost.absoluteShippingCost ? $scope.disposalModelCost.absoluteShippingCost * -1 : 0;
		}

		function getOtherCost() {
			return $scope.disposalModelCost.absoluteOtherCost ? $scope.disposalModelCost.absoluteOtherCost * -1 : 0;
		}

		function configDisposalCostType() {
			if ($scope.disposalModelCost) {
				selectRadio();
			} else {
				$scope.disposalConfirmationCost.radio = $scope.radioOptions[1];
				$scope.previousSelectedRadio = $scope.radioOptions[1];
			}
		}

		function configFormEvents() {
			if ($scope.ctrl.formEvents) {
				$scope.ctrl.formEvents.forceCalculateCost = function () {
					const deferred = $q.defer();
					deferred.resolve($scope.disposalModelCost);
					return deferred.promise;
				};

				$scope.ctrl.formEvents.recalculateCosts = function () {
					const deferred = $q.defer();
					calculateDisposalCosts(deferred);
					return deferred.promise;
				};
			}
		}

		function selectRadio() {
			for (const index in $scope.radioOptions) {
				if ($scope.radioOptions[index].Id == $scope.disposalModelCost.pricingType) {
					$scope.disposalConfirmationCost.radio = $scope.radioOptions[index];
					$scope.previousSelectedRadio = $scope.radioOptions[index];
				}
			}
		}

		$scope.disposalCostTypeChanged = function () {
			if ($scope.previousSelectedRadio == $scope.disposalConfirmationCost.radio) {
				return;
			}

			$scope.previousSelectedRadio = $scope.disposalConfirmationCost.radio;
			$scope.disposalModelCost.pricingType = $scope.previousSelectedRadio.Id;
		};

		$scope.getQuantityString = function (quantity) {
			if (quantity) {
				return Vgr.util.formatUnitDecimalPlaces(quantity, $scope.priceDecimals);
			}
			return $scope.labels.PENDING_WEIGHT;
		};

		function calculateDisposalCosts(deferred) {
			$scope.disposalModelCost.pricingType = $scope.disposalConfirmationCost.radio.Id;

			disposalCostService.calculateDisposalCosts(getCalculateDisposalCostRequest()).then(
				function (cost) {
					showMessageResponseByCalculateDisposalCosts(cost);
					updateDisposalCosts(cost);
					deferred.resolve(cost);
				},
				function () {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.COSTS_ERROR_MESSAGE);
				}
			);
		}

		function showMessageResponseByCalculateDisposalCosts(cost) {
			if (!cost.residueCosts.length && !cost.disposalCost) {
				$rootScope.$broadcast(Vgr.constants.evtExecuteOperation, {
					text: $scope.labels.COSTS_NOT_FOUND,
					label: $scope.labels.REGISTER_NOW
				});
				return;
			}

			$scope.oldDisposalModelCost = angular.copy($scope.disposalModelCost);
			$scope.disposalModelCost = angular.copy(
				disposalCostService.fillDisposalCostResidueByGrid(cost, $scope.ctrl.disposalModel.Residues)
			);
			calculateAllCosts();

			if ($scope.disposalModelCost.residueCosts.some((r) => r.impossibleToCalculate)) {
				$rootScope.$broadcast(
					Vgr.constants.evtShowErrorMessage,
					getMessageCostConversionError($scope.disposalModelCost.residueCosts.filter((r) => r.impossibleToCalculate))
				);
				return;
			}

			if (!isChangeDisposalCost($scope.disposalModelCost, $scope.oldDisposalModelCost)) {
				$rootScope.$broadcast(Vgr.constants.evtShowInfoMessage, $scope.labels.COSTS_NOT_CHANGE);
				return;
			}

			$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $scope.labels.SUCCESS_COSTS);
		}

		function getMessageCostConversionError(impossibleCalculateCosts) {
			return ` ${$scope.labels.COSTS_ERROR_MESSAGE}: ${impossibleCalculateCosts.map((r) => r.residueName).join(", ")}`;
		}

		function isChangeDisposalCost(newDisposalCost, oldDisposalCost) {
			return (
				isPricingTypeChanged(newDisposalCost, oldDisposalCost) ||
				isShippingCostChanged(newDisposalCost, oldDisposalCost) ||
				areResidueCostsChanged(newDisposalCost, oldDisposalCost) ||
				isTotalResidueValueAndShippingChanged(newDisposalCost, oldDisposalCost) ||
				isTotalResidueValueChanged(newDisposalCost, oldDisposalCost) ||
				isDisposalCostChanged(newDisposalCost, oldDisposalCost) ||
				isTotalCostChanged(newDisposalCost, oldDisposalCost)
			);
		}

		function isPricingTypeChanged(newDisposalCost, oldDisposalCost) {
			return newDisposalCost.pricingType !== oldDisposalCost.pricingType;
		}

		function isShippingCostChanged(newDisposalCost, oldDisposalCost) {
			return (
				isNaN(newDisposalCost.shippingCost) ||
				isNaN(oldDisposalCost.shippingCost) ||
				newDisposalCost.shippingCost !== oldDisposalCost.shippingCost
			);
		}

		function areResidueCostsChanged(newDisposalCost, oldDisposalCost) {
			return newDisposalCost.residueCosts.some(
				(newResidue) =>
					!oldDisposalCost.residueCosts.some(
						(oldResidue) =>
							(isNaN(oldResidue.unitaryPrice) && isNaN(newResidue.unitaryPrice)) ||
							oldResidue.unitaryPrice === newResidue.unitaryPrice
					)
			);
		}

		function isTotalResidueValueAndShippingChanged(newDisposalCost, oldDisposalCost) {
			return (
				isNaN(newDisposalCost.totalResidueValueAndShipping) ||
				isNaN(oldDisposalCost.totalResidueValueAndShipping) ||
				newDisposalCost.totalResidueValueAndShipping !== oldDisposalCost.totalResidueValueAndShipping
			);
		}

		function isTotalResidueValueChanged(newDisposalCost, oldDisposalCost) {
			return (
				isNaN(newDisposalCost.totalResidueValue) ||
				isNaN(oldDisposalCost.totalResidueValue) ||
				newDisposalCost.totalResidueValue !== oldDisposalCost.totalResidueValue
			);
		}

		function isDisposalCostChanged(newDisposalCost, oldDisposalCost) {
			return (
				isNaN(newDisposalCost.disposalCost) ||
				isNaN(oldDisposalCost.disposalCost) ||
				newDisposalCost.disposalCost !== oldDisposalCost.disposalCost
			);
		}

		function isTotalCostChanged(newDisposalCost, oldDisposalCost) {
			return (
				isNaN(newDisposalCost.totalCost) ||
				isNaN(oldDisposalCost.totalCost) ||
				newDisposalCost.totalCost !== oldDisposalCost.totalCost
			);
		}

		function getCalculateDisposalCostRequest() {
			const disposalModel = $scope.ctrl.disposalModel;
			return {
				DestinatorId: disposalModel.Destinator?.SupplierId,
				TransporterId: disposalModel.Transporter?.SupplierId,
				GatheringDate: disposalModel.GatheringDate,
				DisposalResidues: getCalculateDisposalCostResidueRequest()
			};
		}

		function getCalculateDisposalCostResidueRequest() {
			return $scope.ctrl.disposalModel.Residues.map((residue) => {
				return {
					ResidueId: residue.ResidueGuidId,
					RecipientId: residue.RecipientGuidId,
					MeasureUnitId: residue.MeasureUnitId,
					ResidueQuantity: residue.Quantity
				};
			});
		}

		function updateDisposalCosts(cost) {
			if (isDisposalCostType(cost)) {
				setDisposalCostType(Vgr.enumerations.destination.disposalCostType.CostByDisposal);
				updateTotalValueLabels($scope.disposalModelCost.totalDisposalValueAndShipping);
			} else {
				setDisposalCostType(Vgr.enumerations.destination.disposalCostType.CostByResidue);
				updateTotalValueLabels($scope.disposalModelCost.totalResidueValueAndShipping);
			}
			updateTotalResiduesCostLabel(cost);
		}

		function setDisposalCostType(type) {
			$scope.disposalModelCost.pricingType = type;
			selectRadio();
		}

		function isDisposalCostType(cost) {
			return cost.disposalCost != null && cost.disposalCost != 0;
		}

		function updateTotalResiduesCostLabel(cost) {
			if (cost && cost.totalResidueValue < 0) {
				$scope.TotalResiduesValueLabel = $rootScope.labels.RESIDUE_TOTAL_COST;
			} else {
				$scope.TotalResiduesValueLabel = $rootScope.labels.RESIDUE_TOTAL_INCOME;
			}
		}

		function updateTotalValueLabels(totalCost) {
			if (totalCost < 0) {
				$scope.TotalValueLabel = $rootScope.labels.DISPOSAL_TOTAL_COST;
			} else {
				$scope.TotalValueLabel = $rootScope.labels.DISPOSAL_TOTAL_INCOME;
			}
		}

		$scope.$on(Vgr.constants.evtExecuteAction, function (event, data, index) {
			if (data && data.text == $rootScope.labels.COSTS_NOT_FOUND && index) {
				httpService.getListFromServiceCore(supplierDocumentService.listDocumentTypes).then((response) => {
					const url = $state.href("newOrganizationAuthDocument", {
						supplierCode: $scope.ctrl.disposalModel.Destinator.Code,
						docType: response.list.find((d) => d.systemDocumentType.id == 26).id
					});
					$window.open(url, "_blank");
				});
			}
		});
	}
]);
