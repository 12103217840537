angular.module("vgresiduos").directive("hommelDiamondResidue", function () {
	return {
		scope: true,
		controller: "HommelDiamondResidueCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/residue/hommelDiamond/hommelDiamondResidue_template.html",
		bindToController: {
			model: "="
		}
	};
});
