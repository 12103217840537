angular.module("vgresiduos").factory("productV2Service", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		// products

		function getBaseProductsUrl() {
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.products.replace("[ORGANIZATION_UNIT]", accountService.getCurrentClient().code)
			);
		}

		const _listProducts = function (dto) {
			let parameters = "";
			if (dto) {
				if (dto.pageNumber) {
					dto.skip = dto.pageNumber - 1;
				}
				parameters = new URLSearchParams(dto).toString();
			}

			const req = {
				method: "get",
				url: getBaseProductsUrl() + "?" + parameters
			};

			return $http(req);
		};

		const _getProduct = function (id) {
			const req = {
				method: "get",
				url: getBaseProductsUrl() + id
			};

			return $http(req);
		};

		const _createProduct = function (dto) {
			const req = {
				method: "post",
				data: dto,
				url: getBaseProductsUrl()
			};
			return $http(req);
		};

		const _updateProduct = function (dto) {
			const req = {
				method: "put",
				data: dto,
				url: getBaseProductsUrl() + dto.id
			};
			return $http(req);
		};

		const _listOrganizationProducts = function () {
			const organizationId = accountService.getCurrentClient().organization.id;
			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.organizationProducts.replace("[ORGANIZATION_ID]", organizationId)
			};
			return $http(req);
		};

		// productions

		function getBaseProductionsUrl() {
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.productions.replace("[ORGANIZATION_UNIT]", accountService.getCurrentClient().code)
			);
		}

		const _listProductions = function (params) {
			const parameters = Vgr.util.serializeObjectToQueryString(params);

			const req = {
				method: "get",
				url: getBaseProductionsUrl() + parameters
			};

			return $http(req);
		};

		const _getProduction = function (id) {
			const req = {
				method: "get",
				url: getBaseProductionsUrl() + id
			};

			return $http(req);
		};

		const _updateProduction = function (dto) {
			const req = {
				method: "put",
				data: dto,
				url: getBaseProductionsUrl() + dto.id
			};
			return $http(req);
		};

		const _createProduction = function (dto) {
			const req = {
				method: "post",
				data: dto,
				url: getBaseProductionsUrl()
			};
			return $http(req);
		};

		const _deleteProduction = function (id) {
			const req = {
				method: "delete",
				url: getBaseProductionsUrl() + id
			};
			return $http(req);
		};

		return {
			listProducts: _listProducts,
			getProduct: _getProduct,
			createProduct: _createProduct,
			updateProduct: _updateProduct,
			listOrganizationProducts: _listOrganizationProducts,

			listProductions: _listProductions,
			getProduction: _getProduction,
			updateProduction: _updateProduction,
			createProduction: _createProduction,
			deleteProduction: _deleteProduction
		};
	}
]);
