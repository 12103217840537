angular.module("vgresiduos").controller("DestinationGatheringCtrl", [
	"$scope",
	"disposalService",
	"httpService",
	"$state",
	"engagementScoreService",
	function ($scope, disposalService, httpService, $state, engagementScoreService) {
		"use strict";

		$scope.imageSrc = Vgr.constants.s3BaseUrl + "public/images/modulo-de-custos-coleta.svg";
		$scope.redirectUrl =
			"https://vertown.zendesk.com/hc/pt-br/articles/28533952697364-Como-cadastrar-o-contrato-de-custo-do-res%C3%ADduo";

		$scope.model.disposalGatheringIsLocked = false;
		$scope.generalStatus = Vgr.constants.generalStatus;
		$scope.showCostModuleAlerts = Vgr.constants.showCostModuleAlerts;

		$scope.trackClick = function () {
			engagementScoreService.track(Vgr.trackings.destination.messageCost);
		};

		const _printMtrAction = {
			Title: $scope.labels.PRINT_MTR,
			Icon: "print",
			IsRaised: true,
			Action: function () {
				const reports = getExternalSystemManifestsToPrint();
				if (reports.length > 0) {
					generateReports(reports);
				}
			},
			Enabled: function () {
				return true;
			}
		};

		const _toTrackingStageAction = {
			Title: $scope.labels.CONFIRM_RECEIVAL,
			Icon: "check",
			Action: function () {
				$state.go(
					"destinationEdit",
					{ id: $scope.destinationModel.Id, createdFromDisposalModel: null, forceIntegrations: null },
					{ reload: true }
				);
			},
			Enabled: function () {
				return true;
			}
		};

		$scope.getActions = function () {
			const manifestsToPrint = getExternalSystemManifestsToPrint();
			if (manifestsToPrint.length > 0) {
				return [_printMtrAction, _toTrackingStageAction];
			} else {
				return [_toTrackingStageAction];
			}
		};

		function generateReports(reports) {
			const dto = {
				DisposalId: $scope.destinationModel.Id,
				model: {
					Format: "PDF",
					Reports: reports
				}
			};

			httpService.postDTOToServiceV2(disposalService.generateDisposalReports, dto, false, true).then(function (data) {
				downloadReport(data);
			});
		}

		function downloadReport(data) {
			if (data && data.length > 0) {
				const fileDTO = Vgr.util.fileDtoToFile(Vgr.util.capitalizeObjectKeys(data[0]));
				// eslint-disable-next-line no-undef
				saveAs(fileDTO, fileDTO.FileName);
			}
		}

		$scope.isTransporterStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.A;
		};

		$scope.isDriverStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.B;
		};

		$scope.isVehicleStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.C;
		};

		$scope.isIntegrationStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.D;
		};

		$scope.isResidueStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.E;
		};

		$scope.isAttachmentStep = function () {
			return $scope.destinationConfig.currentStepPointer == $scope.stepsEnum.F;
		};

		$scope.enableStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			if ($scope.model.disposalGatheringIsLocked) {
				return;
			}

			if (!isStepEnabled(stepId) && !$scope.destinationConfig.blockEnableNextStep) {
				$scope.destinationConfig.enabledStepPointer = stepId;
			}
		};

		$scope.enableBackToStep = function (stepId) {
			if ($scope.isCurrentStageReview()) {
				return;
			}

			if ($scope.model.disposalGatheringIsLocked) {
				return;
			}

			$scope.destinationConfig.enabledStepPointer = stepId;
		};

		function isStepEnabled(stepId) {
			return stepId <= $scope.destinationConfig.enabledStepPointer;
		}

		function getExternalSystemManifestsToPrint() {
			const reports = [];
			if (!$scope.destinationModel.Manifests) {
				return reports;
			}

			const manifestsToPrint = $scope.destinationModel.Manifests.filter(function (m) {
				return (
					m.Source.Id != Vgr.enumerations.destination.systemSource.Local &&
					m.MtrCode &&
					m.Source.DisabledActions.indexOf(Vgr.enumerations.destination.systemActions.PrintMtr) === -1
				);
			});
			manifestsToPrint.forEach((manifest) => {
				reports.push({
					Type: manifest.ProvisionalMtrCode
						? Vgr.enumerations.destination.report.ProvisionalMTR
						: Vgr.enumerations.destination.report.ResidueTransportManifest,
					SourceId: manifest.Source.Id
				});
			});
			return reports;
		}

		function getManifestsProcessing() {
			if (!$scope.destinationModel.Manifests) {
				return [];
			}

			return $scope.destinationModel.Manifests.filter(
				(m) => m.Source.Id != Vgr.enumerations.destination.systemSource.Local && !m.MtrCode
			);
		}

		$scope.getCompletedLabel = function () {
			const manifestsToPrint = getExternalSystemManifestsToPrint();
			const manifestsProcessing = getManifestsProcessing();
			if (manifestsToPrint.length > 0) {
				return $scope.labels.DISPOSAL_GATHERING_FINISHED;
			}

			return manifestsProcessing.length > 0
				? $scope.labels.DISPOSAL_GATHERING_FINISHED_MTR_PROCESSING
				: $scope.labels.DISPOSAL_GATHERING_FINISHED_BUT_MTR;
		};

		$scope.redirectToSupplierDocuments = function () {
			const url = $state.href("supplierEdit", {
				id: $scope.destinationModel.Destinator.Code,
				tab: 2
			});
			window.open(url, "_blank");
		};

		this.$onInit = function () {
			loadGathering();

			if (gatheringIsLocked()) {
				$scope.model.disposalGatheringIsLocked = !$scope.unlockDisposalGatheringPermission;
				setDisposalGatheringBlockMessage();
				setGatheringIsLockedAlertMessage();
				setDisposalGatheringCustomValidationMessageSteps();
			}
		};

		function gatheringIsLocked() {
			return (
				$scope.destinationModel.LockedGathering &&
				$scope.disposalPreferences.needUnlockDisposalGatheringStage &&
				$scope.destinationModel.Status == Vgr.enumerations.destination.stages.Gathering
			);
		}

		function setDisposalGatheringBlockMessage() {
			const lockedGatheringValidationEnum = Vgr.enumerations.validation.GatheringIsLocked + "";

			let blockMessage = $scope.labels.DISPOSAL_VALIDATIONS_DESCRIPTION_ENUM[lockedGatheringValidationEnum];
			if (!$scope.unlockDisposalGatheringPermission) {
				blockMessage += " " + $scope.labels.CANT_GATHERING;
			}
			$scope.model.disposalGatheringBlockMessage = blockMessage;
		}

		function setGatheringIsLockedAlertMessage() {
			$scope.alertMessages[Vgr.enumerations.destination.alertMessages.gatheringIsLocked] = {
				condition: function () {
					return !$scope.isCurrentStageCompleted() && !$scope.isCurrentStageReview() && $scope.isGatheringStage();
				},
				title: $scope.model.disposalGatheringBlockMessage,
				status: "error"
			};
		}

		function setDisposalGatheringCustomValidationMessageSteps() {
			if (!$scope.model.disposalGatheringIsLocked) {
				return;
			}
			const customValidationMessage = $scope.destinationModel.LockReason
				? $scope.destinationModel.LockReason
				: $scope.model.disposalGatheringBlockMessage;
			$scope.stagesConfig[$scope.stagesEnum.Gathering].Steps.map(function (item) {
				item.CustomValidationMessage = customValidationMessage;
			});
		}

		function loadGathering() {
			if ($scope.stagesConfig[$scope.stagesEnum.Gathering].load) {
				return;
			}

			$scope.stagesConfig[$scope.stagesEnum.Gathering].load = function () {
				const stageGathering = $scope.stagesEnum.Gathering;
				const stepIntegrations = $scope.stepsEnum.D;

				if ($scope.hasIntegrations()) {
					$scope.stagesConfig[stageGathering].Steps[stepIntegrations].IsDisabled = true;
				}

				if ($scope.isGatheringStage()) {
					setGatheringDate();
					if ($scope.destinationModel.GatheringDate) {
						loadCurrentGatheringStep();
					}
				}
			};

			$scope.$emit(Vgr.constants.evtSetDisposalLoad, true);
		}

		function setGatheringDate() {
			if ($scope.destinationModel.GatheringDate) {
				return;
			}

			const estimatedGatheringDate = $scope.destinationModel.EstimatedGatheringDate;
			if (new Date(estimatedGatheringDate) > Vgr.util.getDate()) {
				$scope.destinationModel.GatheringDate = estimatedGatheringDate;
			} else {
				$scope.destinationModel.GatheringDate = Vgr.util.getDate();
			}
		}

		function loadCurrentGatheringStep() {
			if ($scope.destinationModel.Transporter) {
				let stepsEnumId = $scope.stepsEnum.B;
				if ($scope.destinationModel.Driver && $scope.destinationModel.Vehicle) {
					stepsEnumId = $scope.stepsEnum.D;
					const stepIntegrations = $scope.stepsEnum.D;
					if ($scope.stagesConfig[$scope.stagesEnum.Gathering].Steps[stepIntegrations].IsDisabled) {
						stepsEnumId = $scope.stepsEnum.E;
					}
				} else if ($scope.destinationModel.Driver) {
					stepsEnumId = $scope.stepsEnum.C;
				}
				const stepId = $scope.destinationConfig.currentStage.Steps[stepsEnumId].Id;
				setStageStep(stepId);
			}
		}

		function setStageStep(stepId) {
			$scope.destinationConfig.currentStepPointer = stepId;
			$scope.destinationConfig.enabledStepPointer = stepId;
		}
	}
]);
