angular.module("vgresiduos").controller("FispqFormCtrl", [
	"$scope",
	"$rootScope",
	"permissionService",
	function ($scope, $rootScope, permissionService) {
		"use strict";

		var editClientDataPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.MasterCreateEditClientData);

		this.$onInit = function () {
			$scope.editClientDataPermission = editClientDataPermission;
			$rootScope.contentNavBarGrid = false;
			$scope.rowsNumber = 5;
			$scope.maxLength = 2000;

			$scope.$watch("fispqForm.$valid", function (newVal) {
				$scope.ctrl.saveOptions.valid = newVal;
			});
			$scope.canGenerate = false;
			$scope.$watch("ctrl.inputFispq", function (newVal) {
				$scope.canGenerate = newVal && newVal.ID && newVal.TemplateS3Key;
			});
			$scope.generatingStatus = Vgr.constants.loadingStates.IDLE;
		};
	}
]);
