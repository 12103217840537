angular.module("vgresiduos").factory("reportService", [
	"$http",
	"accountService",
	"engagementScoreService",
	function ($http, accountService, engagementScoreService) {
		"use strict";

		const _generateIbamaReport = function (filters) {
			const req = {
				method: "POST",
				data: filters,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.reports + "ibama-as-generator"
			};

			engagementScoreService.track(Vgr.trackings.declarations.rappIbamaGenerateReport);
			return $http(req);
		};

		const _generateMasterUserReport = function (filters) {
			const req = {
				method: "POST",
				data: filters,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.reports + "master/users"
			};

			return $http(req);
		};

		const _calculateKpi = function (filters) {
			const req = {
				method: "POST",
				data: filters,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.reports + "kpis"
			};
			return $http(req);
		};

		const _generateGoalResidueDisposalFinancialReport = function (filters) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "POST",
				data: filters,
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.goalReports.replace("[ORGANIZATION_ID]", organizationId) +
					"/disposal-costs"
			};
			return $http(req);
		};

		const _generateGoalResidueGenerationReport = function (filters) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "POST",
				data: filters,
				url:
					Vgr.constants.biHostUrl +
					Vgr.resources.bi.goalReports.replace("[ORGANIZATION_ID]", organizationId) +
					"/residue-generations"
			};
			return $http(req);
		};

		const _generateClientAuditDocument = function (dto) {
			const req = {
				method: "POST",
				data: dto.filters,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.reports + "audits/" + dto.auditId
			};

			engagementScoreService.track(Vgr.trackings.supplier.audit.print);
			return $http(req);
		};

		const _generateDnitReport = function (filters) {
			const organizationId = accountService.getCurrentClient().organization.id;

			const req = {
				method: "POST",
				data: filters,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV2.dnitIntegration.replace("[ORGANIZATION_ID]", organizationId)
			};

			return $http(req);
		};

		return {
			generateGoalResidueDisposalFinancialReport: _generateGoalResidueDisposalFinancialReport,
			generateGoalResidueGenerationReport: _generateGoalResidueGenerationReport,
			generateIbamaReport: _generateIbamaReport,
			generateClientAuditDocument: _generateClientAuditDocument,
			generateMasterUserReport: _generateMasterUserReport,
			calculateKpi: _calculateKpi,
			generateDnitReport: _generateDnitReport
		};
	}
]);
