angular.module("vgresiduos").controller("ActionPlanCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"$state",
	"$stateParams",
	"httpService",
	"actionPlanService",
	"supplierService",
	"supplierContactService",
	"dialogService",
	"accountService",
	function (
		$rootScope,
		$scope,
		$q,
		$state,
		$stateParams,
		httpService,
		actionPlanService,
		supplierService,
		supplierContactService,
		dialogService,
		accountService
	) {
		"use strict";

		const suppliersCanEditActionPlan = false;
		$scope.editPermission = false;

		const supplierCode = $stateParams.supplierCode;
		const corporateOrigin = $stateParams.corporateOrigin;
		const actionPlanId = $stateParams.actionPlanId;
		$scope.supplierId = $stateParams.supplierId;
		$scope.actionPlanSupplier = null;
		$scope.supplierContacts = null;

		this.$onInit = function () {
			$scope.actionPlanStatusEnum = Vgr.enumerations.actionPlan.Status;
			$scope.generalStatus = Vgr.constants.generalStatus;

			$scope.originalModel = {};
			$scope.model = {};

			$scope.goBack = function () {
				internalGoBack(false);
			};

			$scope.goToAuditEditPage = goToAuditEditPage;

			$scope.onAddItem = function () {
				$scope.model.actionPlanItems.push({});
			};

			$scope.onDeleteItem = function (index) {
				$scope.model.actionPlanItems.splice(index, 1);
			};

			loadActionPlan(loadSupplierContacts);
		};

		function configureContentBar() {
			$rootScope.contentNavBar = {
				hasPermission: function () {
					return hasPermissionForEditActionPlan();
				},
				title: $scope.labels.ACTION_PLAN,
				breadcrumb: actionPlanId,
				validate: saveActionPlanValidate,
				save: function () {
					return onSaveActionPlan().then(function () {
						loadActionPlan();
					});
				},
				onSaveError: function () {},
				back: function () {
					internalGoBack(true);
				},
				valid: true,
				customButtons: [
					{
						label: $scope.labels.EFFECT,
						buttonClasses: "md-primary",
						icon: "check",
						supressToast: true,
						onClick: function () {
							const params = {
								popupTitle: $rootScope.labels.ACTION_PLAN,
								popupText: $rootScope.labels.EFFECT_ACTION_PLAN_CONFIRMATION,
								popupAuxiliaryText: null,
								cancelButtonLabel: null,
								confirmButtonLabel: null,
								popupDescription: $rootScope.labels.EFFECT_ACTION_PLAN_CONFIRMATION_DESCRIPTION,
								cancelMethod: null,
								confirmMethod: null
							};

							openConfirmationPopup(params).then(function () {
								onSaveActionPlan().then(function () {
									activateActionPlan().then(function () {
										loadActionPlan();
									});
								});
							});

							return $q.resolve();
						}
					},
					{
						label: $scope.labels.FINALIZE,
						buttonClasses: "md-primary",
						icon: "check",
						supressToast: true,
						onClick: function () {
							const params = {
								popupTitle: $rootScope.labels.ACTION_PLAN,
								popupText: areAllItemsExecuted()
									? $rootScope.labels.FINALIZE_ACTION_PLAN_CONFIRMATION
									: $rootScope.labels.FINALIZE_ACTION_PLAN_CONFIRMATION_WITHOUT_ALL_EXECUTED,
								popupAuxiliaryText: null,
								cancelButtonLabel: null,
								confirmButtonLabel: null,
								popupDescription: $rootScope.labels.FINALIZE_ACTION_PLAN_CONFIRMATION_DESCRIPTION,
								cancelMethod: null,
								confirmMethod: null
							};

							openConfirmationPopup(params).then(function () {
								onSaveActionPlan().then(function () {
									finalizeActionPlan().then(function () {
										loadActionPlan();
									});
								});
							});

							return $q.resolve();
						}
					}
				],
				buttons: []
			};
		}

		function internalGoBack(forceActionPlanTab) {
			if (corporateOrigin || !isActionPlanSupplier()) {
				$state.go("supplierEdit", {
					id: $scope.supplierId,
					tab: forceActionPlanTab || corporateOrigin == "actionPlan" ? 7 : 6
				});
			} else {
				$state.go("transporterEditWithContext", {
					id: supplierCode,
					context: Vgr.enumerations.supplier.tabPageRoute.clientActionPlans
				});
			}
		}

		function loadSupplierContacts() {
			const dto = {
				supplierId: $scope.supplierId,
				supplierCode: supplierCode
			};

			httpService.getListFromServiceCore(supplierContactService.listContacts, dto).then(
				function (response) {
					$scope.supplierContacts = response.list;
				},
				function () {}
			);
		}

		function areAllItemsExecuted() {
			return !$scope.model.actionPlanItems.some((item) => !item.executed);
		}

		function openConfirmationPopup(params) {
			const deferred = $q.defer();
			dialogService
				.showDialogFromTemplateV2(
					"views/components/popups/confirmPopup_template.html",
					"ConfirmPopupCtrl",
					null,
					params
				)
				.then(
					function () {
						deferred.resolve();
					},
					function () {
						deferred.reject();
					}
				);
			return deferred.promise;
		}

		function saveActionPlanValidate() {
			let invalidItem;
			if ($scope.model.status == Vgr.enumerations.actionPlan.Status.Created) {
				if (!$scope.model.user) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
					return false;
				}

				invalidItem = $scope.model.actionPlanItems.find((item) => !item.description);
				if (invalidItem != null) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
					return false;
				}

				return true;
			} else if ($scope.model.status == Vgr.enumerations.actionPlan.Status.Pending) {
				invalidItem = $scope.model.actionPlanItems.find(
					(item) => item.executed && !item.comments && item.actionPlanItemAttachments.length == 0
				);
				if (invalidItem != null) {
					$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
					return false;
				}

				return true;
			}
		}

		function onSaveActionPlan() {
			if (isOwner()) {
				if ($scope.model.status == Vgr.enumerations.actionPlan.Status.Created) {
					return updateActionPlan();
				} else if ($scope.model.status == Vgr.enumerations.actionPlan.Status.Pending) {
					return executeActionPlanItems();
				}
			} else if (hasPermissionForEditActionPlan()) {
				return executeActionPlanItems();
			} else {
				const deferred = $q.defer();
				return deferred.promise;
			}
		}

		function getChangedItems() {
			const changedItems = [];

			$scope.originalModel.actionPlanItems.forEach((originalItem) => {
				const modelItem = $scope.model.actionPlanItems.find((item) => item.id == originalItem.id);

				if (itemChanged(originalItem, modelItem)) {
					changedItems.push(modelItem);
				}
			});

			return changedItems;
		}

		function itemChanged(originalItem, modelItem) {
			//todo: implementar futuramente, não fucniona por causa da proriedade $$hashKey: "object:'x'"
			//return objectHash(originalItem) != objectHash(modelItem);

			if (originalItem.executed != modelItem.executed || originalItem.comments != modelItem.comments) {
				return true;
			}

			if (originalItem.actionPlanItemAttachments.length != modelItem.actionPlanItemAttachments.length) {
				return true;
			}

			let anyChangedAttachment = false;

			originalItem.actionPlanItemAttachments.forEach((originalAtt) => {
				const foundModelItem = modelItem.actionPlanItemAttachments.find((att) => originalAtt.Path == att.Path);

				if (!foundModelItem) {
					anyChangedAttachment = true;
				}
			});

			return anyChangedAttachment;
		}

		function executeActionPlanItems() {
			const list = angular.copy(getChangedItems());

			return executeItemsSync(list);
		}

		function executeItemsSync(list) {
			return executeActionPlanItem(0, list);
		}

		function getExecuteItemDtoData(item) {
			const data = {
				Executed: item.executed,
				Comments: item.comments,
				Attachments: []
			};

			item.actionPlanItemAttachments.forEach((att) => {
				data.Attachments.push({
					Name: att.Name,
					Path: att.Path
				});
			});

			return data;
		}

		function getUpdateDtoData() {
			const data = {
				UserResponsibleId: $scope.model.user.id,
				Items: []
			};

			$scope.model.actionPlanItems.forEach((item) => {
				data.Items.push({
					Id: item.id,
					Description: item.description,
					ExpirationDate: Vgr.date.fromDateToUTCString(item.expirationDate),
					SupplierContactId: item.contact ? item.contact.id : null
				});
			});

			return data;
		}

		function getBaseDto() {
			return {
				supplierId: $scope.supplierId,
				actionPlanId: actionPlanId
			};
		}

		function executeActionPlanItem(index, list) {
			if (index == list.length) {
				return $q.resolve();
			}

			const deferred = $q.defer();

			const dto = getBaseDto();
			dto.actionPlanItemId = list[index].id;
			dto.data = getExecuteItemDtoData(list[index]);

			httpService.postDTOToServiceV2(actionPlanService.executeActionPlanItem, dto, true).then(
				function () {
					index++;
					executeActionPlanItem(index, list).then(
						function () {
							deferred.resolve();
						},
						function () {
							deferred.reject();
						}
					);
				},
				function () {
					index++;
					executeActionPlanItem(index, list).then(
						function () {
							deferred.reject();
						},
						function () {
							deferred.reject();
						}
					);
				}
			);

			return deferred.promise;
		}

		function updateActionPlan() {
			const dto = getBaseDto();
			dto.data = getUpdateDtoData();

			return httpService.postDTOToServiceV2(actionPlanService.updateActionPlan, dto);
		}

		function activateActionPlan() {
			const dto = getBaseDto();

			return httpService.postDTOToServiceV2(actionPlanService.activateActionPlan, dto);
		}

		function finalizeActionPlan() {
			const dto = getBaseDto();

			return httpService.postDTOToServiceV2(actionPlanService.finalizeActionPlan, dto);
		}

		function getActionPlan() {
			const dto = isActionPlanSupplier() ? { actionPlanId: actionPlanId } : getBaseDto();
			const service = isActionPlanSupplier()
				? actionPlanService.getSupplierActionPlan
				: actionPlanService.getActionPlan;
			return httpService.getDTOFromServiceV2(service, dto);
		}

		function loadActionPlan(loadSupplierContactsAction) {
			$scope.loadingFinished = false;
			if (corporateOrigin) {
				httpService
					.getDTOFromServiceV2(supplierService.getOrganizationSupplierByCode, { supplierCode: supplierCode })
					.then(function (supplier) {
						$scope.supplierId = supplier.supplierId;
						$scope.actionPlanSupplier = supplier;
						getActionPlan().then(function (audit) {
							initializePage(audit);
							$scope.loadingFinished = true;
						});
						if (loadSupplierContactsAction) {
							loadSupplierContactsAction();
						}
					});
			} else {
				getActionPlan().then(function (audit) {
					initializePage(audit);
					$scope.loadingFinished = true;
				});
			}
		}

		function initializePage(audit) {
			buildModel(audit);
			$scope.editPermission = hasPermissionForEditActionPlan();
			configureContentBar();
			configureButtons();
		}

		function buildModel(data) {
			data.creationDateFormated = new Date(data.creationDate);
			data.actionPlanItems.forEach((item) => {
				item.expirationDate = item.expirationDate ? new Date(item.expirationDate) : null;
				item.executed = item.percentageCompleted ? true : false;
				item.actionPlanItemAttachments.forEach((att) => {
					att.Name = att.name;
					att.Path = att.path;
				});
			});

			$scope.originalModel = angular.copy(data);
			$scope.model = angular.copy(data);

			if (!$scope.model.actionPlanItems.length) {
				$scope.onAddItem();
			}
		}

		function configureButtons() {
			$rootScope.contentNavBar.buttons = [];
			if (hasPermissionForEditActionPlan()) {
				if ($scope.model.status == Vgr.enumerations.actionPlan.Status.Created) {
					$rootScope.contentNavBar.buttons.push($scope.labels.EFFECT);
					$rootScope.contentNavBar.buttons.push($rootScope.labels.SAVE);
				} else if ($scope.model.status == Vgr.enumerations.actionPlan.Status.Pending) {
					$rootScope.contentNavBar.buttons.push($scope.labels.FINALIZE);
					$rootScope.contentNavBar.buttons.push($rootScope.labels.SAVE);
				}
			}
		}

		function goToAuditEditPage() {
			$state.go("auditEdit", {
				supplierId: $scope.supplierId,
				supplierCode: supplierCode,
				auditId: $scope.model.clientAuditId,
				corporateOrigin: corporateOrigin
			});
		}

		function isOwner() {
			return $scope.model.organization.id == accountService.getCurrentClient().organization.id;
		}

		function hasPermissionForEditActionPlan() {
			const isStatusPending = $scope.model.status == Vgr.enumerations.actionPlan.Status.Pending;
			return (
				(isStatusPending && suppliersCanEditActionPlan && isActionPlanSupplier()) ||
				($scope.actionPlanSupplier &&
					supplierService.hasSupplierPermission(
						$scope.actionPlanSupplier,
						Vgr.systemPermissions.SupplierOrganizationActionPlan,
						Vgr.systemPermissions.SupplierActionPlan
					))
			);
		}

		function isActionPlanSupplier() {
			return $scope.supplierId == accountService.getCurrentClient().id;
		}
	}
]);
