angular.module("vgresiduos").controller("GoalsListCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"$state",
	"$stateParams",
	function ($rootScope, $scope, $q, $state, $stateParams) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.yearFilterOptions = {};
		$scope.filter = {};

		const minYearRange = 3;
		const maxYearRange = 3;

		$rootScope.contentNavBar = {
			title: $scope.labels.GOAL_REGISTRY,
			valid: true,
			buttons: []
		};

		this.$onInit = function () {
			$scope.yearFilterOptions.initialYear = new Date().getFullYear();
			$scope.yearFilterOptions.minYear = $scope.yearFilterOptions.initialYear - minYearRange;
			$scope.yearFilterOptions.maxYear = $scope.yearFilterOptions.initialYear + maxYearRange;

			$scope.filter.year = $scope.yearFilterOptions.initialYear;
		};

		$scope.yearChanged = function (year) {
			$scope.filter.year = year;
		};
	}
]);
