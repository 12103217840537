angular.module("vgresiduos").controller("InviteUserCustomizedFormCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.options = {
				availableFields: [],
				rows: []
			};

			if ($scope.ctrl.invitationMode == Vgr.enumerations.userInviteMode.sso) {
				$scope.options.availableFields.push(buildEmailField());

				$scope.options.availableFields.push({
					type: Vgr.enumerations.customizedFilter.type.Text,
					label: $rootScope.labels.SSO_ID,
					id: "ssoId",
					isEnabled: true,
					isOptionEnabled: function () {
						return true;
					}
				});
			} else if ($scope.ctrl.invitationMode == Vgr.enumerations.userInviteMode.username) {
				$scope.options.availableFields.push(buildEmailField());

				$scope.options.availableFields.push({
					type: Vgr.enumerations.customizedFilter.type.Text,
					label: $rootScope.labels.USERNAME_TO_INVITE,
					id: "username",
					isEnabled: true,
					isOptionEnabled: function () {
						return true;
					}
				});
			}

			$scope.ctrl.model = $scope.options.rows;
		};

		function buildEmailField() {
			return {
				type: Vgr.enumerations.customizedFilter.type.Text,
				label: $rootScope.labels.EMAIL_TO_INVITE + "*",
				id: "email",
				isEnabled: true,
				isOptionEnabled: function () {
					return true;
				}
			};
		}
	}
]);
