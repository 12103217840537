angular.module("vgresiduos").controller("DisposalIntegrationsPopupCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"dialogService",
	"disposalService",
	"deviceDetector",
	"asyncTimeoutService",
	"residuesManagementService",
	"localVariables",
	function (
		$scope,
		$rootScope,
		httpService,
		dialogService,
		disposalService,
		deviceDetector,
		asyncTimeoutService,
		residuesManagementService,
		localVariables
	) {
		"use strict";

		$scope.operationStatus = Vgr.enumerations.operationStatus;
		$scope.integrationStatus = Vgr.enumerations.destination.systemStatus;
		$scope.systems = localVariables.systems;
		$scope.destinationModel = localVariables.destinationModel;
		$scope.updateIntegrationSystemsModels = localVariables.updateIntegrationSystemsModels;
		$scope.loadings = localVariables.loadings;
		$scope.listClientExternalSystems = localVariables.listClientExternalSystems;
		$scope.editPermission = localVariables.editPermission;
		$scope.integrationsInfoPopupEnabled = localVariables.integrationsInfoPopupEnabled;
		$scope.clientExternalSystems = localVariables.clientExternalSystems;
		$scope.getSystemStatusDescription = localVariables.getSystemStatusDescription;
		$scope.readOnlyModel = localVariables.readOnlyModel;
		$scope.isUpToDate = localVariables.isUpToDate;
		$scope.closeIntegrationStatusAndOpenInfoPopup = localVariables.closeIntegrationStatusAndOpenInfoPopup;
		$scope.openIntegrationsPopup = localVariables.openIntegrationsPopup;
		$scope.disableNewIntegrations = localVariables.disableNewIntegrations;
		$scope.onUpdateDisposalManifest = localVariables.onUpdateDisposalManifest;
		$scope.listenForDisposalFilesEvents = localVariables.listenForDisposalFilesEvents;

		$scope.labels = $rootScope.labels;
		$scope.observable$ = undefined;

		$scope.showIntegratedErrorsSourceIds = [];

		$scope.goBack = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.openIntegrationsInfoPopup = function (system) {
			$scope.closeIntegrationStatusAndOpenInfoPopup(system);
		};

		$scope.updateIntegration = function (system) {
			if ($scope.loadings.integration) {
				return;
			}

			$scope.loadingUpdate = true;

			system.status = $scope.operationStatus.Processing;
			$scope.loadings.integration = true;

			const dto = {
				disposalId: $scope.destinationModel.Id,
				sourceId: system.SourceId,
				newProvisionalCode: system.newProvisionalCode
			};

			system.ErrorMessage = "";
			listenForEvents(system, isCreatingIntegration(system));
			httpService.postDTOToServiceV2(residuesManagementService.updateDisposalManifestsBySource, dto, true).then(
				function () {
					addToShowIntegratedErrors(system.SourceId);
				},
				function (response) {
					addToShowIntegratedErrors(system.SourceId);
					asyncTimeoutService.cancelAsyncTimer(system);
					unsubscribeTopic();
					onErrorUpdateDisposalIntegration(system, response);
				}
			);
		};

		function isCreatingIntegration(system) {
			return system.Manifests.some((m) => m.Status == Vgr.enumerations.destination.systemStatus.Pending);
		}

		function updateManifestsErrorMessage(manifest, system, errors, status) {
			if (errors && errors.length > 0) {
				manifest.Errors = [
					{
						Code: errors[0].Code ?? errors[0].code,
						Message: disposalService.getIntegrationErrorMessageFromResponse(status, errors, system.Description)
					}
				];
			} else {
				manifest.Errors = null;
			}
		}

		function onErrorUpdateDisposalIntegration(system, response) {
			system.isCancelError = false;
			system.status =
				response.status == 400
					? Vgr.enumerations.operationStatus.BadRequestError
					: Vgr.enumerations.operationStatus.UnexpectedError;
			$scope.loadings.integration = false;
			for (const manifest of system.Manifests) {
				updateManifestsErrorMessage(manifest, system, response.data, response.status);
			}
			$scope.loadingUpdate = false;
			calculateShowNewIntegrations();
		}

		$scope.getDisposalIntegrationLabel = function (manifest) {
			if (manifest.MtrCode) {
				return `${$scope.labels.MTR} ${manifest.MtrCode}`;
			}
			return $scope.labels.MTR;
		};

		$scope.onUpdateToProvisionalSuccess = function (systemId) {
			const system = $scope.popupSystems.find((ps) => ps.SourceId == systemId);

			if (system != null) {
				$scope.loadings.integration = true;
				$scope.loadingCancel = true;
				system.status = $scope.operationStatus.Processing;
				httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id).then(
					function (data) {
						$scope.loadings.integration = false;
						$scope.loadingCancel = false;
						system.status = $scope.operationStatus.Success;
						$scope.destinationModel.Manifests = data.Disposal.Manifests;
						$scope.popupSystems = angular.copy($scope.updateIntegrationSystemsModels());
						setDefaultCancelReason();
						setInitialStateSystems();
					},
					function (data) {
						$scope.loadingCancel = true;
						system.isCancelError = false;
						system.status =
							data.status == 400
								? Vgr.enumerations.operationStatus.BadRequestError
								: Vgr.enumerations.operationStatus.UnexpectedError;
						$scope.loadings.integration = false;
					}
				);
			}
		};

		$scope.toggleCancelOpen = function (system) {
			system.CancelOpen = !system.CancelOpen;
			checkErrorMessageOnCancelClose(system);
		};

		$scope.closeCancel = function (system) {
			system.CancelOpen = false;
			checkErrorMessageOnCancelClose(system);
		};

		function checkErrorMessageOnCancelClose(system) {
			if (!system.CancelOpen && system.errorMessage) {
				system.errorMessage = null;
			}
		}

		$scope.cancelIntegration = function (system) {
			if (!system.CancelReason) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.JUSTIFICATION_REQUIRED);
				return;
			}
			system.ErrorMessage = null;
			$scope.loadings.integration = true;
			$scope.loadingCancel = true;
			system.status = $scope.operationStatus.Processing;

			const dto = {
				organizationUnitCode: $scope.destinationModel.Client.Code,
				disposalId: $scope.destinationModel.Id,
				sourceId: system.SourceId,
				params: {
					cancelReason: system.CancelReason
				}
			};

			listenForEvents(system);
			httpService.postDTOToServiceV2(disposalService.cancelDisposalIntegration, dto, true).then(
				function (data) {
					addToShowIntegratedErrors(system.SourceId);
					if (!data.IsAsyncCancellation) {
						afterCancellationSuccessfull(system);
					}
				},
				function (response) {
					afterCancellationError(response, system);
					unsubscribeTopic();
				}
			);
		};

		function afterCancellationError(response, system) {
			asyncTimeoutService.cancelAsyncTimer(system);

			system.isCancelError = true;
			system.ErrorMessage = disposalService.getIntegrationErrorMessageFromResponse(
				response.status,
				response.data,
				system.Description
			);
			system.status =
				response.status == 400
					? Vgr.enumerations.operationStatus.BadRequestError
					: Vgr.enumerations.operationStatus.UnexpectedError;
			$scope.loadings.integration = false;
			$scope.loadingCancel = false;
			calculateShowNewIntegrations();
		}

		function afterCancellationSuccessfull(system) {
			asyncTimeoutService.cancelAsyncTimer(system);

			$scope.destinationModel.Manifests = getDisposalManifestsNotFromSystem(system);

			system.status = $scope.operationStatus.Success;
			system.ErrorMessage = null;
			system.NoActions = true;
			system.CancelOpen = false;
			system.Manifests.forEach((manifest) => {
				manifest.Errors = null;
				manifest.Status = Vgr.enumerations.destination.systemStatus.Canceled;
				manifest.IsUpToDate = true;
				manifest.StatusDescription = $scope.getSystemStatusDescription(
					Vgr.enumerations.destination.systemStatus.Canceled
				);
			});

			$scope.systems = $scope.updateIntegrationSystemsModels();
			setDefaultCancelReason();
			$scope.loadings.integration = false;
			$scope.loadingCancel = false;
			calculateShowNewIntegrations();
		}

		function getDisposalManifestsNotFromSystem(system) {
			const disposalManifestsNotFromSystem = [];
			$scope.destinationModel.Manifests.forEach((manifest) => {
				if (manifest.Source.Id != system.SourceId) {
					disposalManifestsNotFromSystem.push(manifest);
				}
			});
			return disposalManifestsNotFromSystem;
		}

		$scope.toNewIntegrations = function () {
			if ($scope.disableNewIntegrations) {
				return;
			}

			const params = {
				localVariables: {
					destinationModel: $scope.destinationModel,
					readOnlyModel: $scope.readOnlyModel,
					systems: $scope.systems,
					listClientExternalSystems: $scope.listClientExternalSystems,
					clientExternalSystems: $scope.clientExternalSystems,
					updateIntegrationSystemsModels: $scope.updateIntegrationSystemsModels,
					loadings: $scope.loadings,
					openIntegrationsPopup: $scope.openIntegrationsPopup,
					disableNewIntegrations: $scope.disableNewIntegrations
				}
			};

			const dialog = dialogService.showDialogFromTemplateV2(
				"views/destination/popups/disposalAddIntegrationsPopup/disposalAddIntegrationsPopup_template.html",
				"DisposalAddIntegrationsPopupCtrl",
				"#disposal-popup-medium",
				params,
				true
			);

			$scope.dialog = dialog;
		};

		$scope.integrationInfoPopupEnabled = function (system) {
			return (
				!system.NoActions &&
				$scope.editPermission &&
				!$scope.isToBlockFieldsBasedOnDisposalDate &&
				system.Manifests.some(
					(manifest) =>
						(manifest.Status == Vgr.enumerations.destination.systemStatus.Disposed ||
							manifest.Status == Vgr.enumerations.destination.systemStatus.Received) &&
						manifest.actions.canViewDetails
				) &&
				$scope.integrationsInfoPopupEnabled()
			);
		};

		function setDefaultCancelReason() {
			$scope.popupSystems.forEach((system) => {
				system.CancelReason = $scope.labels.CANCEL_MTR_DEFAULT_REASON;
			});
		}

		function calculateShowNewIntegrations() {
			$scope.showNewIntegrations = false;
			$scope.listClientExternalSystems().then(function () {
				const systemsToIgnore = [];

				$scope.systems.forEach((systems) => {
					systemsToIgnore.push(systems.SourceId);
				});

				for (const externalSystem of $scope.clientExternalSystems) {
					if (systemsToIgnore.includes(externalSystem.source.id)) {
						continue;
					}

					$scope.showNewIntegrations = true;
				}
			});
		}

		function setInitialStateSystems() {
			$scope.popupSystems.forEach(function (system) {
				system.Manifests.forEach(function (manifest) {
					updateManifestsErrorMessage(manifest, system, manifest.Errors, Vgr.enumerations.httpErrorCode.BadRequest);
				});
				$scope.loadings.integration = false;
				$scope.loadingCancel = false;
			});
		}

		function isProvisionalMtrActive(system) {
			const clientExternalSystem = $scope.clientExternalSystems.find((ces) => ces.sourceId == system.SourceId);
			return clientExternalSystem && clientExternalSystem.canUseProvisionalMtr;
		}

		$scope.getReasonNoProvisionalMtr = function (system) {
			if (systemIsUnavailable(system) && isExportDisposal()) {
				return $scope.labels.NO_PROVISIONAL_MTR_EXPORTATION;
			}

			return "";
		};

		$scope.showAutomaticCreateProvisionalMtr = function (system) {
			return (
				!$scope.disableNewIntegrations &&
				systemIsUnavailable(system) &&
				isProvisionalMtrActive(system) &&
				!isExportDisposal()
			);
		};

		function isExportDisposal() {
			return $scope.destinationModel.Model == Vgr.enumerations.destination.model.Export;
		}

		function systemIsUnavailable(system) {
			const clientExternalSystems = $scope.clientExternalSystems.find((es) => es.source.id == system.SourceId);
			if (clientExternalSystems.canUseProvisionalMtr) {
				for (const manifest of system.Manifests) {
					const isPending = manifest.Status == Vgr.enumerations.destination.systemStatus.Pending;
					const isUnavailable = hasErrorCode(manifest, Vgr.errorCodes.disposal.SystemUnavailable);
					const isInstable = hasErrorCode(manifest, Vgr.errorCodes.disposal.SystemInstable);
					const isInstableOnCreation = hasErrorCode(manifest, Vgr.errorCodes.disposal.SystemInstableOnCreation);
					const isInternalError = hasErrorCode(manifest, Vgr.errorCodes.disposal.InternalError);
					const isWrongGeneratorProfileSinir =
						hasErrorCode(manifest, Vgr.errorCodes.disposal.WrongGeneratorProfile) &&
						system.SourceId == Vgr.enumerations.destination.systemSource.SINIR_BR;
					const isUnexpectedErrorCode = hasUnexpectedErrorCode(manifest);
					if (
						isPending &&
						(!!isUnavailable ||
							!!isInstableOnCreation ||
							!!isInstable ||
							!!isInternalError ||
							!!isUnexpectedErrorCode ||
							!!isWrongGeneratorProfileSinir)
					) {
						return true;
					}
				}
			}
			return false;
		}

		$scope.showAutomaticProvisionalCodeError = function (system, manifest) {
			return hasErrorCode(manifest, Vgr.errorCodes.disposal.InvalidMTRProvisoryCode) && manifest.IsAutomaticProvisional;
		};

		function hasErrorCode(manifest, errorCode) {
			if (manifest.Errors && manifest.Errors.length > 0) {
				return manifest.Errors.find((error) => error.Code == errorCode);
			}

			return false;
		}

		function hasUnexpectedErrorCode(manifest) {
			if (manifest.Status == Vgr.enumerations.operationStatus.UnexpectedError) {
				return true;
			}

			if (manifest.Errors && manifest.Errors.length > 0) {
				return manifest.Errors.find((error) => error.Code == null && error.Message == null);
			}

			return false;
		}

		function addToShowIntegratedErrors(sourceId) {
			if ($scope.showIntegratedErrorsSourceIds.includes(sourceId)) {
				return;
			}
			$scope.showIntegratedErrorsSourceIds.push(sourceId);
		}

		$scope.showErrorMessages = function (system, manifest) {
			const isProcessing = system.status === $scope.operationStatus.Processing;
			const isProvisionalMtr = manifest.Status === $scope.integrationStatus.Provisional;
			const isCancelOpen = system.CancelOpen;
			const isMissingMtrCode = !manifest.MtrCode;

			// check if there are any errors to show
			return (
				!isProcessing &&
				!isCancelOpen &&
				(isMissingMtrCode || isProvisionalMtr || $scope.showIntegratedErrorsSourceIds.includes(system.SourceId))
			);
		};

		$scope.getMtrStatusHint = function (manifest) {
			if (manifest.Status == $scope.integrationStatus.Provisional) {
				const hasProvisionalMtrNotReceivedErrorOrInvalidCode =
					manifest.Errors &&
					(manifest.Errors.find((error) => error.Code == Vgr.errorCodes.disposal.ProvisionalMTRNotReceived) ||
						manifest.Errors.find((error) => error.Code == Vgr.errorCodes.disposal.InvalidMTRProvisoryCode));
				if (!manifest.CancelOpen && !hasProvisionalMtrNotReceivedErrorOrInvalidCode) {
					return $scope.labels.PROVISIONAL_MTR_STATUS_HINT_2;
				}
			} else if (manifest.Status == $scope.integrationStatus.PendingManualAction) {
				return $scope.labels.DISPOSAL_SYSTEM_INTEGRATION_STATUS_DETAILED[$scope.integrationStatus.PendingManualAction];
			} else if (!manifest.actions.canUpdateStatus) {
				return $scope.labels.IT_IS_NOT_POSSIBLE_TO_UPDATE_STATUS_FOR_THE_SYSTEM_X.replace(
					"[SYSTEM]",
					manifest.Description
				);
			}
			return "";
		};

		$scope.isMobile = function () {
			return deviceDetector.isMobile();
		};

		$scope.showUpdateStatusButton = function (system) {
			return (
				!system.NoActions &&
				$scope.editPermission &&
				system.Manifests.every(
					(manifest) => manifest.actions.canUpdateStatus && manifest.Status != $scope.integrationStatus.Canceled
				) &&
				systemCanUpdateStatus(system) &&
				!$scope.isProcessing(system)
			);
		};

		$scope.isProcessing = function (system) {
			return system.status == $scope.operationStatus.Processing && $scope.loadingUpdate;
		};

		$scope.loadingAction = function (system) {
			return system.status == $scope.operationStatus.Processing || $scope.loadings.integration;
		};

		$scope.showCancelButton = function (system) {
			return (
				!system.NoActions && $scope.editPermission && system.Manifests.every((manifest) => manifest.actions.canCancel)
			);
		};

		$scope.getCancelConfirmationMessage = function (system) {
			if (!$scope.anyProvisionalOrPending(system)) {
				return $scope.labels.CANCEL_MTR_CONFIRMATION;
			}

			const isProvisional = system.Manifests.some(
				(manifest) => manifest.Status == $scope.integrationStatus.Provisional
			);
			if (isProvisional) {
				return getProvisionalCancelConfirmationMessage(system.Manifests);
			} else {
				return getMtrCancelConfirmationMessage(system.Manifests);
			}
		};

		function getProvisionalCancelConfirmationMessage(manifests) {
			const isProvisional = manifests.map((manifest) => manifest.Status == $scope.integrationStatus.Provisional);

			if (isProvisional.every((item) => !!item)) {
				return $scope.labels.PROVISIONAL_MTR_CANCEL_CONFIRMATION;
			}

			if (isProvisional.some((item) => !!item)) {
				return $scope.labels.MTR_CANCEL_CONFIRMATION_WITH_ANY_PENDING;
			}
		}

		function getMtrCancelConfirmationMessage(manifests) {
			const isPending = manifests.map((manifest) => manifest.Status == $scope.integrationStatus.Pending);

			if (isPending.every((item) => !!item)) {
				return $scope.labels.MTR_CANCEL_CONFIRMATION_PENDING;
			}

			if (isPending.some((item) => !!item)) {
				return $scope.labels.MTR_CANCEL_CONFIRMATION_WITH_ANY_PENDING;
			}
		}

		$scope.isProvisionalOrPending = function (system) {
			return system.Manifests.every(
				(manifest) =>
					manifest.Status == $scope.integrationStatus.Provisional || manifest.Status == $scope.integrationStatus.Pending
			);
		};

		$scope.anyProvisionalOrPending = function (system) {
			return system.Manifests.some(
				(manifest) =>
					manifest.Status == $scope.integrationStatus.Provisional || manifest.Status == $scope.integrationStatus.Pending
			);
		};

		function systemCanUpdateStatus(system) {
			return system.Manifests.some((manifest) => manifest.Status != $scope.integrationStatus.PendingManualAction);
		}

		function initializePopup() {
			$scope.popupSystems = angular.copy($scope.systems);
			setDefaultCancelReason();
			setInitialStateSystems();
			calculateShowNewIntegrations();
		}

		let tries = 0;

		function clearLoading() {
			$scope.loadings.integration = false;
			$scope.loadingUpdate = false;
		}

		function updateDisposalManifest(system, manifest, timedOut) {
			httpService.getDTOFromServiceV2(disposalService.getDisposal, $scope.destinationModel.Id).then(
				function (response) {
					if (system || manifest) {
						processUpdateDisposalManifest(system, manifest, timedOut, response);
					} else {
						buildPopupSystemWithUpdateManifests(response);
						clearLoading();
					}
				},
				function () {
					clearLoading();
				}
			);
		}

		function processUpdateDisposalManifest(system, manifest, timedOut, response) {
			const responseDisposalManifests = response.Disposal.Manifests.filter(
				(m) => m.Source.Id == system.SourceId || (manifest && manifest.ManifestId == m.Id)
			);
			const manifestsToUpdate = $scope.destinationModel.Manifests.filter((m) => m.Source.Id == system.SourceId);

			if (isManifestCancelled(responseDisposalManifests, system)) {
				afterCancellationSuccessfull(system);
				clearLoading();
			} else if (!hasUpdated(responseDisposalManifests, manifestsToUpdate) && !timedOut) {
				return retryUpdateDisposalManifest(system, manifest, timedOut, responseDisposalManifests, response);
			} else {
				asyncTimeoutService.cancelAsyncTimer(system);
				updateDisposalManifestInfo(responseDisposalManifests, system, response);
				clearLoading();

				responseDisposalManifests.forEach((dm) => {
					$scope.onUpdateDisposalManifest(dm.Source.Id, dm.Status, dm.Status);
				});
			}
		}

		function retryUpdateDisposalManifest(system, manifest, timedOut, responseDisposalManifests, response) {
			tries++;
			if (tries < 5) {
				Vgr.util.sleep(5000).then(function () {
					updateDisposalManifest(system, manifest, timedOut);
				});
			} else if (tries == 5) {
				asyncTimeoutService.cancelAsyncTimer(system);
				updateDisposalManifestInfo(responseDisposalManifests, system, response);

				responseDisposalManifests.forEach((dm) => {
					$scope.onUpdateDisposalManifest(dm.Source.Id, dm.Status, dm.Status);
				});

				clearLoading();
			}
		}

		function updateDisposalManifestInfo(responseDisposalManifests, system, response) {
			if (responseDisposalManifests.some((dm) => !dm.MtrCode && !dm.Errors)) {
				buildInstabilityAsyncResponseError(system);
			} else {
				buildPopupSystemWithUpdateManifests(response);
			}
		}

		function hasUpdated(responseDisposalManifests, manifestsToUpdate) {
			return manifestsToUpdate.every(function (m) {
				const responseManifest = responseDisposalManifests.find((dm) => dm.Id == m.Id);
				return responseManifest != null && responseManifest.UpdateDate != m.UpdateDate;
			});
		}

		function isManifestCancelled(responseDisposalManifests, system) {
			return responseDisposalManifests.length == 0 && system;
		}

		function buildInstabilityAsyncResponseError(system) {
			const errorUpdateAsync = {
				status: Vgr.enumerations.httpErrorCode.BadRequest,
				data: [
					{
						Code: Vgr.errorCodes.disposal.SystemInstable,
						Message: $scope.labels.INSTABILITY_ASYNC_RESPONSE_NO_REFRESH
					}
				]
			};
			onErrorUpdateDisposalIntegration(system, errorUpdateAsync);
		}

		function buildPopupSystemWithUpdateManifests(response) {
			$scope.destinationModel.Manifests = response.Disposal.Manifests;
			$scope.popupSystems = angular.copy($scope.updateIntegrationSystemsModels());
			setDefaultCancelReason();
			setInitialStateSystems();
			calculateShowNewIntegrations();
		}

		initializePopup();

		//init socket methods ---------------------------------------------------------------------------------------

		function onErrorUpdateAsync(system, data) {
			const errors = data.errors;
			const errorsCapitalize = errors.map((e) => Vgr.util.capitalizeObjectKeys(e));
			const responseError = {
				status: getStatusFromErrors(errorsCapitalize),
				data: errorsCapitalize
			};
			if ($scope.loadingCancel) {
				afterCancellationError(responseError, system);
			} else {
				onErrorUpdateDisposalIntegration(system, responseError);
			}
		}

		function getStatusFromErrors(errors) {
			if (errors && errors.length > 0) {
				const badRequestError = errors.find((e) => e.Code && e.Code != "000");
				if (badRequestError) {
					return 400;
				}
			}
			return 500;
		}

		function onSuccessUpdateAsync(system, data) {
			const manifest = system.Manifests.find((m) => m.SourceId == data.sourceId);
			updateDisposalManifest(system, manifest);
		}

		function getRoomName(disposalId, sourceId) {
			return "disposal_" + disposalId + "_source_" + sourceId;
		}

		function listenForEvents(system, isCreation) {
			subscribeTopic(system);
			asyncTimeoutService.startAsyncCallTimer(
				system,
				onTimeoutNoResponseFromServer,
				asyncTimeoutService.getTimeoutBySystem(system.SourceId, isCreation) * system.Manifests.length,
				8
			);
		}

		function subscribeTopic(system) {
			$scope.observable$ = window.Amplify.API.graphql(
				window.Amplify.graphqlOperation(Vgr.constants.graphql.subscriptions, {
					name: getRoomName($scope.destinationModel.Id, system.SourceId)
				})
			).subscribe({
				next: ({ value }) => callBackTopicSucces(JSON.parse(value.data.subscribe2channel.data)),
				error: (e) => {
					if (e) {
						console.log(e);
					}
					unsubscribeTopic();
				}
			});
		}
		function callBackTopicSucces(data) {
			const system = $scope.popupSystems.find((e) => e.SourceId == data.sourceId);
			asyncTimeoutService.cancelAsyncTimer(system);
			if (data.success || !data.errors) {
				onSuccessUpdateAsync(system, data);
				unsubscribeTopic();
			} else {
				onErrorUpdateAsync(system, data);
				unsubscribeTopic();
				$scope.loadingUpdate = false;
			}
		}

		function cancelAllTimers() {
			$scope.popupSystems.forEach((system) => {
				asyncTimeoutService.cancelAsyncTimer(system);
			});
		}

		function onTimeoutNoResponseFromServer(system, timedOut) {
			if (timedOut) {
				unsubscribeTopic();
			}
			updateDisposalManifest(system, null, timedOut);
		}
		function unsubscribeTopic() {
			if ($scope.observable$) {
				$scope.observable$.unsubscribe();
			}
		}

		//end socket methods ---------------------------------------------------------------------------------------

		$scope.$on("$destroy", function () {
			cancelAllTimers();
			unsubscribeTopic();

			// nullify the DOM-bound model
			$scope.domElement = null;
		});
	}
]);
