angular.module("vgresiduos").factory("actionPlanService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		var actionPlanService = {};

		function getBaseUrl(supplierId) {
			var organizationId = accountService.getCurrentClient().organization.id;

			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.actionPlans.replace("[ORGANIZATION_ID]", organizationId).replace("[SUPPLIER_ID]", supplierId)
			);
		}
		function getBaseUrlsForSupplier() {
			const supplierOrganizationId = accountService.getCurrentClient().organization.id;
			const supplierUnitId = accountService.getCurrentClient().id;

			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.supplierActionPlans
					.replace("[SUPPLIER_ORGANIZATION_ID]", supplierOrganizationId)
					.replace("[UNIT_ID]", supplierUnitId)
			);
		}

		// CRUD
		var _listActionPlans = function (dto) {
			var parameters = "";

			if (dto.queryParams) {
				parameters = $.param(dto.queryParams, true);
			}

			var req = {
				method: "get",
				url: getBaseUrl(dto.supplierId) + "?" + parameters
			};

			return $http(req);
		};

		var _createActionPlan = function (dto) {
			var data = {
				clientAuditId: dto.clientAuditId
			};

			var req = {
				method: "post",
				data: data,
				url: getBaseUrl(dto.supplierId)
			};

			return $http(req);
		};

		var _deleteActionPlan = function (dto) {
			var req = {
				method: "delete",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId
			};

			return $http(req);
		};

		var _getActionPlan = function (dto) {
			var req = {
				method: "get",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId
			};

			return $http(req);
		};

		var _updateActionPlan = function (dto) {
			var req = {
				method: "put",
				data: dto.data,
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId
			};

			return $http(req);
		};

		// Supplier
		var _listSupplierActionPlans = function (dto) {
			var parameters = "";

			if (dto.queryParams) {
				parameters = $.param(dto.queryParams, true);
			}

			var req = {
				method: "get",
				url: getBaseUrlsForSupplier() + "?" + parameters
			};

			return $http(req);
		};

		var _getSupplierActionPlan = function (dto) {
			var req = {
				method: "get",
				url: getBaseUrlsForSupplier() + dto.actionPlanId
			};

			return $http(req);
		};

		// Others
		var _finalizeActionPlan = function (dto) {
			var req = {
				method: "post",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId + "/finalization"
			};

			return $http(req);
		};

		var _activateActionPlan = function (dto) {
			var req = {
				method: "post",
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId + "/activation"
			};

			return $http(req);
		};

		var _executeActionPlanItem = function (dto) {
			var req = {
				method: "post",
				data: dto.data,
				url: getBaseUrl(dto.supplierId) + dto.actionPlanId + "/action-plan-items/" + dto.actionPlanItemId + "/execution"
			};

			return $http(req);
		};

		actionPlanService.listActionPlans = _listActionPlans;
		actionPlanService.createActionPlan = _createActionPlan;
		actionPlanService.deleteActionPlan = _deleteActionPlan;
		actionPlanService.getActionPlan = _getActionPlan;
		actionPlanService.updateActionPlan = _updateActionPlan;

		actionPlanService.finalizeActionPlan = _finalizeActionPlan;
		actionPlanService.activateActionPlan = _activateActionPlan;
		actionPlanService.executeActionPlanItem = _executeActionPlanItem;

		actionPlanService.listSupplierActionPlans = _listSupplierActionPlans;
		actionPlanService.getSupplierActionPlan = _getSupplierActionPlan;

		return actionPlanService;
	}
]);
