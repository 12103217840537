/* eslint-disable no-undef */
angular.module("vgresiduos").controller("generalTopAlertsCtrl", [
	"$scope",
	"$rootScope",
	"accountService",
	"localStorageService",
	"$state",
	"dialogService",
	"engagementScoreService",
	"userAccessService",
	"clientService",
	"$sce",
	function (
		$scope,
		$rootScope,
		accountService,
		localStorageService,
		$state,
		dialogService,
		engagementScoreService,
		userAccessService,
		clientService,
		$sce
	) {
		"use strict";

		$scope.currentAlerts = [];

		let onChangeLoggedClient;
		const dateDiffParam = "[DATE_DIFF]";
		let currentClient = accountService.getCurrentClient();

		const hasBiDashboardAccess = userAccessService.hasAccess(null, null, Vgr.constants.featureToggles.release.biModule);

		this.$onInit = function () {
			refreshAlerts();
			buildAlertPopups();
			buildNewFeaturesPopups();
			openDmrAlert();
			onChangeLoggedClient = $rootScope.$on(Vgr.constants.evtChangeLoggedClient, function () {
				currentClient = accountService.getCurrentClient();
				$scope.currentAlerts = [];
				refreshAlerts();
				buildAlertPopups();
				buildNewFeaturesPopups();
				openDmrAlert();
			});
		};

		function refreshAlerts() {
			if (!currentClient) {
				return;
			}

			const contractEndAlert = getContractEndAlert();
			if (contractEndAlert != null) {
				$scope.currentAlerts.push(contractEndAlert);
			}
			const contractBlockAlert = getContractBlockAlert();
			if (contractBlockAlert != null) {
				$scope.currentAlerts.push(contractBlockAlert);
			}
			const completeRegisterAlert = getCompleteRegisterAlert();
			if (completeRegisterAlert != null) {
				$scope.currentAlerts.push(completeRegisterAlert);
			}
			const bannerInfoAlert = getBannerInfoAlert();
			if (bannerInfoAlert != null) {
				$scope.currentAlerts.push(bannerInfoAlert);
			}
		}

		function buildAlertPopups() {
			if (!currentClient || !isCurrentClientPayer()) {
				return;
			}

			const alertPopups = [];

			openInfoAlertPopups(alertPopups);
		}

		function isCurrentClientPayer() {
			const contractTypePayerStatus = [
				Vgr.enumerations.organization.contractType.Payer,
				Vgr.enumerations.organization.contractType.Trial
			];

			return contractTypePayerStatus.includes(currentClient.organization.contractType);
		}

		this.$onDestroy = function () {
			// disable the listener
			onChangeLoggedClient();

			// nullify the DOM-bound model
			$scope.domElement = null;
		};

		function getContractEndAlert() {
			const endContractDate = Vgr.util.getDateFromString(currentClient.organization.endDate);
			const contractType = currentClient.organization.contractType;

			const contractEndAlert = {
				key: "contractEndAlertKey",
				showEasyAlert: false,
				showHardAlert: false,
				alertMessage: null
			};
			localStorageService.set(contractEndAlert.key, false);
			if (!endContractDate || !isCurrentClientPayer()) {
				return contractEndAlert;
			}

			const now = new Date();
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
			const dateDiff = Vgr.util.compareDays(endContractDate, today);

			if (dateDiff < 0) {
				contractEndAlert.showHardAlert = true;
				if (contractType == Vgr.enumerations.organization.contractType.Payer) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_END_DATE_ALERT_PAYER;
				} else if (contractType == Vgr.enumerations.organization.contractType.Trial) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_END_DATE_ALERT_TRIAL;
				}
			} else if (dateDiff < 30 && !localStorageService.get(contractEndAlert.key)) {
				contractEndAlert.showEasyAlert = true;
				if (contractType == Vgr.enumerations.organization.contractType.Payer) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_END_ALERT_PAYER.replace(
						dateDiffParam,
						dateDiff
					);
				} else if (contractType == Vgr.enumerations.organization.contractType.Trial) {
					contractEndAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_END_ALERT_TRIAL.replace(
						dateDiffParam,
						dateDiff
					);
				}
			}
			return contractEndAlert;
		}

		function getContractBlockAlert() {
			const blockDate = Vgr.util.getDateFromString(currentClient.organization.blockDate);

			const contractBlockAlert = {
				key: "contractBlockAlertKey",
				showEasyAlert: false,
				showHardAlert: false,
				alertMessage: null
			};
			localStorageService.set(contractBlockAlert.key, false);
			if (!blockDate || !isCurrentClientPayer()) {
				return contractBlockAlert;
			}

			const oneDay = 24 * 60 * 60 * 1000;
			const today = new Date();
			const dateDiff = Math.ceil((blockDate - today) / oneDay);

			if (dateDiff < 0) {
				contractBlockAlert.alertMessage = $rootScope.labels.CONTRACT_BLOCK_DATE_ALERT;
				contractBlockAlert.showHardAlert = true;
			} else if (dateDiff < 30 && !localStorageService.get(contractBlockAlert.key)) {
				const day = addZero(blockDate.getDate());
				const month = addZero(blockDate.getMonth() + 1);
				const year = blockDate.getFullYear();
				const block_day = dateStructure(day, month, year);
				contractBlockAlert.alertMessage = $rootScope.labels.CONTRACT_GOINGTO_BLOCK_ALERT.replace(
					"[BLOCK_DAY]",
					block_day
				);
				contractBlockAlert.showEasyAlert = true;
			}
			return contractBlockAlert;
		}

		function getCompleteRegisterAlert() {
			let completeRegisterAlert = null;
			if (currentClient.registerCompleted == undefined || !currentClient.registerCompleted) {
				completeRegisterAlert = {
					key: "completeRegisterAlertKey",
					showEasyAlert: true,
					alertMessage: $rootScope.labels.COMPLETE_REGISTER_MESSAGE,
					clickEvent: function () {
						$state.go("clientEdit", { id: currentClient.id });
					}
				};
			}
			return completeRegisterAlert;
		}

		const bannerInfoAlertKey = "disableAlertBanner_FridayHoliday";
		function getBannerInfoAlert() {
			if ($rootScope.labels.BANNER_ALERT_MESSAGE) {
				const disableAlertBanner = localStorageService.get(bannerInfoAlertKey);
				if (!disableAlertBanner) {
					return {
						key: bannerInfoAlertKey,
						showEasyAlert: true,
						alertMessage: $rootScope.labels.BANNER_ALERT_MESSAGE,
						isHtml: true
					};
				}
			} else {
				localStorageService.set(bannerInfoAlertKey, false);
				return null;
			}
		}

		function addZero(variable) {
			if (variable < 10) {
				return "0" + variable;
			} else {
				return variable;
			}
		}

		function dateStructure(day, month, year) {
			return day + "/" + month + "/" + year;
		}

		function openInfoAlertPopups(alertPopups) {
			for (const popupAlert of alertPopups) {
				const disableInfoAlertPopup = localStorageService.get(popupAlert.alertPopupKey);
				if (!disableInfoAlertPopup) {
					dialogService
						.showDialogFromTemplateV2(
							"views/components/generalTopAlerts/popup/generalTopAlertsPopup_template.html",
							"GeneralTopAlertsPopupCtrl",
							".vgr-page",
							{ alertPopupModel: popupAlert },
							true
						)
						.then(
							function () {
								localStorageService.set(popupAlert.alertPopupKey, true);
							},
							function () {
								localStorageService.set(popupAlert.alertPopupKey, true);
							}
						);
				}
			}
		}

		$scope.closeAlert = function (storageKey) {
			const alert = $scope.currentAlerts.find((a) => a.key == storageKey);
			if (!alert.showHardAlert) {
				localStorageService.set(storageKey, true);
			}
			alert.showEasyAlert = false;
			alert.showInfoAlert = false;
			alert.showHardAlert = false;
		};

		$scope.executeAlertFunction = function (storageKey) {
			$scope.currentAlerts.forEach((a) => {
				if (a.key == storageKey && a.clickEvent) {
					a.clickEvent();
				}
			});
		};

		function openDmrAlert() {
			const dateMoment = moment();
			const hasDmrAccess = userAccessService.hasAccess(Vgr.systemPermissions.GenerateDmr);
			const dmrKeyPrefix = "dmrAlertPopup_";
			const dmrAlertPopupKey = `${dmrKeyPrefix}${dateMoment.quarter()}_${dateMoment.year()}`;
			const disableDmrAlertPopup = localStorageService.get(dmrAlertPopupKey);
			if (
				!disableDmrAlertPopup &&
				isPeriodToShowDmrAlert(dateMoment) &&
				hasDmrAccess &&
				!accountService.isMarketProfile()
			) {
				dialogService
					.showDialogFromTemplateV2(
						"views/components/generalTopAlerts/dmrAlertPopup/dmrAlertPopup_template.html",
						"dmrAlertPopupCtrl",
						".vgr-page",
						{ alertPopupModel: null },
						true
					)
					.then(
						function () {
							// do nothing
						},
						function () {
							// do nothing
						}
					);
			}
		}

		function isPeriodToShowDmrAlert(dateMoment) {
			const dayOfMonth = dateMoment.date();
			const currentMonth = dateMoment.month() + 1;
			const monthsAlert = [1, 4, 7, 10]; // meses p/ alerta
			return monthsAlert.includes(currentMonth) && dayOfMonth >= 5;
		}

		function buildNewFeaturesPopups() {
			if (!currentClient || !isCurrentClientPayer()) {
				return;
			}

			const reportData = buildCustomReportNewFeaturesPopup();
			if (reportData) {
				openNewFeaturesPopups(reportData);
			}
		}

		function buildCustomReportNewFeaturesPopup() {
			if (clientService.isSupplier()) {
				return;
			}

			if (hasBiDashboardAccess) {
				if (!localStorageService.get(Vgr.enumerations.newFeaturesKey.biModule)) {
					return buildByFeatureType(Vgr.enumerations.newFeaturesType.biModule);
				}
			}
		}

		function buildByFeatureType(newFeatureType) {
			const featurePopupModel = getFeatureToShow(newFeatureType);
			return {
				key: featurePopupModel.key,
				imageLink: featurePopupModel.imageLink,
				knowMore: featurePopupModel.knowMore,
				buttons: featurePopupModel.buttons,
				isManualAccess: featurePopupModel.isManualAccess,
				isFepamAlert: featurePopupModel.isFepamAlert,
				isManualAccessKnowMoreBelow: featurePopupModel.isManualAccessKnowMoreBelow
			};
		}

		function getFeatureToShow(newFeatureType) {
			switch (newFeatureType) {
				case Vgr.enumerations.newFeaturesType.mtrEmission:
					return getMtrEmission();
				case Vgr.enumerations.newFeaturesType.ctfIntegration:
					return getCtfIntegration();
				case Vgr.enumerations.newFeaturesType.sgorsIntegration:
					return getSgorsIntegration();
				case Vgr.enumerations.newFeaturesType.newStorageModule:
					return getNewStorageModule();
				case Vgr.enumerations.newFeaturesType.disposalCostsModule:
					return getDisposalCostsModule();
				case Vgr.enumerations.newFeaturesType.notifySupplierExpiringMtr:
					return getNotifySupplierMtrExpiring();
				case Vgr.enumerations.newFeaturesType.fepamAlert:
					return getFepamAlert();
				case Vgr.enumerations.newFeaturesType.internalTransferControl:
					return getInternalTransferControl();
				case Vgr.enumerations.newFeaturesType.removeOldGatherinPage:
					return getRemoveOldGatheringPage();
				case Vgr.enumerations.newFeaturesType.residueRastreabilityReport:
					return getResidueRastreabilityReport();
				case Vgr.enumerations.newFeaturesType.biModule:
					return getBiModule();

				default:
					break;
			}
		}

		function getDisposalCostsModule() {
			return {
				key: Vgr.enumerations.newFeaturesKey.disposalCostsModule,
				imageLink: "/img/generalTopAlerts/disposalCostsModule.png",
				isManualAccess: true,
				knowMore: function () {
					trackEventAction(Vgr.trackings.supplier.costsModule.accessManual);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.disposalCostsModule;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							trackEventAction(Vgr.trackings.supplier.costsModule.closePopup);
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.CREATE,
						class: "md-primary md-raised",
						click: function () {
							trackEventAction(Vgr.trackings.supplier.costsModule.accessPage);
							dialogService.confirm();
							$state.go("supplierList");
						}
					}
				]
			};
		}

		function getNewStorageModule() {
			var labelButton = $scope.labels.GENERAL_TOP_ALERTS.USE;

			return {
				key: Vgr.enumerations.newFeaturesKey.newStorageModule,
				imageLink: "/img/generalTopAlerts/newStorageModule.png",
				isManualAccessKnowMoreBelow: true,
				knowMore: function () {
					trackEventAction(Vgr.trackings.internal.gathering.accessManualNewFeature);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.newStorageModule;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary button-large",
						click: function () {
							trackEventAction(Vgr.trackings.internal.gathering.closePopupNewFeature);
							dialogService.confirm();
						}
					},
					{
						label: labelButton,
						class: "md-primary md-raised button-large",
						click: function () {
							dialogService.confirm();

							trackEventAction(Vgr.trackings.internal.gathering.userTriedNewFeature);
							$state.go("storageList");
						}
					}
				]
			};
		}

		function getSgorsIntegration() {
			return {
				key: Vgr.enumerations.newFeaturesKey.sgorsIntegration,
				imageLink: "/img/generalTopAlerts/sgorsIntegration.png",
				isManualAccess: true,
				knowMore: function () {
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.mtrCreation;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							trackEventAction(Vgr.trackings.disposal.integration.sgors.closePopup);
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.CREATE,
						class: "md-primary md-raised",
						click: function () {
							trackEventAction(Vgr.trackings.disposal.integration.sgors.createIntegration);
							dialogService.confirm();

							$state.go("clientEdit", {
								id: currentClient.id,
								tab: Vgr.enumerations.clientEdit.tabPage.integrations
							});
						}
					}
				]
			};
		}

		function getCtfIntegration() {
			const hasPermissionComplianceModule = userAccessService.hasAccess(
				null,
				Vgr.constants.featureToggles.permission.complianceModule,
				null
			);
			const reportData = {
				key: Vgr.enumerations.newFeaturesKey.ctfIntegration,
				imageLink: "/img/generalTopAlerts/integrationCtfIbamaDivulgation.png",
				isManualAccess: true,
				knowMore: function () {
					trackEventAction(Vgr.trackings.supplier.integrationCtfIbamaDivulgation.compliance.accessPage);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.integrationCtfIbamaDivulgation;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							trackEventAction(Vgr.trackings.supplier.integrationCtfIbamaDivulgation.compliance.closePopup);
							dialogService.confirm();
						}
					}
				]
			};
			if (!hasPermissionComplianceModule) {
				reportData.buttons = [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							trackEventAction(Vgr.trackings.supplier.integrationCtfIbamaDivulgation.noCompliance.closePopup);
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.TO_MARK_CONVERSATION,
						class: "md-primary md-raised",
						click: function () {
							trackEventAction(Vgr.trackings.supplier.integrationCtfIbamaDivulgation.noCompliance.markConversation);

							const url = Vgr.constants.calendly.baseUrl + Vgr.constants.calendly.profiles.ctfIbama;
							window.open(url, "_blank");
						}
					}
				];
				reportData.isMarkConversation = true;
			}

			return reportData;
		}

		function getMtrEmission() {
			return {
				key: Vgr.enumerations.newFeaturesKey.mtrEmission,
				imageLink: "/img/generalTopAlerts/gatherDisposalOnCreateFromModel.jpg",
				isManualAccess: true,
				knowMore: function () {
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.gatherDisposalOnCreateFromModel;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							trackEventAction(Vgr.trackings.disposal.gatherOnCreateFromModel.closePopup);
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.ENABLE,
						class: "md-primary md-raised",
						click: function () {
							trackEventAction(Vgr.trackings.disposal.gatherOnCreateFromModel.accessPage);
							dialogService.confirm();

							$state.go("clientEdit", {
								id: currentClient.id,
								tab: Vgr.enumerations.clientEdit.tabPage.disposalPreferences
							});
						}
					}
				]
			};
		}

		function getInternalTransferControl() {
			return {
				key: Vgr.enumerations.newFeaturesKey.internalTransferControl,
				imageLink: "/img/generalTopAlerts/internalTransferControl.png",
				isManualAccess: true,
				isManualAccessKnowMoreBelow: false,
				knowMore: function () {
					trackEventAction(Vgr.trackings.internal.transfer.accessManualNewFeature);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.internalTransferControl;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary button-large",
						click: function () {
							trackEventAction(Vgr.trackings.internal.transfer.closePopupNewFeature);
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.ENABLE,
						class: "md-primary md-raised button-large",
						click: function () {
							dialogService.confirm();

							trackEventAction(Vgr.trackings.internal.transfer.userInterestedNewFeature);
							$state.go("clientEdit", {
								id: currentClient.id,
								tab: Vgr.enumerations.clientEdit.tabPage.storagePreferences
							});
						}
					}
				]
			};
		}

		function openNewFeaturesPopups(reportData) {
			const disablePopup = localStorageService.get(reportData.key);
			if (disablePopup || reportData == null) {
				return;
			}
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/newFeatures/newFeaturesPopup_template.html",
					"NewFeaturesPopupCtrl",
					".vgr-page",
					{ popupModel: reportData },
					true
				)
				.then(
					function () {
						localStorageService.set(reportData.key, true);
					},
					function () {
						localStorageService.set(reportData.key, true);
					}
				);
		}

		function trackEventAction(event) {
			engagementScoreService.track(event);
		}

		$scope.trustedHtml = function (plainText) {
			return $sce.trustAsHtml(plainText);
		};

		function getNotifySupplierMtrExpiring() {
			return {
				key: Vgr.enumerations.newFeaturesKey.notifySupplierExpiringModule,
				imageLink: "/img/generalTopAlerts/notifySupplierExpiringMtr.svg",
				isManualAccess: true,
				knowMore: function () {
					trackEventAction(Vgr.trackings.disposal.integration.notifySupplierExpiringMtr.accessManual);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.notifySupplierExpiringMtr;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							trackEventAction(Vgr.trackings.disposal.integration.notifySupplierExpiringMtr.closePopup);
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.CONFIGURE,
						class: "md-primary md-raised",
						click: function () {
							trackEventAction(Vgr.trackings.disposal.integration.notifySupplierExpiringMtr.accessPage);
							dialogService.confirm();
							$state.go("clientEdit", {
								id: currentClient.id,
								tab: Vgr.enumerations.clientEdit.tabPage.disposalPreferences
							});
						}
					}
				]
			};
		}

		function getRemoveOldGatheringPage() {
			return {
				key: Vgr.enumerations.newFeaturesKey.removeOldGatherinPage,
				imageLink: "/img/generalTopAlerts/removeOldGatheringPage.svg",
				isManualAccess: true,
				knowMore: function () {
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.removeOldGatheringPage;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.USE,
						class: "md-primary md-raised",
						click: function () {
							dialogService.confirm();
							$state.go("storageList", {});
						}
					}
				]
			};
		}

		function getResidueRastreabilityReport() {
			return {
				key: Vgr.enumerations.newFeaturesKey.residueRastreabilityReport,
				imageLink: "/img/generalTopAlerts/residueRastreabilityReport.svg",
				isManualAccess: true,
				knowMore: function () {
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.residueRastreabilityReport;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.ACCESS,
						class: "md-primary md-raised",
						click: function () {
							dialogService.confirm();
							$state.go("residueTraceabilityReport", {});
						}
					}
				]
			};
		}

		function getBiModule() {
			return {
				key: Vgr.enumerations.newFeaturesKey.biModule,
				imageLink: "/img/generalTopAlerts/biModule.svg",
				hasContactOurSupport: true,
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary",
						click: function () {
							dialogService.confirm();
						}
					},
					{
						label: $scope.labels.GENERAL_TOP_ALERTS.ACCESS,
						class: "md-primary md-raised",
						click: function () {
							dialogService.confirm();
							$state.go("bi", {});
						}
					}
				]
			};
		}
	}
]);
