angular.module("vgresiduos").controller("ClientFormPreferencesCtrl", [
	"$scope",
	"$q",
	"$state",
	"httpService",
	"organizationService",
	"permissionService",
	"accountService",
	function ($scope, $q, $state, httpService, organizationService, permissionService, accountService) {
		"use strict";

		const createInactivatePermission = permissionService.hasPermissionV2(
			Vgr.systemPermissions.MasterCreateEditClientData
		);
		$scope.createInactivatePermission = createInactivatePermission;

		const currentClient = accountService.getCurrentClient();
		const isMasterClient = accountService.isMasterClient();
		$scope.isMaster = permissionService.isMaster();

		$scope.IsCNPJRequired = false;

		let globalSave;
		let globalValidate;

		this.$onInit = function () {
			if (!$scope.ctrl.inputClient.City) {
				$scope.countryId = Vgr.constants.country.Brazil;
			} else {
				$scope.countryId = $scope.ctrl.inputClient.City.State.CountryId;
			}
			globalSave = angular.copy($scope.ctrl.saveOptions.save);
			globalValidate = angular.copy($scope.ctrl.saveOptions.validate);

			$scope.isLowerGenerationPlan = $scope.ctrl.isLowerGenerationPlan;

			if ($scope.ctrl.inputClient.ID) {
				if ($scope.ctrl.inputClient.ClientGroup.ID == currentClient.organization.id) {
					$scope.isCreation = false;
				} else {
					$state.go("unitList");
				}
				if ($scope.ctrl.inputClient.CNPJ != null) {
					$scope.IsCNPJRequired = true;
				}
			} else {
				$scope.isCreation = true;
				$scope.ctrl.inputClient.IsActive = true;
				$scope.IsCNPJRequired = true;
				if (isMasterClient) {
					$scope.enableSelectClientGroup = true;
				} else {
					$scope.ctrl.inputClient.Organization = {
						Id: currentClient.organization.id,
						Name: currentClient.organization.name
					};
				}
			}
		};

		$scope.$watch("clientForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});

		$scope.organizationsSubfieldList = [
			{
				item: "Id",
				label: $scope.labels.ID
			},
			{
				item: "ContractTypeDescription",
				label: $scope.labels.CONTRACT_TYPE
			}
		];

		$scope.organizationsPaginationOptions = {
			pageSize: 40,
			pageNumber: 1,
			query: ""
		};

		$scope.listOrganizations = function () {
			const deferred = $q.defer();

			if (!$scope.organizationsPaginationOptions.query) {
				$scope.organizationsPaginationOptions.query = "";
			}

			const params = {
				skip: $scope.organizationsPaginationOptions.pageNumber - 1,
				take: $scope.organizationsPaginationOptions.pageSize,
				nameLike: $scope.organizationsPaginationOptions.query
			};

			httpService.getListFromServiceV2(organizationService.listOrganizations, params, "Organizations").then(
				function (list) {
					formatContractType(list);
					deferred.resolve(list);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		};

		function formatContractType(organizations) {
			for (const organization of organizations) {
				organization.ContractTypeDescription = $scope.labels.CONTRACT_TYPE_ENUM[organization.ContractType];
			}
		}

		$scope.nameChanged = function () {
			if (!$scope.isCreation || $scope.tradeNameTouched) {
				return;
			}

			$scope.ctrl.inputClient.Name = $scope.ctrl.inputClient.SocialName;
		};

		$scope.onTradeNameFocus = function () {
			$scope.tradeNameTouched = true;
		};

		$scope.onSelectResponsibleUsers = function (responsibleUsers) {
			$scope.ctrl.inputClient.ResponsibleUsers = responsibleUsers;
		};

		$scope.$watch("clientGeneralTabSelected", function (newValue, oldValue) {
			if (newValue) {
				$scope.ctrl.saveOptions.save = globalSave;
				$scope.ctrl.saveOptions.validate = globalValidate;
			}
		});
	}
]);
