angular.module("vgresiduos").controller("HomeCtrl", [
	"$rootScope",
	"$scope",
	"$state",
	"$q",
	"accountService",
	"engagementScoreService",
	"userAccessService",
	"stockControlPreferenceService",
	"clientStoragePreferenceService",
	function (
		$rootScope,
		$scope,
		$state,
		$q,
		accountService,
		engagementScoreService,
		userAccessService,
		stockControlPreferenceService,
		clientStoragePreferenceService
	) {
		"use strict";

		const masterViewClientDataPermission = userAccessService.hasAccess(Vgr.systemPermissions.MasterViewClientData);
		const viewDisposalPermission = userAccessService.hasAccess(Vgr.systemPermissions.ViewDisposal);

		const viewStorageViewGenerated =
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesGeneratedFromAllAreas) ||
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesGeneratedFromUsersAreas);
		const viewStorageViewStored =
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesStoredFromAllAreas) ||
			userAccessService.hasAccess(Vgr.systemPermissions.ViewResiduesStoredFromUsersAreas);
		const viewStorageViewInternalTransfer = userAccessService.hasAccess(Vgr.systemPermissions.ViewInternalTransfer);

		const viewMarketPermission = userAccessService.hasAccess(Vgr.systemPermissions.ViewMarket);

		$scope.isLowerGenerationPlan = userAccessService.hasAccess(
			null,
			Vgr.constants.featureToggles.permission.lowerGenerationPlan,
			null
		);

		$scope.hasBiDashboardAccess = userAccessService.hasAccess(
			null,
			null,
			Vgr.constants.featureToggles.release.biModule
		);

		// Scope functions
		$scope.goToDisposal = function () {
			engagementScoreService.track(Vgr.trackings.disposal.followupAccess.usingShortcut);
			$state.go("disposalFollowup");
		};
		$scope.goToMarket = function () {
			$state.go("residueMarketNew");
		};
		$scope.goToInternalGathering = function () {
			engagementScoreService.track(Vgr.trackings.internal.gathering.usingShortcutNewPage);
			$state.go("storageList");
		};
		$scope.goToDashboard = function () {
			engagementScoreService.track(Vgr.trackings.dashboard.usingShortcut);
			$state.go("dashboard");
		};
		$scope.goToBiDashboard = function () {
			$state.go("bi");
		};
		$scope.goToMtrFollowupPage = function () {
			engagementScoreService.track(Vgr.trackings.disposal.manifest.followupAccessUsingHomeShortcut);
			$state.go("mtrFollowUp");
		};
		$scope.goToReports = function () {
			$state.go("customReport");
		};
		$scope.goToSuppliers = function () {
			$state.go("supplierList");
		};

		// RootScope variables
		$rootScope.contentNavBar = {};
		$rootScope.contentNavBar.title = $scope.labels.HOME;
		$rootScope.contentNavBar.removeButtonBar = true;

		this.$onInit = function () {
			accountService.goHome();
			$scope.loading = true;

			$rootScope.$broadcast(Vgr.constants.evtShowMenu);

			$scope.loggedUser = accountService.getLoggedAccount();
			$scope.organizationUnit = getClientNameForList(accountService.getCurrentClient());

			loadButtonPermissions().then(function () {
				$scope.loading = false;
			});
		};

		function canAccessStorageScreen(storagePreference) {
			if (
				storagePreference.internalTransferType === Vgr.enumerations.internalGathering.internalTransferType.FullTransfer
			) {
				return viewStorageViewInternalTransfer || viewStorageViewGenerated || viewStorageViewStored;
			} else if (
				storagePreference.internalTransferType ===
				Vgr.enumerations.internalGathering.internalTransferType.TwoStepTransfer
			) {
				return viewStorageViewGenerated || viewStorageViewStored;
			} else {
				return viewStorageViewStored;
			}
		}

		function getClientNameForList(client) {
			let description = "";

			if (masterViewClientDataPermission) {
				if (client.id == client.code) {
					description += client.id;
				} else {
					description += client.id + " - " + client.code;
				}
			} else {
				description += client.code;
			}

			description += " - " + client.name;

			return description;
		}

		function loadButtonPermissions() {
			const deferred = $q.defer();

			$scope.hasAccessToDisposalPage = viewDisposalPermission;
			$scope.hasAccessToMarketPage = viewMarketPermission;

			stockControlPreferenceService.getStockControlPreferences().then(
				function (preferences) {
					clientStoragePreferenceService.getStoragePreferences().then(function (storagePreference) {
						const viewStorageAnyPermission = canAccessStorageScreen(storagePreference);
						const viewGatheringPermission = viewStorageAnyPermission;

						$scope.hasAccessToResidueGenerationPage = preferences.enableControlStock && viewGatheringPermission;
						deferred.resolve();
					});
				},
				function () {
					$scope.hasAccessToResidueGenerationPage = true;
					deferred.resolve();
				}
			);

			return deferred.promise;
		}
	}
]);
