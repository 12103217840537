angular.module("vgresiduos").factory("supplierProfileService", [
	"$http",
	"$q",
	"accountService",
	"userAccessService",
	function ($http, $q, accountService, userAccessService) {
		"use strict";

		function getSupplierProfileBaseRequestUrl(supplierCode) {
			return (
				Vgr.constants.suppliersHostUrl +
				Vgr.resources.suppliers.supplierProfiles
					.replace("[ORGANIZATION]", accountService.getCurrentClient().organization.id)
					.replace("[SUPPLIER_CODE]", supplierCode)
			);
		}

		const _listSupplierProfiles = function () {
			const route = getSupplierProfileBaseRequestUrl();
			const req = {
				method: "get",
				url: route
			};

			return $http(req);
		};

		return {
			listSupplierProfiles: _listSupplierProfiles
		};
	}
]);
