angular.module("vgresiduos").directive("permissionGroupForm", function () {
	return {
		scope: true,
		controller: "PermissionGroupFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/permission/form/permissionGroupForm_template.html",
		bindToController: {
			model: "=",
			saveOptions: "="
		}
	};
});
