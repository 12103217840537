angular.module("vgresiduos").controller("monthDaySelectFieldCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		var labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.label = $scope.$ctrl.label;
			getList();
		};

		function getList() {
			$scope.list = [];
			var day = 1;
			while (day <= 31) {
				$scope.list.push({
					id: day,
					description: Vgr.util.capitalize(labels.DAY) + " " + day.toString().padStart(2, "0")
				});
				day++;
			}
		}
	}
]);
