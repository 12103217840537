angular.module("vgresiduos").directive("actionPlanDashboardStatus", function () {
	return {
		scope: true,
		controller: "ActionPlanDashboardStatusCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/actionPlan/dashboard/baseComponents/status/actionPlanDashboardStatus_template.html",
		bindToController: {
			actionPlan: "="
		}
	};
});
