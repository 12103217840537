angular.module("vgresiduos").controller("AreaMultiSelectV2Ctrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"areaService",
	function ($scope, $rootScope, httpService, areaService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listProducts();
		};

		function listProducts() {
			httpService.getListFromServiceCore(areaService.listOrganizationAreas).then(function (response) {
				let list = response.list;
				list.forEach((element) => {
					element.id = element.id.toString();
					element.displayText = element.name;
				});
				$scope.areas = list;
			});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
