angular.module("vgresiduos").directive("supplierDataForm", function () {
	return {
		scope: true,
		controller: "SupplierDataFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/supplierDataForm/supplierDataForm_template.html",
		bindToController: {
			inputSupplierData: "=",
			saveOptions: "="
		}
	};
});
