angular.module("vgresiduos").directive("fispqForm", function () {
	return {
		scope: true,
		controller: "FispqFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/Product/fispq/form/fispqForm_template.html",
		bindToController: {
			inputFispq: "=",
			saveOptions: "="
		}
	};
});
