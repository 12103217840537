angular.module("vgresiduos").directive("ingredientForm", function () {
	return {
		scope: true,
		controller: "IngredientFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/product/ingredients/ingredientsComponents/ingredientForm/ingredientForm_template.html",
		bindToController: {
			inputIngredient: "=",
			saveOptions: "="
		}
	};
});
