angular.module("vgresiduos").controller("ProductionFormCtrl", [
	"$scope",
	"$rootScope",
	"clientService",
	function ($scope, $rootScope, clientService) {
		"use strict";

		$rootScope.contentNavBarGrid = false;
		$scope.priceDecimals = clientService.getPriceDecimalPlaces();

		$scope.$watch("productionForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});

		$scope.isEditing = function () {
			return $scope.ctrl.inputProduction && $scope.ctrl.inputProduction.id && $scope.ctrl.inputProduction.id.length > 0;
		};
	}
]);
