angular.module("vgresiduos").controller("ResidueTagMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"clientService",
	function ($scope, $rootScope, httpService, clientService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			listResidueTag();
		};

		function listResidueTag() {
			httpService.getListFromService(clientService.getTagList).then(function (list) {
				list.forEach((element) => {
					element.id = element.ID.toString();
					element.displayText = element.Name;
				});
				$scope.residueTag = list;
			});
		}

		$scope.onChanged = function (selectedValues) {
			callback(selectedValues);
		};

		function callback(selectedValues) {
			if ($scope.$ctrl.callback) {
				$scope.$ctrl.callback(selectedValues);
			}
		}
	}
]);
