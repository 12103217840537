angular.module("vgresiduos").component("permissionGroupDescriptionSelectField", {
	controller: "PermissionGroupDescriptionSelectFieldCtrl",
	templateUrl:
		"views/components/fields/permissionGroupDescriptionSelectField/permissionGroupDescriptionSelectField_template.html",
	bindings: {
		isDisabled: "<",
		model: "=",
		isRequired: "<?"
	}
});
