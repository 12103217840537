angular.module("vgresiduos").controller("SupplierDataFormCtrl", [
	"$scope",
	function ($scope) {
		"use strict";

		this.$onInit = function () {
			if ($scope.ctrl.inputSupplierData.City) {
				$scope.ctrl.inputSupplierData.CityDescriptionToShow =
					$scope.ctrl.inputSupplierData.City.Description + " - " + $scope.ctrl.inputSupplierData.City.State.Description;
			} else {
				$scope.ctrl.inputSupplierData.CityDescriptionToShow = "";
			}
		};
	}
]);
