angular.module("vgresiduos").factory("clientService", [
	"$http",
	"$q",
	"localStorageService",
	"engagementScoreService",
	"accountService",
	function ($http, $q, localStorageService, engagementScoreService, accountService) {
		"use strict";

		const clientService = {};

		const _getClientById = function GetClient(clientId) {
			const req = {
				method: "post",
				data: clientId,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.client + "GetClient"
			};
			return $http(req);
		};

		const _generateCADRIBalance = function GenerateCADRIBalance(filter) {
			const req = {
				method: "post",
				data: filter,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.report + "GenerateCadriBalanceReport"
			};
			engagementScoreService.track(Vgr.trackings.bi.reports.generateCadriBalance);
			return $http(req);
		};

		const _generateMovimentationReport = function GenerateMovimentationReport(filter) {
			const req = {
				method: "post",
				data: filter,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.client + "GenerateMovimentationReport"
			};
			engagementScoreService.track(Vgr.trackings.bi.reports.generateResidueMovimentation);
			return $http(req);
		};

		const _generateMovimentationCorporateReport = function GenerateMovimentationCorporateReport(filter) {
			const req = {
				method: "post",
				data: filter,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.client + "GenerateMovimentationCorporateReport"
			};
			engagementScoreService.track(Vgr.trackings.bi.reports.generateResidueMovimentation);
			return $http(req);
		};

		const _getTagList = function GetTagList() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.client + "GetTagList"
			};
			return $http(req);
		};

		const _isSupplier = function () {
			return accountService.isSupplier();
		};

		clientService.getClientById = _getClientById;
		clientService.isSupplier = _isSupplier;
		clientService.getTagList = _getTagList;
		clientService.generateMovimentationReport = _generateMovimentationReport;
		clientService.generateCADRIBalance = _generateCADRIBalance;
		clientService.generateMovimentationCorporateReport = _generateMovimentationCorporateReport;

		//new apis

		const _listClientsInternal = function (params) {
			let parameters = "";
			if (params) {
				parameters = $.param(params);
			}

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "clients-internal/?" + parameters
			};

			return $http(req);
		};

		const _createClient = function UpdateClient(dto) {
			const req = {
				method: "post",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clients
			};
			return $http(req);
		};

		const _updateClient = function UpdateClient(model) {
			const req = {
				method: "put",
				data: model.dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clients + model.id
			};
			return $http(req);
		};

		const _getLogo = function GetLogo(clientId) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clients + clientId + "/logo/"
			};

			return $http(req);
		};

		const _updateLogo = function UpdateLogo(dto) {
			const clientId = dto.clientId;
			const files = dto.files;

			const formData = new FormData();
			formData.append("clientId", clientId);
			Vgr.util.addFilesToForm(formData, files);
			const req = {
				method: "post",
				headers: { "Content-Type": undefined },
				data: formData,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clients + clientId + "/logo/"
			};
			return $http(req);
		};

		const _deleteLogo = function UpdateLogo(clientId) {
			const req = {
				method: "delete",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clients + clientId + "/logo/"
			};

			return $http(req);
		};

		const _listEmergencySheetPhones = function () {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "emergency-sheet-phones"
			};
			return $http(req);
		};

		const _updateEmergencySheetPhones = function (dto) {
			const req = {
				method: "put",
				data: dto,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "emergency-sheet-phones"
			};
			return $http(req);
		};

		function _getDecimalPlaces() {
			const client = localStorageService.get(Vgr.constants.authentication.client);

			if (client) {
				if (client.decimalPlaces) {
					return client.decimalPlaces;
				}
			} else {
				return 4;
			}
		}

		function _getPriceDecimalPlaces() {
			const client = localStorageService.get(Vgr.constants.authentication.client);

			if (client) {
				if (client.priceDecimalPlaces) {
					return client.priceDecimalPlaces;
				}
			} else {
				return 2;
			}
		}

		function _getSendResidueCodeOnMtrIntegration() {
			const client = localStorageService.get(Vgr.constants.authentication.client);
			return !!(client && client.sendResidueCodeMtrIntegration);
		}

		clientService.listClientsInternal = _listClientsInternal;
		clientService.createClient = _createClient;
		clientService.updateClient = _updateClient;

		clientService.getLogo = _getLogo;
		clientService.updateLogo = _updateLogo;
		clientService.deleteLogo = _deleteLogo;

		clientService.listEmergencySheetPhones = _listEmergencySheetPhones;
		clientService.updateEmergencySheetPhones = _updateEmergencySheetPhones;

		clientService.getDecimalPlaces = _getDecimalPlaces;
		clientService.getPriceDecimalPlaces = _getPriceDecimalPlaces;
		clientService.getSendResidueCodeOnMtrIntegration = _getSendResidueCodeOnMtrIntegration;

		//core

		const _listOrganizationUnitsToLogin = function (params) {
			let parameters = "";
			if (params) {
				parameters = "?" + $.param(params);
			}

			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + "v1/organization-units-login" + parameters
			};

			return $http(req);
		};

		const _listClientsCore = function (params) {
			const organizationId = accountService.getCurrentClient().organization.id;
			const parameters = Vgr.util.serializeObjectToQueryString(params);

			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.organizationUnits.replace("[ORGANIZATION]", organizationId) +
					parameters
			};

			return $http(req);
		};

		const _listMasterClients = function (data) {
			const req = {
				method: "post",
				data: data,
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.masterOrganizationUnits
			};

			return $http(req);
		};

		clientService.listOrganizationUnitsToLogin = _listOrganizationUnitsToLogin;
		clientService.listClientsCore = _listClientsCore;
		clientService.listMasterClients = _listMasterClients;

		return clientService;
	}
]);
