angular.module("vgresiduos").controller("SupplierListCtrl", [
	"$rootScope",
	"$stateParams",
	function ($rootScope, $stateParams) {
		"use strict";

		this.$onInit = function () {
			Vgr.util.redirectSingleton($rootScope, `organizacao/fornecedores`, getQueries());
		};

		function getQueries() {
			const queries = [];
			if ($stateParams.pendencia && $stateParams.unidade) {
				queries.push({ key: "filterPendencyId", value: $stateParams.pendencia });
				queries.push({ key: "filterOrganizationUnitId", value: $stateParams.unidade });
			}
			if ($stateParams.vincular) {
				queries.push({ key: "linkUnitsToSupplier", value: true });
			}
			return queries;
		}
	}
]);
