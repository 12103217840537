angular.module("vgresiduos").controller("IbamaRappGridCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"httpService",
	"ibamaIntegrationService",
	function ($scope, $rootScope, $state, httpService, ibamaIntegrationService) {
		"use strict";

		var labels = $rootScope.labels;

		var columns = [
			{
				subfield: "NameFormated",
				displayName: function () {
					return labels.CLIENT_UNITS;
				},
				sublist: "OrganizationUnits"
			},
			{
				field: "CnpjFormated",
				displayName: function () {
					return labels.CNPJ;
				}
			},
			{
				field: "StatusFormated",
				displayName: function () {
					return labels.INTEGRATION_STATUS;
				},
				classes: "bold uppercase",
				getCustomClass: function (row) {
					return row.StatusClass;
				}
			}
		];

		$scope.gridOptions = {
			columnDefinitions: columns,
			filterFields: ["cnpj"],
			data: [],
			query: {
				order: "cnpj",
				limit: 10,
				page: 1
			},
			rowClick: function (row) {
				$state.go("ibamaRappFollowup", { id: row.Id });
			},
			create: function () {
				//Nothing to be done
			},
			promise: {},
			hasData: false,
			removeNew: true,
			removeDelete: true,
			errorLoading: false
		};

		this.$onInit = function () {
			listIbamaRapp();
		};

		function listIbamaRapp() {
			var dto = {
				year: $scope.ctrl.year
			};

			httpService.getDTOFromServiceV2(ibamaIntegrationService.listIbamaRapp, dto).then(
				function (list) {
					formatFields(list);
					$scope.gridOptions.data = list;
					$scope.gridOptions.hasData = true;
				},
				function () {
					$scope.gridOptions.hasData = true;
				}
			);
		}

		function formatFields(list) {
			list.forEach((ibamaRapp) => {
				formatStatus(ibamaRapp);
				formatUnits(ibamaRapp);
				formatCnpj(ibamaRapp);
			});
		}

		function formatCnpj(ibamaRapp) {
			ibamaRapp.CnpjFormated = Vgr.util.formatCNPJ(ibamaRapp.Cnpj);
		}

		function formatUnits(ibamaRapp) {
			ibamaRapp.OrganizationUnits.forEach((unit) => {
				unit.NameFormated = unit.Code + " - " + unit.Name;
			});
		}

		function formatStatus(ibamaRapp) {
			ibamaRapp.StatusFormated = $scope.labels.IBAMA_RAPP_STATUS_USER[ibamaRapp.Status];
			switch (ibamaRapp.Status) {
				case Vgr.enumerations.ibamaRapp.status.Pending:
					ibamaRapp.StatusClass = "c-orange";
					break;
				case Vgr.enumerations.ibamaRapp.status.ConfirmPending:
					ibamaRapp.StatusClass = "c-orange";
					break;
				case Vgr.enumerations.ibamaRapp.status.Success:
					ibamaRapp.StatusClass = "strong-green";
					break;
				case Vgr.enumerations.ibamaRapp.status.Error:
					ibamaRapp.StatusClass = "strong-red";
					break;
			}
		}
	}
]);
