angular.module("vgresiduos").controller("ReplicateForClientsOneStepPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"replicateType",
	"supplierId",
	"requestModel",
	function ($scope, $rootScope, dialogService, replicateType, supplierId, requestModel) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.replicateType = replicateType;
		$scope.supplierId = supplierId;
		$scope.requestModel = requestModel;

		const cancelButton = {
			label: $scope.labels.CLOSE,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};

		const confirmButton = {
			label: $scope.labels.REPLICATE,
			class: "md-primary md-raised",
			click: function () {
				this.disabled = true;
				$rootScope.$broadcast(Vgr.constants.evtConfimReplicateForClients);
			},
			disabled: false
		};

		$scope.onReplicateFinish = function () {
			confirmButton.disabled = false;
		};

		$scope.onLoadClientsFinish = function (hasClientsToReplicate) {
			if (hasClientsToReplicate) {
				$scope.popupActionButtons.push(confirmButton);
			}
		};

		$scope.popupActionButtons = [cancelButton];
	}
]);
