angular.module("vgresiduos").directive("clientPropertyGrid", function () {
	return {
		scope: true,
		controller: "ClientPropertyGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/clientProperty/components/grid/clientPropertyGrid_template.html",
		bindToController: {
			processType: "=?",
			selectedProperties: "=",
			setterRowsIds: "=?"
		}
	};
});
