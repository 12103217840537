angular.module("vgresiduos").controller("userGridCtrl", [
	"$scope",
	"$state",
	"userService",
	"$rootScope",
	"httpService",
	"permissionService",
	"dialogService",
	"accountService",
	function ($scope, $state, userService, $rootScope, httpService, permissionService, dialogService, accountService) {
		"use strict";

		const createPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.CreateEditUser);

		const organizationHasSso = accountService.organizationHasSso();

		this.$onInit = function () {
			$scope.editOptions = [
				{
					icon: "lock",
					label: $rootScope.labels.PERMISSIONS,
					disable: function () {
						return false;
					},
					click: function (row) {
						$state.go("userPermissions", { id: row.code });
					}
				}
			];

			if (createPermission) {
				const deleteUserOption = {
					type: "delete",
					icon: "delete",
					label: $rootScope.labels.DELETE,
					disable: function () {
						return false;
					}
				};
				$scope.editOptions.push(deleteUserOption);
			}

			$scope.columns = [
				{
					field: "name",
					displayName: function () {
						return $rootScope.labels.userForm.NAME;
					},
					width: 250
				},
				{
					field: "email",
					displayName: function () {
						return $rootScope.labels.userForm.EMAIL;
					},
					width: 160
				}
			];

			if (organizationHasSso) {
				$scope.columns.push({
					field: "useSso",
					displayName: function () {
						return $rootScope.labels.userForm.USE_SSO;
					}
				});

				$scope.columns.push({
					field: "ssoId",
					displayName: function () {
						return $rootScope.labels.SSO_ID;
					}
				});
			}

			$scope.columns.push(
				{
					field: "registrationCode",
					displayName: function () {
						return $rootScope.labels.userForm.REGISTRATION_CODE;
					}
				},
				{
					field: "lastLogin",
					displayName: function () {
						return $rootScope.labels.userForm.LAST_ACCESS;
					},
					width: 160
				},
				{
					editOptions: getRowEditOptions(),
					width: 60,
					displayName: function () {
						return $rootScope.labels.ACTIONS;
					}
				}
			);

			$scope.reportFilter = {};

			const filterFields = ["name", "email", "ssoId", "registrationCode", "lastLogin"];

			if (!$scope.ctrl.gridOptions) {
				$scope.ctrl.gridOptions = {
					hasPermission: function (actionType) {
						if (
							actionType == Vgr.enumerations.systemPermissions.permissionActionTypes.Create ||
							actionType == Vgr.enumerations.systemPermissions.permissionActionTypes.Delete
						) {
							return createPermission;
						} else {
							return false;
						}
					},
					title: $rootScope.labels.USERS,
					columnDefinitions: $scope.columns,
					listV3: function () {
						return httpService.getDTOFromServiceV2(userService.listUsersV2);
					},
					deletePopup: {
						title: $rootScope.labels.REMOVE_USER_ORGANIZATION_POPUP_TITLE,
						text: $rootScope.labels.REMOVE_USER_ORGANIZATION_POPUP_TEXT,
						deleteMethod: userService.removeUserFromOrganization,
						descriptionField: "name",
						onClose: list,
						idField: "code"
					},
					promise: {},
					removeNew: true
				};

				const buttons = [];
				if (createPermission) {
					buttons.push($rootScope.labels.INVITE);
				}

				buttons.push($rootScope.labels.EXPORT);

				$rootScope.contentNavBar = {
					customButtons: [
						{
							hasPermission: function () {
								return true;
							},
							label: $rootScope.labels.EXPORT,
							icon: "ios_share",
							buttonClasses: "md-secondary",
							onClick: function () {
								return exportToCsv();
							}
						},
						{
							hasPermission: function () {
								return createPermission;
							},
							label: $rootScope.labels.INVITE,
							icon: "add",
							buttonClasses: "md-primary",
							supressToast: true,
							onClick: function () {
								return dialogService
									.showDialogFromTemplateV2(
										"views/components/userGrid/popup/userInvitePopup_template.html",
										"UserInvitePopupCtrl",
										null
									)
									.then(
										function () {
											list();
										},
										function (hasInvited) {
											if (hasInvited) {
												list();
											}
										}
									);
							}
						}
					],
					validate: false,
					valid: true,
					buttons: buttons
				};
			}
			Vgr.util.merge(
				$scope.ctrl.gridOptions,
				{
					filterFields: filterFields,
					query: {
						order: "name",
						limit: 10,
						page: 1
					},
					showDeleteOnSelectedItens: true,
					data: [],
					hasData: false,
					errorLoading: false
				},
				true
			);

			if ($scope.ctrl.gridOptions.internalTabBack) {
				$rootScope.contentNavBar.back = $scope.ctrl.gridOptions.internalTabBack;
			}

			list();

			$rootScope.contentNavBarGrid = $scope.ctrl.gridOptions;
		};

		function getRowEditOptions() {
			return $scope.editOptions;
		}

		function list() {
			$scope.ctrl.gridOptions.data = [];
			$scope.ctrl.gridOptions.hasData = false;
			if ($scope.ctrl.gridOptions.listCore) {
				$scope.ctrl.gridOptions.promise = $scope.ctrl.gridOptions.listCore().then(
					function (usersList) {
						$scope.ctrl.gridOptions.data = usersList.list;
						$scope.ctrl.gridOptions.hasData = true;
					},
					function () {
						$scope.ctrl.gridOptions.errorLoading = true;
					}
				);
			} else if ($scope.ctrl.gridOptions.listV3) {
				$scope.ctrl.gridOptions.promise = $scope.ctrl.gridOptions.listV3().then(
					function (usersList) {
						for (const user of usersList) {
							fillUser(user);
						}
						$scope.ctrl.gridOptions.data = usersList;
						$scope.ctrl.gridOptions.hasData = true;
					},
					function () {
						$scope.ctrl.gridOptions.errorLoading = true;
					}
				);
			}
			return $scope.ctrl.gridOptions.promise;
		}

		function fillUser(user) {
			if (user.lastLogin) {
				user.lastLogin = Vgr.util.dateToString(new Date(user.lastLogin));
			}
			if (user.ssoId) {
				user.useSso = $rootScope.labels.YES;
			} else {
				user.useSso = $rootScope.labels.NO;
			}
		}

		function exportToCsv() {
			const promise = httpService.postDTOToServiceV2(userService.generateUsersReport);
			promise.then(function (data) {
				const fileDTO = Vgr.util.fileDtoToFile(Vgr.util.capitalizeObjectKeys(data));
				saveAs(fileDTO, fileDTO.FileName);
			});
			return promise;
		}
	}
]);
