angular.module("vgresiduos").directive("collapsibleDiv", function () {
	return {
		scope: true,
		controller: "CollapsibleDivCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/collapsibleDiv/collapsibleDiv_template.html",
		transclude: true,
		bindToController: {
			name: "@"
		}
	};
});
