angular.module("vgresiduos").controller("GoalsListGridCtrl", [
	"$scope",
	"$rootScope",
	"$state",
	"httpService",
	"residueV2Service",
	"goalService",
	function ($scope, $rootScope, $state, httpService, residueV2Service, goalService) {
		"use strict";

		var labels = $rootScope.labels;

		var residueGoalList = [];

		$scope.goalStatus = {
			Partial: "Partial",
			Full: "Full",
			None: "None"
		};

		var columns = [
			{
				field: "Code",
				displayName: function () {
					return labels.ID;
				}
			},
			{
				field: "Name",
				displayName: function () {
					return labels.DESCRIPTION_RESIDUE;
				},
				width: 450
			},
			{
				field: "ResidueGenerationStatusText",
				displayName: function () {
					return labels.RESIDUE_GENERATION;
				},
				getCustomClass: function (row) {
					return row.ResidueGenerationStatusClass;
				},
				iconClass: "align-icon",
				classes: "m-l-30",
				orderField: "ResidueGenerationStatusText",
				icon: {
					iconImageClass: "icon-resize",
					property: "ResidueGenerationStatusImg",
					condition: function (row) {
						if (row.ResidueGenerationStatus) {
							return true;
						}
						return false;
					}
				}
			},
			{
				field: "FinancialDisposalStatusText",
				displayName: function () {
					return labels.RESIDUE_DISPOSAL_COSTS_CHART_TITLE;
				},
				getCustomClass: function (row) {
					return row.FinancialDisposalStatusClass;
				},
				iconClass: "align-icon",
				classes: "m-l-30",
				orderField: "FinancialDisposalStatusText",
				icon: {
					iconImageClass: "icon-resize",
					property: "FinancialDisposalStatusImg",
					condition: function (row) {
						if (row.FinancialDisposalStatus) {
							return true;
						}
						return false;
					}
				}
			},
			{
				menuButton: "menuButton",
				displayName: function () {
					return labels.ACTIONS;
				}
			}
		];

		$scope.gridOptions = {
			columnDefinitions: columns,
			filterFields: ["Name"],
			data: [],
			query: {
				order: "Name",
				page: 1
			},
			promise: {},
			hasData: false,
			removeNew: true,
			removeDelete: true,
			errorLoading: false
		};

		function listResidue(year) {
			// to do ryan: filtro de ano ?
			var dto = {
				year: year
			};

			residueGoalList = [];

			$scope.gridOptions.hasData = false;

			httpService.getDTOFromServiceV2(residueV2Service.listOrganizationUnitResiduesOld).then(
				function (list) {
					httpService.getDTOFromServiceV2(goalService.listGoals, dto).then(
						function (goalList) {
							formatFields(list, goalList);
							$scope.gridOptions.data = residueGoalList;
							$scope.gridOptions.hasData = true;
						},
						function () {
							$scope.gridOptions.data = [];
							$scope.gridOptions.hasData = true;
						}
					);
				},
				function () {
					$scope.gridOptions.data = [];
					$scope.gridOptions.hasData = true;
				}
			);
		}

		function formatFields(residueList, goalList) {
			residueList.map((el) => {
				var residueGoal = {};

				residueGoal.Id = el.id;
				residueGoal.Code = el.code;

				residueGoal.Name = el.name;

				var goalResidueGeneration = goalList.find(function (goal) {
					return goal.residueId == el.id && goal.type == Vgr.enumerations.goal.type.ResidueGeneration;
				});

				if (!goalResidueGeneration) {
					residueGoal.ResidueGenerationStatus = $scope.goalStatus.None;
					residueGoal.ResidueGenerationStatusText = labels.GOAL_STATUS_ENUM.NONE;
				} else {
					residueGoal.ResidueGenerationStatus = goalResidueGeneration.status;
					residueGoal.ResidueGenerationStatusText = labels.GOAL_STATUS_ENUM[goalResidueGeneration.status.toUpperCase()];
				}

				var goalFinancialDisposal = goalList.find(function (goal) {
					return goal.residueId == el.id && goal.type == Vgr.enumerations.goal.type.DisposalFinancial;
				});

				if (!goalFinancialDisposal) {
					residueGoal.FinancialDisposalStatus = $scope.goalStatus.None;
					residueGoal.FinancialDisposalStatusText = labels.GOAL_STATUS_ENUM.NONE;
				} else {
					residueGoal.FinancialDisposalStatus = goalFinancialDisposal.status;
					residueGoal.FinancialDisposalStatusText = labels.GOAL_STATUS_ENUM[goalFinancialDisposal.status.toUpperCase()];
				}

				residueGoal.FinancialDisposalStatusClass = formatStatus(residueGoal.FinancialDisposalStatus);
				residueGoal.ResidueGenerationStatusClass = formatStatus(residueGoal.ResidueGenerationStatus);

				loadClientFinancialDisposalStatus(residueGoal);

				$scope.CreateSettingsButton(residueGoal);

				residueGoalList.push(residueGoal);
			});
		}

		$scope.CreateSettingsButton = function (residueGoal) {
			var actionTitle = $rootScope.labels.EDIT_GOAL;
			var usedIcon = "edit";

			residueGoal.menuButton = {
				icon: "more_vert",
				label: "",
				forceDropdown: true
			};
			residueGoal.menuButton.menuItems = [];

			if (
				residueGoal.ResidueGenerationStatus == $scope.goalStatus.None &&
				residueGoal.FinancialDisposalStatus == $scope.goalStatus.None
			) {
				actionTitle = $rootScope.labels.REGISTER_GOAL;
				usedIcon = "add_circle";
			}

			// edit goal
			residueGoal.menuButton.menuItems.push({
				usedIcon: usedIcon,
				title: actionTitle,
				method: function () {
					$state.go("goalsEdit", { year: $scope.$ctrl.setterYear, residueCode: residueGoal.Code });
				}
			});
		};

		function formatStatus(goalStatus) {
			switch (goalStatus) {
				case $scope.goalStatus.None:
					return "color-dark-grey font-bold";
				case $scope.goalStatus.Partial:
					return "c-orange font-bold";
				case $scope.goalStatus.Full:
					return "strong-green font-bold";
			}
		}

		function loadClientFinancialDisposalStatus(goal) {
			switch (goal.FinancialDisposalStatus) {
				case $scope.goalStatus.None:
					goal.FinancialDisposalStatusDescription = $rootScope.labels.GOAL_STATUS_ENUM.NONE;
					goal.FinancialDisposalStatusImg = "img/icons/none-goal-status.png";
					break;
				case $scope.goalStatus.Partial:
					goal.FinancialDisposalStatusDescription = $rootScope.labels.GOAL_STATUS_ENUM.PARTIAL;
					goal.FinancialDisposalStatusImg = "img/icons/partial-goal-status.png";
					break;
				case $scope.goalStatus.Full:
					goal.FinancialDisposalStatusDescription = $rootScope.labels.GOAL_STATUS_ENUM.FULL;
					goal.FinancialDisposalStatusImg = "img/icons/full-goal-status.png";
					break;
			}

			switch (goal.ResidueGenerationStatus) {
				case $scope.goalStatus.None:
					goal.ResidueGenerationStatusDescription = $rootScope.labels.GOAL_STATUS_ENUM.NONE;
					goal.ResidueGenerationStatusImg = "img/icons/none-goal-status.png";
					break;
				case $scope.goalStatus.Partial:
					goal.ResidueGenerationStatusDescription = $rootScope.labels.GOAL_STATUS_ENUM.PARTIAL;
					goal.ResidueGenerationStatusImg = "img/icons/partial-goal-status.png";
					break;
				case $scope.goalStatus.Full:
					goal.ResidueGenerationStatusDescription = $rootScope.labels.GOAL_STATUS_ENUM.FULL;
					goal.ResidueGenerationStatusImg = "img/icons/full-goal-status.png";
					break;
			}
			return goal;
		}

		this.$onChanges = function (obj) {
			if (obj.setterYear && obj.setterYear.currentValue != obj.setterYear.previousValue) {
				listResidue(obj.setterYear.currentValue);
			}
		};
	}
]);
