angular.module("vgresiduos").directive("additionalPropertiesForm", function () {
	return {
		scope: true,
		controller: "AdditionalPropertiesFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/additionalProperties/form/additionalPropertiesForm_template.html",
		bindToController: {
			model: "=",
			residuesSwitch: "=?",
			organizationUnitRadioSelectedId: "=?",
			organizationUnitSelectedListCount: "=?"
		}
	};
});
