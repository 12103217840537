angular.module("vgresiduos").controller("UserInvitePopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"accountService",
	"userService",
	"directiveService",
	function ($scope, $rootScope, dialogService, accountService, userService, directiveService) {
		"use strict";

		$scope.labels = $rootScope.labels;
		$scope.popupTitle = $scope.labels.INVITE_USERS;
		$scope.invitationModes = Vgr.enumerations.userInviteMode;
		const client = accountService.getCurrentClient();
		const organizationHasSso = accountService.organizationHasSso();
		const emailDifferentFromUsername = client.organization.emailDifferentFromUsername;
		const emailInviteDisabled = client.organization.emailInviteDisabled;

		$scope.emailDifferentFromUsername = emailDifferentFromUsername;

		$scope.model = {
			emails: [],
			client: client,
			invitationMode: "",
			unit: client.name + " - " + client.city.description
		};
		$scope.currentStepIndex = 0;

		const stepSelection = {
			title: $scope.labels.SELECTION,
			isSelection: true
		};

		const stepInfo = {
			title: $scope.labels.INFO,
			isInfo: true
		};

		const stepOperation = {
			title: $scope.labels.OPERATION_STATUS,
			isOperation: true,
			isDisabled: true
		};

		$scope.steps = [stepInfo, stepOperation];
		$scope.hasAnySucceeded = false;

		$scope.onChangeStep = function (stepIndex) {
			setCurrentStep(stepIndex);
			if (stepIndex == 0) {
				clear();
			}
			setPopupActionButtons();
		};

		$scope.onFinish = function (hasError, hasAnySucceeded) {
			if (!hasError) {
				dialogService.confirm();
			}

			$scope.hasAnySucceeded = hasAnySucceeded;
			$scope.popupActionButtons[0].disabled = false;
			$scope.runDelete = false;
		};

		$scope.onSelectInvitationMode = function () {
			setStep(1);
		};

		$scope.getDto = function (item) {
			const permissions = [];
			if ($scope.model.permissionGroup) {
				permissions.push({
					organizationUnitCode: $scope.model.permissionGroup.organizationUnit.code,
					permissionGroupId: $scope.model.permissionGroup.id
				});
			}

			return {
				email: item.email,
				ssoId: item.ssoId,
				username: item.username,
				permissions: permissions
			};
		};

		function buildInvitationList() {
			return $scope.model.emails.map((email) => {
				return { email: email[Object.keys(email)[0]] };
			});
		}

		function buildSsoInvitationList() {
			const invitationList = $scope.model.usersToInvite.map((userToInvite) => {
				return {
					email: userToInvite.fields[0].value,
					ssoId: userToInvite.fields[1].value ? userToInvite.fields[1].value : userToInvite.fields[0].value
				};
			});
			if (isValidSsoInvitationList(invitationList)) {
				return invitationList;
			}
			return null;
		}

		function buildUsernameInvitationList() {
			const invitationList = $scope.model.usersToInvite.map((userToInvite) => {
				return {
					email: userToInvite.fields[0].value,
					username: userToInvite.fields[1].value ? userToInvite.fields[1].value : userToInvite.fields[0].value
				};
			});
			if (isValidUsernameInvitationList(invitationList)) {
				return invitationList;
			}
			return null;
		}

		function isValidSsoInvitationList(list) {
			for (const user of list) {
				if (!user.email || !user.ssoId) {
					return false;
				}
			}
			return true;
		}

		function isValidUsernameInvitationList(list) {
			for (const user of list) {
				if (!user.email || !user.username) {
					return false;
				}
			}
			return true;
		}

		function clear() {
			$scope.invitationList = [];
			stepOperation.isDisabled = true;
			$scope.runInvite = false;
			$scope.popupActionButtons.forEach((button) => (button.disabled = false));
		}

		function setCurrentStep(index) {
			$scope.currentStepIndex = index;
			$scope.goToStepIndex = index;
		}

		function setStep(index) {
			$scope.steps[index].isDisabled = false;
			$scope.currentStepIndex = index;
			$scope.goToStepIndex = index;
			setPopupActionButtons();
		}

		function invite() {
			if ($scope.model.invitationMode == Vgr.enumerations.userInviteMode.sso) {
				inviteBySso();
			} else if ($scope.model.invitationMode == Vgr.enumerations.userInviteMode.username) {
				inviteByUsername();
			} else {
				inviteByEmail();
			}
		}

		function setPopupActionButtons() {
			if ($scope.steps[$scope.currentStepIndex].isInfo) {
				$scope.popupActionButtons = [
					{
						label: $scope.labels.CANCEL,
						class: "md-primary",
						click: function () {
							dialogService.cancel($scope.hasAnySucceeded);
						}
					},
					{
						label: $scope.labels.INVITE,
						class: "md-primary md-raised",
						click: invite
					}
				];
			} else {
				$scope.popupActionButtons = [
					{
						label: $scope.labels.CANCEL,
						class: "md-primary",
						click: function () {
							dialogService.cancel($scope.hasAnySucceeded);
						}
					},
					{
						label: $scope.labels.CONTINUE,
						class: "md-primary md-raised",
						click: function () {
							if ($scope.model.invitationMode) {
								setStep(1);
							} else {
								$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SELECT_ONE_INVITATION_MODE);
							}
						}
					}
				];
			}
		}

		function inviteBySso() {
			$scope.invitationList = buildSsoInvitationList();
			if (!$scope.invitationList) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.VALIDATION_ERROR_MESSAGE);
				return;
			} else if ($scope.invitationList.length == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels["ERR-USR-0006"]);
				return;
			}

			setStep($scope.steps.length - 1);
			$scope.popupActionButtons.forEach((button) => (button.disabled = true));
			$scope.runInvite = true;
		}

		function inviteByUsername() {
			$scope.invitationList = buildUsernameInvitationList();
			if ($scope.invitationList.length == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels["ERR-USR-0006"]);
				return;
			}

			setStep($scope.steps.length - 1);
			$scope.popupActionButtons.forEach((button) => (button.disabled = true));
			$scope.runInvite = true;
		}

		function inviteByEmail() {
			$scope.invitationList = buildInvitationList();
			if ($scope.invitationList.length == 0) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels["ERR-USR-0006"]);
				return;
			}

			setStep($scope.steps.length - 1);
			$scope.popupActionButtons.forEach((button) => (button.disabled = true));
			$scope.runInvite = true;
		}

		function initializePopup() {
			$scope.inviteMethod = userService.inviteUsers;

			if (!emailInviteDisabled && organizationHasSso) {
				$scope.steps[0].isDisabled = true;
				$scope.steps.unshift(stepSelection);
			} else {
				if (emailDifferentFromUsername) {
					$scope.model.invitationMode = Vgr.enumerations.userInviteMode.username;
				} else if (organizationHasSso) {
					$scope.model.invitationMode = Vgr.enumerations.userInviteMode.sso;
				} else {
					$scope.model.invitationMode = Vgr.enumerations.userInviteMode.email;
				}
			}

			setStep(0);
		}

		initializePopup();
	}
]);
