angular.module("vgresiduos").controller("AreaMultiSelectCtrl", [
	"$scope",
	"$rootScope",
	"httpService",
	"areaService",
	function ($scope, $rootScope, httpService, areaService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			initValues();
			loadAreas();
		};

		function initValues() {
			$scope.searchLabel = $scope.labels.FILTER_BY_AREA;
			$scope.label = $scope.labels.AREA;
			$scope.showSelectAll = angular.isDefined($scope.$ctrl.showSelectAll) ? $scope.$ctrl.showSelectAll : true;
			if ($scope.$ctrl.isRequired) {
				$scope.label += "*";
			}
		}

		function loadAreas() {
			const requestDto = {
				simpleDto: true,
				onlyActive: true
			};

			httpService.getListFromServiceCore(areaService.listAreas, requestDto).then(function (response) {
				$scope.list = response.list;
			});
		}

		$scope.onSelectedValuesChanged = function (values) {
			if ($scope.$ctrl.onSelectedValuesChanged) {
				$scope.$ctrl.onSelectedValuesChanged(values);
			}
		};
	}
]);
