angular.module("vgresiduos").controller("ClientPropertyGridCtrl", [
	"$scope",
	"$rootScope",
	"additionalPropertyService",
	"httpService",
	function ($scope, $rootScope, additionalPropertyService, httpService) {
		"use strict";
		$scope.clientPropertyInfoGrid = {};

		$scope.clientPropertyInfoGrid.columns = [
			{
				description: $rootScope.labels.PROPERTIES,
				property: "name"
			}
		];

		$scope.clientPropertyInfoGrid.defaultActions = [];

		$scope.clientPropertyInfoGrid.list = [];

		function loadClientProperties() {
			var dto = {};

			if ($scope.ctrl.processType) {
				dto.process = $scope.ctrl.processType;
			}

			httpService
				.getListFromServiceCore(additionalPropertyService.listClientProperties, dto, "Properties")
				.then(function (additionalProperties) {
					$scope.clientPropertyInfoGrid.list = additionalProperties.list;
				});
		}

		this.$onInit = function () {
			loadClientProperties();
		};
	}
]);
