angular.module("vgresiduos").directive("residueCodeTextField", function () {
	return {
		scope: true,
		controller: "ResidueCodeTextFieldCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/residue/components/fields/residueCodeTextField_template.html",
		bindToController: {
			model: "=",
			isRequired: "=",
			label: "@?",
			canEdit: "@?"
		}
	};
});
