angular.module("vgresiduos").directive("customizedInputForm", function () {
	return {
		scope: true,
		controller: "CustomizedInputFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/components/customizedInputForm/customizedInputForm_template.html",
		bindToController: {
			availableFields: "<",
			rows: "<"
		}
	};
});
