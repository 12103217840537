angular.module("vgresiduos").controller("auditClassificationSelectFieldCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";

		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			$scope.situations = [
				{
					value: Vgr.enumerations.audit.Classification.None,
					description: $scope.ctrl.emptyFieldDescription
				},
				{
					value: Vgr.enumerations.audit.Classification.Approved,
					description: $scope.labels.APPROVED
				},
				{
					value: Vgr.enumerations.audit.Classification.ApprovedWithRestrictions,
					description: $scope.labels.APPROVED_WITH_RESTRICTIONS
				},
				{
					value: Vgr.enumerations.audit.Classification.Disapproved,
					description: $scope.labels.DISAPPROVED
				}
			];

			if (!$scope.ctrl.situation) {
				$scope.ctrl.situation = $scope.situations[0].value;
			}
		};

		$scope.$watch("ctrl.situation", function (newVal, oldVal) {
			if (newVal === null) {
				$scope.ctrl.situation = $scope.situations[0].value;
			}
		});
	}
]);
