angular.module("vgresiduos").controller("IbamaIntegrationCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"httpService",
	"ibamaExternalClientService",
	"dialogService",
	"permissionService",
	"$stateParams",
	function (
		$scope,
		$rootScope,
		$q,
		httpService,
		ibamaExternalClientService,
		dialogService,
		permissionService,
		$stateParams
	) {
		"use strict";

		var editPermission = permissionService.hasPermissionV2(Vgr.systemPermissions.EditOrganization);

		$scope.labels = $rootScope.labels;

		$scope.ibamaIntegrationPreferenceGrid = {};

		$scope.ibamaIntegrationPreferenceGrid.columns = [
			{
				description: $scope.labels.CNPJ,
				property: "CnpjFormated"
			},
			{
				description: $scope.labels.userForm.PASSWORD,
				property: "showedPassword"
			}
		];

		$scope.ibamaIntegrationPreferenceGrid.defaultActions = [];

		$scope.ibamaIntegrationPreferenceGrid.actionButtons = [];

		$scope.ibamaIntegrationPreferenceGrid.list = [];
		$scope.ibamaIntegrationPreferenceGrid.btnAddClick = function () {
			openPreferencesPopup();
		};

		function deleteClientExternalSystem(row) {
			const labelsPopup = {
				title: $scope.labels.DELETE_IBAMA_EXTERNAL_SYSTEM_POPUP.TITLE,
				description: $scope.labels.DELETE_IBAMA_EXTERNAL_SYSTEM_POPUP.DESCRIPTION + " " + row.CnpjFormated + "?"
			};
			const params = {
				popupTitle: labelsPopup.title,
				popupText: labelsPopup.description,
				cnpj: row.cnpj
			};
			dialogService
				.showDialogFromTemplateV2(
					"views/organization/form/organizationIbamaPopups/ibamaIntegrationPopupDelete/ibamaIntegrationPopupDelete_template.html",
					"IbamaIntegrationPopupDeleteCtrl",
					"#middle-popup",
					params
				)
				.then(function () {
					list();
				});
		}

		function openPreferencesPopup(ibamaIntegrationPreferenceSelected) {
			var params = {
				ibamaIntegrationInfo: ibamaIntegrationPreferenceSelected,
				onCancel: function () {
					dialogService.hide($scope.dialog);
				},
				onOk: function (data) {
					dialogService.hide($scope.dialog);
					list();
				}
			};
			var dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/organization/form/organizationIbamaPopups/ibamaIntegrationPopup/ibamaIntegrationPopup_template.html",
					"IbamaIntegrationPopupCtrl",
					null,
					null,
					false,
					function () {},
					params
				)
				.then(
					function () {},
					function () {}
				);
			$scope.dialog = dialog;
			return dialog;
		}

		function list() {
			$scope.ibamaIntegrationPreferenceGrid.loading = true;
			var promise = httpService.getDTOFromServiceV2(ibamaExternalClientService.listIbamaExternalClient);
			promise.then(
				function (response) {
					$scope.ibamaIntegrationPreferenceGrid.list = response;

					$scope.ibamaIntegrationPreferenceGrid.list.map((el) => {
						formatCnpj(el);
						el.showedPassword = "**********";
					});

					$scope.ibamaIntegrationPreferenceGrid.loading = false;
				},
				function () {
					$scope.ibamaIntegrationPreferenceGrid.loading = false;
				}
			);
			return promise;
		}

		function formatCnpj(el) {
			el.CnpjFormated = Vgr.util.formatCNPJ(el.cnpj);
		}

		this.$onInit = function () {
			configureActions();
			list();
		};

		function configureActions() {
			if (editPermission) {
				$scope.ibamaIntegrationPreferenceGrid.defaultActions = ["add"];
				$scope.ibamaIntegrationPreferenceGrid.actionButtons = [
					{
						label: $scope.labels.EDIT,
						icon: "edit",
						click: function (row) {
							openPreferencesPopup(row);
						}
					},
					{
						label: $scope.labels.DELETE,
						icon: "delete",
						click: function (row) {
							deleteClientExternalSystem(row);
						}
					}
				];
			}
		}
	}
]);
