angular.module("vgresiduos").controller("UserPermissionGridCtrl", [
	"$scope",
	"$q",
	"$rootScope",
	"httpService",
	"permissionGroupService",
	"permissionService",
	"dialogService",
	"accountService",
	"userService",
	function (
		$scope,
		$q,
		$rootScope,
		httpService,
		permissionGroupService,
		permissionService,
		dialogService,
		accountService,
		userService
	) {
		"use strict";

		const editPermission = permissionService.hasAnyClientWithPermission(Vgr.systemPermissions.EditUnitPermissions);

		const currentUser = accountService.getLoggedAccount();

		const labels = $rootScope.labels;

		this.$onInit = function () {
			const columns = [
				{
					field: "profileTypeName",
					displayName: function () {
						return labels.PROFILE_TYPE;
					},
					width: 20
				},
				{
					field: "organizationUnit.id",
					displayName: function () {
						return labels.UNIT_CODE;
					},
					width: 30
				},
				{
					field: "organizationUnit.name",
					displayName: function () {
						return labels.UNIT;
					}
				},
				{
					field: "name",
					displayName: function () {
						return labels.PROFILE;
					}
				},
				{
					editOptions: [
						{
							type: "delete",
							icon: "delete",
							label: $scope.labels.DELETE,
							disable: function () {
								return !editPermission;
							},
							click: function () {}
						}
					],
					displayName: function () {
						return labels.ACTIONS;
					}
				}
			];

			const gridOptions = {
				hasPermission: function () {
					return editPermission;
				},
				enableSelect: editPermission,
				columnDefinitions: columns,
				title: labels.PROFILES,
				filterFields: ["organizationUnit.id", "organizationUnit.name", "name"],
				data: [],
				query: {
					order: "name",
					limit: 10,
					page: 1
				},
				deletePopup: {
					title: labels.DELETE_USER_PERMISSION_POPUPTITLE,
					text: labels.DELETE_USER_PERMISSION_POPUPTEXT,
					deleteMethod: permissionGroupService.removeUserFromPermissionGroup,
					descriptionField: "deleteConfirmationMessage",
					getDto: function (row) {
						const dto = {
							type: row.type,
							id: $scope.ctrl.userId,
							permissionGroupId: row.id
						};

						if (row.organizationUnit) {
							dto.organizationUnitCode = row.organizationUnit.code;
						}

						return dto;
					},
					onClose: function () {
						reloadLoggedUserPermissions();
						return loadLists();
					}
				},
				promise: {},
				hasData: false,
				errorLoading: false,
				removeNew: true,
				showDeleteOnSelectedItens: true
			};

			Vgr.util.merge($scope.ctrl.gridOptions, gridOptions, true);

			$rootScope.contentNavBar.customButtons = [
				{
					hasPermission: function () {
						return editPermission;
					},
					label: $scope.labels.ADD_PROFILE,
					icon: "add",
					buttonClasses: "md-primary",
					supressToast: true,
					onClick: function () {
						const deferred = $q.defer();
						openAddUsersPopup();
						deferred.resolve();
						return deferred.promise;
					}
				}
			];
			$rootScope.contentNavBar.validate = false;
			$rootScope.contentNavBar.valid = true;

			loadLists();
			$rootScope.contentNavBar.buttons = [];
			if (editPermission) {
				$rootScope.contentNavBar.buttons.push($scope.labels.ADD_PROFILE);
			}
		};

		function openAddUsersPopup() {
			const currentPermissions = [];
			$scope.ctrl.gridOptions.data.forEach((p) => {
				currentPermissions.push(p.id);
			});

			const params = {
				currentPermissions: currentPermissions
			};

			dialogService
				.showDialogFromTemplateV2(
					"views/components/userGrid/popup/addPermissionGroupToUserPopup_template.html",
					"AddPermissionGroupToUserPopupCtrl",
					null,
					params
				)
				.then(
					function (model) {
						onAddConfirmation(model);
					},
					function () {}
				);
		}

		function onAddConfirmation(model) {
			if (model.addToMultipleUnits) {
				httpService
					.postDTOToServiceV2(permissionGroupService.addUserToMultiplePermissionGroups, {
						permissionGroupName: model.permissionGroupName,
						userId: $scope.ctrl.userId
					})
					.then(function () {
						loadLists();
						reloadLoggedUserPermissions();
					});
			} else {
				const dto = {
					type: model.permissionType,
					organizationUnitCode: model.organizationUnitCode,
					permissionGroupId: model.permissionGroupId,
					model: {
						UserIds: [$scope.ctrl.userId]
					}
				};

				httpService.postDTOToServiceV2(permissionGroupService.addUsersToPermissionGroup, dto).then(function () {
					loadLists();
					reloadLoggedUserPermissions();
				});
			}
		}

		function reloadLoggedUserPermissions() {
			if ($scope.ctrl.userId == currentUser.code) {
				permissionService.loadUserPermissions(currentUser.id).then(function () {
					window.location.reload();
				});
			}
		}

		function listUserPermissionsGroups(permissionType) {
			const deferred = $q.defer();

			httpService.getDTOFromServiceV2(userService.listUserPermissionGroups, $scope.ctrl.userId).then(
				function (data) {
					data.forEach(function (permissionGroup) {
						if (permissionGroup.type == Vgr.enumerations.systemPermissions.typesText.OrganizationUnit) {
							permissionGroup.profileTypeName = labels.UNIT;
							permissionGroup.deleteConfirmationMessage =
								permissionGroup.organizationUnit.name + " - " + permissionGroup.name;
						} else {
							permissionGroup.profileTypeName = labels.ORGANIZATION;
							permissionGroup.deleteConfirmationMessage = permissionGroup.name;
						}
					});
					$scope.ctrl.gridOptions.data = $scope.ctrl.gridOptions.data.concat(data);
					deferred.resolve();
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function loadLists() {
			$scope.ctrl.gridOptions.hasData = false;
			$scope.ctrl.gridOptions.data = [];

			return listUserPermissionsGroups().then(function () {
				$scope.ctrl.gridOptions.hasData = true;
			});
		}
	}
]);
