angular.module("vgresiduos").controller("ResidueFormCtrl", [
	"$scope",
	"$rootScope",
	"$q",
	"$stateParams",
	"$sce",
	"dialogService",
	"residueService",
	"residueV2Service",
	"recipientService",
	"clientService",
	"httpService",
	"measureUnitService",
	"calculusService",
	"ibamaService",
	"userAccessService",
	function (
		$scope,
		$rootScope,
		$q,
		$stateParams,
		$sce,
		dialogService,
		residueService,
		residueV2Service,
		recipientService,
		clientService,
		httpService,
		measureUnitService,
		calculusService,
		ibamaService,
		userAccessService
	) {
		"use strict";

		$scope.additionalPropertyProcess = Vgr.enumerations.additionalProperty.process;
		$scope.conama313Type = Vgr.enumerations.conama313.type.DangerousAndNotDangerousResidues;
		$scope.isEditing = $stateParams.id ? true : false;
		$scope.isMaster = userAccessService.hasAccess(Vgr.systemPermissions.MasterCreateEditClientData);
		$scope.isLowerGenerationPlan = userAccessService.hasAccess(
			null,
			Vgr.constants.featureToggles.permission.lowerGenerationPlan
		);
		$scope.model = {};
		$scope.capacityConversion = {};
		$scope.priceConversion = {};
		$scope.priceDecimals = clientService.getPriceDecimalPlaces();
		$scope.decimals = clientService.getDecimalPlaces();
		$scope.stickerPrintMode = Vgr.enumerations.residue.StickerPrintMode;
		$scope.filteredResidueClassTypes = [];
		$scope.tags = [];
		$scope.selectDefaults = {
			displayText: $rootScope.labels.SELECT_TEXT,
			emptyListText: $rootScope.labels.SELECT_EMPTY,
			emptySearchResultText: $rootScope.labels.SELECT_NO_RESULTS,
			addText: $rootScope.labels.SELECT_ADD,
			searchDelay: 0
		};
		$scope.listOptions = {
			residues: {
				pageSize: 20,
				pageNumber: 1,
				query: "",
				id: 0,
				residueGroups: []
			},
			onuDangerousProduct: {
				pageSize: 30,
				pageNumber: 1,
				query: "",
				id: 0
			},
			nbr: {
				pageSize: 30,
				pageNumber: 1,
				query: "",
				id: 0
			},
			conama358: {
				pageSize: 30,
				pageNumber: 1,
				query: "",
				id: 0
			},
			ibama13: {
				pageSize: 30,
				pageNumber: 1,
				query: "",
				id: 0
			}
		};
		$scope.subfieldList = [{ item: "id", label: $scope.labels.ID }];
		$scope.ibamaSubfieldList = [
			{ item: "ChapterDescription", label: $scope.labels.IBAMA13_RESIDUE_CHAPTER },
			{ item: "SubChapterDescription", label: $scope.labels.IBAMA13_RESIDUE_SUB_CHAPTER }
		];
		$scope.ONUSubfieldList = [{ item: "Class", label: $scope.labels.ONU_CLASS }];
		$scope.showCostModuleAlerts = Vgr.constants.showCostModuleAlerts;
		$scope.unifiedBaseResidueSelectLabel = $scope.isNewResidue
			? $scope.labels.SELECT_RESIDUE_UNIFIED_BASE
			: $scope.labels.DESCRIPTION_UNIFIED_BASE;

		const defaultONUPackingGroups = residueService.listOnuPackingGroups();

		this.$onInit = function () {
			$scope.model.setterProcess = $scope.ctrl.inputResidue.ProcessId;

			if (!$scope.ctrl.inputResidue.TagList) {
				$scope.ctrl.inputResidue.TagList = [];
			}

			if (!$scope.ctrl.inputResidue.ID) {
				$scope.ctrl.inputResidue.StorageAlertPercentage = Vgr.constants.defaultStorageAlertPercentage;
			}

			setTextcompleteValues();

			httpService.getListFromService(clientService.getTagList).then(function (list) {
				for (const item of list) {
					item.Id = item.ID;
				}
				$scope.tags = list;
			});

			httpService.getListFromServiceCore(residueV2Service.listNbrs).then(function (list) {
				list.list.forEach(function (element) {
					$scope.setNbrLabel(element);
				});
				$scope.nbrList = list.list;
			});

			httpService.getListFromServiceV2(ibamaService.listIbamaResidues, null, "IbamaResidues").then(function (list) {
				list.forEach(function (element) {
					$scope.setIbamaLabel(element);
				});
				$scope.ibamaResidueList = list;
			});

			httpService.getListFromServiceCore(residueV2Service.listConama358).then(function (list) {
				list.list.forEach(function (element) {
					$scope.setConama358Label(element);
				});
				$scope.conama358List = list.list;
			});

			httpService.getListFromServiceCore(residueV2Service.listPictograms).then(function (result) {
				$scope.pictogramList = result.list;
			});

			$scope.onuDangerousProductSelected($scope.ctrl.inputResidue.OnuDangerousProduct, true);
			listMeasureUnits();
			listRecipients();
			listResidueClassTypes();

			if (!$scope.isNewResidue) {
				$scope.model.residue = {
					id: $scope.ctrl.inputResidue.GeneralResidueId,
					name: $scope.ctrl.inputResidue.GeneralResidueName
				};
			}
		};

		$scope.$watch("ctrl.saveOptions", function (newVal) {
			if (newVal) {
				$scope.pictogramFileOptions = newVal.pictogramFileOptions;
			}
		});

		$scope.$watch("residueForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});

		$scope.onIbamaResidueChange = function (newValue) {
			if (
				(!newValue && !$scope.ctrl.inputResidue.IbamaResidue) ||
				(newValue && $scope.ctrl.inputResidue.IbamaResidue && newValue.Id === $scope.ctrl.inputResidue.IbamaResidue.Id)
			) {
				return;
			}
			validateResidueDangerousness(newValue);
			filterResidueClassTypes(newValue);
		};

		function filterResidueClassTypes(ibamaResidue) {
			if (!ibamaResidue) {
				resetResidueClassTypesFilter();
			} else {
				$scope.filteredResidueClassTypes = $scope.residueClassTypes.filter(
					(residueClassType) => residueClassType.isDangerous === ibamaResidue.IsDangerous
				);
			}
		}

		function validateResidueDangerousness(ibamaResidue) {
			if ($scope.ctrl.inputResidue.ResidueClassType && ibamaResidue) {
				const findedResidueClass = $scope.residueClassTypes.find(
					(residueClassType) => residueClassType.id == $scope.ctrl.inputResidue.ResidueClassType.Id
				);
				if (!findedResidueClass || findedResidueClass.isDangerous !== ibamaResidue.IsDangerous) {
					$scope.ctrl.inputResidue.ResidueClassType = null;
					$rootScope.$broadcast(Vgr.constants.evtShowWarningMessage, $rootScope.labels["ERR-RES-0009"]);
				}
			}
		}

		function addSelectedResidueClassTypeOnEditing() {
			if ($scope.ctrl.inputResidue.ResidueClassType) {
				const isCurrentClassTypeInFilter = $scope.filteredResidueClassTypes.find(
					(residueClassType) => residueClassType.id === $scope.ctrl.inputResidue.ResidueClassType.Id
				);
				if (!isCurrentClassTypeInFilter) {
					const currentClassType = $scope.residueClassTypes.find(
						(residueClassType) => residueClassType.id === $scope.ctrl.inputResidue.ResidueClassType.Id
					);
					$scope.filteredResidueClassTypes.push(currentClassType);
				}
			}
		}

		function resetResidueClassTypesFilter() {
			$scope.filteredResidueClassTypes = $scope.residueClassTypes;
		}

		function listMeasureUnits() {
			const dto = {
				onlyResidueUnits: true
			};
			httpService.getListFromServiceV2(measureUnitService.listMeasureUnits, dto, "MeasureUnits").then(
				function (list) {
					$scope.measureUnitList = [];
					$scope.measureUnitUnitaryPriceList = [];

					angular.copy(list, $scope.measureUnitList);
					angular.copy(list, $scope.measureUnitUnitaryPriceList);
				},
				function (data) {}
			);
		}

		$scope.defaultUnitChanged = function (measureUnitId) {
			if (!measureUnitId || !$scope.measureUnitList) {
				return;
			}

			const measureUnit = $scope.measureUnitList.find((mu) => mu.Id === measureUnitId);
			const oldMeasureUnit = $scope.ctrl.inputResidue.MeasureUnit;
			$scope.ctrl.inputResidue.MeasureUnit = measureUnit;

			updateStorageCapacity(oldMeasureUnit);
			updateUnitaryPrice();
			updateMinMaxValues(oldMeasureUnit);
		};

		function getConvertedQuantity(currentValue, currentUnit, finalUnit) {
			return currentValue * (currentUnit.ConversionFactor / finalUnit.ConversionFactor);
		}

		function updateMinMaxValues(oldMeasureUnit) {
			if (!oldMeasureUnit || !$scope.ctrl.inputResidue.QuantityLimitActive) {
				return;
			}

			if (oldMeasureUnit.Group == $scope.ctrl.inputResidue.MeasureUnit.Group) {
				if ($scope.ctrl.inputResidue.MinimumQuantity) {
					$scope.ctrl.inputResidue.MinimumQuantity = getConvertedQuantity(
						$scope.ctrl.inputResidue.MinimumQuantity,
						oldMeasureUnit,
						$scope.ctrl.inputResidue.MeasureUnit
					);
				}
				if ($scope.ctrl.inputResidue.MaximumQuantity) {
					$scope.ctrl.inputResidue.MaximumQuantity = getConvertedQuantity(
						$scope.ctrl.inputResidue.MaximumQuantity,
						oldMeasureUnit,
						$scope.ctrl.inputResidue.MeasureUnit
					);
				}
			} else {
				$scope.ctrl.inputResidue.MinimumQuantity = null;
				$scope.ctrl.inputResidue.MaximumQuantity = null;
			}
		}

		$scope.listResidues = function () {
			if (!$scope.listOptions.residues.query) {
				$scope.listOptions.residues.query = "";
			}
			const deferred = $q.defer();
			httpService
				.getListFromServiceCore(residueV2Service.listSystemResidues, {
					take: 20,
					skip: $scope.listOptions.residues.pageNumber - 1,
					residueName: $scope.listOptions.residues.query
				})
				.then(function (response) {
					deferred.resolve(response.list);
				});
			return deferred.promise;
		};

		$scope.listOnuDangerousProducts = function () {
			const deferred = $q.defer();
			if (!$scope.listOptions.onuDangerousProduct.query) {
				$scope.listOptions.onuDangerousProduct.query = "";
			}
			httpService
				.getListFromServiceV2(
					residueService.listOnuDangerousProducts,
					$scope.listOptions.onuDangerousProduct,
					"OnuDangerousProducts"
				)
				.then(
					function (list) {
						list.forEach((element) => {
							$scope.setOnuDangerousProductLabel(element);
						});
						deferred.resolve(list);
					},
					function () {
						deferred.reject();
					}
				);

			return deferred.promise;
		};

		$scope.listConama358 = function () {
			const deferred = $q.defer();
			if (!$scope.listOptions.conama358.query) {
				$scope.listOptions.conama358.query = "";
			}
			const filteredList = $scope.conama358List.filter(function (c) {
				return c.Label.toUpperCase().indexOf($scope.listOptions.conama358.query.toUpperCase()) != -1;
			});
			deferred.resolve(filteredList);
			return deferred.promise;
		};

		$scope.listNbr = function () {
			const deferred = $q.defer();
			if (!$scope.listOptions.nbr.query) {
				$scope.listOptions.nbr.query = "";
			}

			const filteredList = $scope.nbrList.filter(function (c) {
				return c.Label.toUpperCase().indexOf($scope.listOptions.nbr.query.toUpperCase()) != -1;
			});
			deferred.resolve(filteredList);
			return deferred.promise;
		};

		$scope.getPhysicStateList = function () {
			return httpService.getListFromService(residueService.getPhysicStateList);
		};

		function listRecipients() {
			httpService.getListFromServiceCore(recipientService.listClientRecipientsCore, null).then(function (response) {
				$scope.clientRecipientList = response.list;
			});
		}

		$scope.residueSelected = function (residue) {
			if ($scope.model.residue && residue && $scope.model.residue.Id == residue.id) {
				return;
			}
			if (residue) {
				httpService.getDTOFromServiceV2(residueService.getResidue, residue.id).then(
					function (response) {
						if (!response.Residue) {
							$scope.ctrl.inputResidue.MRID = null;
							return;
						}
						$scope.model.residue = response.Residue;
						$scope.model.residue.id = $scope.model.residue.Id;
						$scope.model.residue.name = $scope.model.residue.Name;
						$scope.ctrl.inputResidue.MRID = response.Residue.Id;

						if ($scope.isNewResidue) {
							loadResidueAditionalData(response.Residue);
							$scope.$broadcast("singleBaseChanged", residue.name);
						}
					},
					function () {
						$scope.ctrl.inputResidue.MRID = null;
					}
				);
			} else {
				$scope.ctrl.inputResidue.MRID = null;
			}
		};

		$scope.onPictogramSelect = function (pictogram) {
			$scope.pictogramFileOptions.addFile(
				{
					FileName: pictogram.name,
					name: pictogram.name,
					url: pictogram.fileLink,
					type: "image/gif",
					path: pictogram.path
				},
				"pictogram",
				".gif"
			);
		};

		$scope.openFilterPopup = function () {
			$scope.popupTitle = $rootScope.labels.RESIDUE_GROUPS;
			const dialog = dialogService
				.showDialogFromTemplateWithScope(
					null,
					"views/residue/components/residueFilterPopup_template.html",
					"ResidueFilterPopupCtrl",
					$scope,
					"#residue-filter-popup",
					false,
					function () {
						/* no content purposely */
					}
				)
				.then(
					function () {
						/* no content purposely */
					},
					function () {
						/* no content purposely */
					}
				);
			$scope.dialog = dialog;
		};

		$scope.onuSelectDisabled = function () {
			if ($scope.ctrl.inputResidue.ResidueClassType && $scope.ctrl.inputResidue.ResidueClassType.Id) {
				const findedResidueClass = $scope.filteredResidueClassTypes.find(
					(residueClassType) => residueClassType.id == $scope.ctrl.inputResidue.ResidueClassType.Id
				);
				return findedResidueClass && !findedResidueClass.isDangerous;
			}

			return false;
		};

		function getMeasureUnitById(list, id) {
			return list.find((mu) => mu.Id === id);
		}

		function loadResidueAditionalData(residue) {
			$scope.ctrl.inputResidue.DescriptionPt = residue.Name;
			$scope.ctrl.inputResidue.ResidueGroups = residue.ResidueGroups;
			$scope.ctrl.inputResidue.MeasureUnit = residue.MeasureUnit;
			$scope.ctrl.inputResidue.UnitaryPriceUnit = !$scope.isLowerGenerationPlan ? residue.MeasureUnit : null;
			$scope.ctrl.inputResidue.ResidueClassType = residue.ResidueClass;
			$scope.setConama358Label(residue.Conama358);
			$scope.setIbamaLabel(residue.IbamaResidue);
			$scope.ctrl.inputResidue.ONUDangerousProduct = residue.ONUDangerousProduct;
			$scope.setOnuDangerousProductLabel(residue.ONUDangerousProduct);
			$scope.ctrl.inputResidue.IbamaResidue = residue.IbamaResidue;
			$scope.setNbrLabel(residue.NBR);
			$scope.ctrl.inputResidue.PhysicStateDTO = residue.PhysicalState ? { ID: residue.PhysicalState.ID } : { ID: "" };
		}

		function updateStorageCapacity(oldMeasureUnit) {
			if ($scope.ctrl.inputResidue.Capacity && $scope.ctrl.inputResidue.Capacity != 0 && oldMeasureUnit) {
				convertValue($scope.ctrl.inputResidue.Capacity, oldMeasureUnit, $scope.ctrl.inputResidue.MeasureUnit).then(
					function (value) {
						$scope.ctrl.inputResidue.Capacity = value;
						capacityConversionSuccess();
					},
					function () {
						capacityConversionImpossible();
					}
				);
			}
		}

		function updateUnitaryPrice() {
			if (
				$scope.ctrl.inputResidue.UnitaryPrice &&
				$scope.ctrl.inputResidue.UnitaryPrice != 0 &&
				$scope.ctrl.inputResidue.UnitaryPriceUnit
			) {
				convertValue(
					$scope.ctrl.inputResidue.UnitaryPrice,
					$scope.ctrl.inputResidue.MeasureUnit,
					$scope.ctrl.inputResidue.UnitaryPriceUnit
				).then(
					function (value) {
						$scope.ctrl.inputResidue.UnitaryPrice = value;
						$scope.ctrl.inputResidue.UnitaryPriceUnit = getMeasureUnitById(
							$scope.measureUnitUnitaryPriceList,
							$scope.ctrl.inputResidue.MeasureUnit.Id
						);
						priceConversionSuccess();
					},
					function () {
						priceConversionImpossible();
					}
				);
			} else {
				$scope.ctrl.inputResidue.UnitaryPriceUnit = getMeasureUnitById(
					$scope.measureUnitUnitaryPriceList,
					$scope.ctrl.inputResidue.MeasureUnit.Id
				);
			}
		}

		function convertValue(currentValue, currentUnit, finalUnit) {
			const deferred = $q.defer();
			if (areUnitsFromSameGroup(currentUnit, finalUnit)) {
				deferred.resolve(getConvertedQuantity(currentValue, currentUnit, finalUnit));
			} else if (areMassAndVolume(currentUnit, finalUnit)) {
				if ($scope.ctrl.inputResidue.VolumeConversionFactor) {
					return calculateQuantity(
						currentValue,
						currentUnit,
						finalUnit,
						$scope.ctrl.inputResidue.VolumeConversionFactor
					);
				} else {
					deferred.reject();
				}
			} else if (areMassAndUnit(currentUnit, finalUnit)) {
				if ($scope.ctrl.inputResidue.UnitConversionFactor) {
					return calculateQuantity(currentValue, currentUnit, finalUnit, $scope.ctrl.inputResidue.UnitConversionFactor);
				} else {
					deferred.reject();
				}
			} else {
				deferred.reject();
			}
			return deferred.promise;
		}

		function areUnitsFromSameGroup(unitA, unitB) {
			return unitA.Group == unitB.Group;
		}

		function areMassAndVolume(unitA, unitB) {
			return (
				(unitA.Group == Vgr.enumerations.measureUnit.Group.Volume &&
					unitB.Group == Vgr.enumerations.measureUnit.Group.Mass) ||
				(unitA.Group == Vgr.enumerations.measureUnit.Group.Mass &&
					unitB.Group == Vgr.enumerations.measureUnit.Group.Volume)
			);
		}

		function areMassAndUnit(unitA, unitB) {
			return (
				(unitA.Group == Vgr.enumerations.measureUnit.Group.Unit &&
					unitB.Group == Vgr.enumerations.measureUnit.Group.Mass) ||
				(unitA.Group == Vgr.enumerations.measureUnit.Group.Mass &&
					unitB.Group == Vgr.enumerations.measureUnit.Group.Unit)
			);
		}

		function isMassUnit(unit) {
			return unit.Group == Vgr.enumerations.measureUnit.Group.Mass;
		}

		function calculateQuantity(currentValue, currentUnit, finalUnit, conversionFactor) {
			const deferred = $q.defer();

			if (isMassUnit(currentUnit)) {
				conversionFactor = 1 / conversionFactor;
			}

			const dto = {
				Quantity: currentValue,
				UnitId: currentUnit.Id,

				FinalUnitId: finalUnit.Id,
				ConversionFactor: conversionFactor
			};

			httpService.postDTOToServiceV2(calculusService.calculateQuantityConverted, dto).then(
				function (response) {
					deferred.resolve(response.Quantity);
				},
				function () {
					deferred.reject();
				}
			);

			return deferred.promise;
		}

		function capacityConversionSuccess() {
			$scope.capacityConversion = {
				active: true,
				message: $scope.labels.CAPACITY_CONVERSION_ALERT_MESSAGE,
				success: true
			};
		}

		function capacityConversionImpossible() {
			$scope.capacityConversion = {
				active: true,
				message: $scope.labels.CAPACITY_CONVERSION_IMPOSSIBLE_MESSAGE
			};
		}

		function priceConversionSuccess() {
			$scope.priceConversion = {
				active: true,
				message: $scope.labels.PRICE_CONVERSION_ALERT_MESSAGE,
				success: true
			};
		}

		function priceConversionImpossible() {
			$scope.priceConversion = {
				active: true,
				message: $scope.labels.PRICE_CONVERSION_IMPOSSIBLE_MESSAGE
			};
		}

		function listResidueClassTypes() {
			httpService.getListFromServiceCore(residueService.listResidueClassTypes).then(
				function (response) {
					$scope.residueClassTypes = response.list;
					filterResidueClassTypes($scope.ctrl.inputResidue.IbamaResidue);
					addSelectedResidueClassTypeOnEditing();
				},
				function () {
					$scope.residueClassTypes = [];
				}
			);
		}

		$scope.onuDangerousProductSelected = function (onuDangerousProduct, isInit) {
			if (onuDangerousProduct && onuDangerousProduct.ONUPackingGroups.length) {
				const ONUPackingGroups = [];
				defaultONUPackingGroups.forEach((element) => {
					if (onuDangerousProduct.ONUPackingGroups.find((o) => o.Id == element.Id)) {
						ONUPackingGroups.push(element);
					}
				});
				if (!isInit) {
					if (ONUPackingGroups.length == 1) {
						$scope.ctrl.inputResidue.ONUPackingGroupId = ONUPackingGroups[0].Id;
					} else {
						$scope.ctrl.inputResidue.ONUPackingGroupId = null;
					}
				}
				$scope.ONUPackingGroups = ONUPackingGroups;
			} else if (onuDangerousProduct && !onuDangerousProduct.ONUPackingGroups.length) {
				$scope.ONUPackingGroups = defaultONUPackingGroups;
			} else {
				$scope.ONUPackingGroups = [];
				$scope.ctrl.inputResidue.ONUPackingGroupId = null;
			}
		};

		$scope.getCodeLabel = function () {
			const label = $scope.labels.INTERNAL_ID;
			if ($scope.isEditing) {
				return label + "*";
			}
			return label;
		};

		$scope.residueClassTypesChanged = function (residueClassTypeId) {
			const findedResidueClass = $scope.filteredResidueClassTypes.find((rct) => rct.id == residueClassTypeId);
			if (findedResidueClass && !findedResidueClass.isDangerous) {
				$scope.OnuDangerousProductSearchText = null;
				$scope.ctrl.inputResidue.OnuDangerousProduct = null;
				$scope.ctrl.inputResidue.ONUPackingGroupId = null;
			}
		};

		$scope.getUnitaryPriceAlertMessage = function () {
			return $sce.trustAsHtml($scope.labels.COST_MODULE_ALERTS.RESIDUE_UNITARY_PRICE);
		};

		function setTextcompleteValues() {
			if ($scope.ctrl.inputResidue.Conama358DTO) {
				$scope.setConama358Label($scope.ctrl.inputResidue.Conama358DTO);
			}
			if ($scope.ctrl.inputResidue.NBRDTO) {
				$scope.setNbrLabel($scope.ctrl.inputResidue.NBRDTO);
			}
		}
	}
]);
