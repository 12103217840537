angular.module("vgresiduos").factory("productIngredientService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getBaseRequestUrl(productCode) {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.productIngredients
					.replace("[ORGANIZATION_ID]", organizationId)
					.replace("[ORGANIZATION_UNIT_CODE]", organizationUnitCode)
					.replace("[PRODUCT_CODE]", productCode)
			);
		}

		const _createIngredient = function (productIngredientDTO) {
			const req = {
				method: "post",
				data: productIngredientDTO,
				url: getBaseRequestUrl(productIngredientDTO.productCode)
			};
			return $http(req);
		};

		const _getIngredientList = function (productCode) {
			const req = {
				method: "get",
				url: getBaseRequestUrl(productCode)
			};
			return $http(req);
		};

		const _getIngredientByCode = function (productCode, ingredientCode) {
			const req = {
				method: "get",
				url: getBaseRequestUrl(productCode) + ingredientCode
			};
			return $http(req);
		};

		const _updateIngredient = function (productIngredientDTO) {
			const req = {
				method: "put",
				data: productIngredientDTO,
				url: getBaseRequestUrl(productIngredientDTO.productCode) + productIngredientDTO.id
			};
			return $http(req);
		};

		const _deleteIngredient = function (productCode, ingredientCode) {
			const req = {
				method: "delete",
				url: getBaseRequestUrl(productCode) + ingredientCode
			};
			return $http(req);
		};

		return {
			getIngredientListByProduct: _getIngredientList,
			getIngredientByCode: _getIngredientByCode,
			updateIngredient: _updateIngredient,
			createIngredient: _createIngredient,
			deleteIngredient: _deleteIngredient
		};
	}
]);
