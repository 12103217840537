angular.module("vgresiduos").controller("IngredientGridCtrl", [
	"$scope",
	"productIngredientService",
	"$rootScope",
	"httpService",
	"clientService",
	"$q",
	function ($scope, productIngredientService, $rootScope, httpService, clientService, $q) {
		"use strict";

		const decimalPlaces = clientService.getDecimalPlaces();

		this.$onInit = function () {
			$scope.columns = [
				{
					field: "id",
					displayName: function () {
						return $rootScope.labels.ID;
					},
					width: 100
				},
				{
					field: "name",
					displayName: function () {
						return $rootScope.labels.NAME;
					}
				},
				{
					field: "casNumber",
					displayName: function () {
						return $rootScope.labels.CAS;
					},
					width: 200
				},
				{
					field: "minPercentage",
					displayName: function () {
						return $rootScope.labels.MIN_PERCENTAGE;
					}
				},
				{
					field: "maxPercentage",
					displayName: function () {
						return $rootScope.labels.MAX_PERCENTAGE;
					}
				}
			];

			const selectedToDeletedIds = function (selected) {
				const deletedIds = {
					ingredientIds: "",
					ingredientClientIds: "",
					ingredientClientGroupCodes: ""
				};
				const ingredientIds = [];
				const clientIds = [];
				const clientGroupIds = [];
				selected.forEach(function (element, index) {
					ingredientIds[index] = element.id;
					clientIds[index] = element.organizationUnitId;
					clientGroupIds[index] = element.organizationId;
				});
				deletedIds.ingredientIds = ingredientIds.join(", ");
				deletedIds.ingredientClientIds = clientIds.join(", ");
				deletedIds.ingredientClientGroupCodes = clientGroupIds.join(", ");
				return deletedIds;
			};

			const gridOptions = {
				columnDefinitions: $scope.columns,
				filterFields: ["id", "name", "cas", "maxPercentage", "minPercentage"],
				title: $scope.ctrl.product
					? $rootScope.labels.INGREDIENTS + ": " + $scope.ctrl.product.Name
					: $rootScope.labels.PRODUCTIONS,
				query: {
					order: "id",
					limit: 10,
					page: 1
				},
				data: [],
				showDeleteOnSelectedItens: true,
				rowClick: $scope.ctrl.ingredientEdit,
				create: $scope.ctrl.ingredientEdit,
				promise: {},
				hasData: false,
				errorLoading: false,
				list: function () {
					return productIngredientService.getIngredientListByProduct($scope.ctrl.product.code);
				},
				delete: function (selected) {
					const deferred = $q.defer();
					const deletedIds = selectedToDeletedIds(selected);
					selected.forEach(function (element) {
						productIngredientService.deleteIngredient($scope.ctrl.product.code, element.id).then(
							function (response) {
								$scope.ctrl.gridOptions.data = $scope.ctrl.gridOptions.data.filter(function (ingredient) {
									return !deletedIds.ingredientIds.split(", ").find(function (id) {
										return id == ingredient.id;
									});
								});
								deferred.resolve(response);
							},
							function () {
								$scope.ctrl.gridOptions.errorLoading = true;
								$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels.SERVER_ERROR);
								deferred.reject();
							}
						);
					});
					return deferred.promise;
				}
			};

			if (!$scope.ctrl.gridOptions) {
				$scope.ctrl.gridOptions = {};
			}
			Vgr.util.merge($scope.ctrl.gridOptions, gridOptions, true);

			$scope.ctrl.gridOptions.promise = httpService
				.getDTOFromServiceV2(productIngredientService.getIngredientListByProduct, $scope.ctrl.product.code)
				.then(function (list) {
					for (let i = 0; i < list.length; ++i) {
						list[i].maxPercentage = Vgr.util.formatUnitDecimalPlaces(list[i].maxPercentage, decimalPlaces);
						list[i].minPercentage = Vgr.util.formatUnitDecimalPlaces(list[i].minPercentage, decimalPlaces);
					}
					$scope.ctrl.gridOptions.data = list;
					$scope.ctrl.gridOptions.hasData = true;
				});

			$rootScope.contentNavBarGrid = $scope.ctrl.gridOptions;
		};
	}
]);
