angular.module("vgresiduos").controller("auditNotificationPopupCtrl", [
	"$rootScope",
	"$scope",
	"dialogService",
	"supplierService",
	"$q",
	"engagementScoreService",
	"httpService",
	"auditService",
	"clientService",
	"$sce",
	function (
		$rootScope,
		$scope,
		dialogService,
		supplierService,
		$q,
		engagementScoreService,
		httpService,
		auditService,
		clientService,
		$sce
	) {
		"use strict";

		$scope.canAddObservations = function () {
			return $scope.popupPreferences.type == Vgr.enumerations.audit.Notification.Effect;
		};

		function setPopupPreferences() {
			switch ($scope.popupType) {
				case Vgr.enumerations.audit.Notification.Effect:
					$scope.popupPreferences.title = $scope.labels.NOTIFY_SUPPLIER;
					$scope.popupPreferences.message = $scope.labels.SUPPLIER_SOLICITATION_POPUP_MESSAGE_TO_EFFECT;
					$scope.popupPreferences.type = Vgr.enumerations.audit.Notification.Effect;
					break;
				case Vgr.enumerations.audit.Notification.SupplierSolicitation:
					$scope.popupPreferences.title = $scope.labels.REQUEST_TO_SUPPLIER;
					$scope.popupPreferences.message = $scope.labels.SUPPLIER_SOLICITATION_POPUP_MESSAGE1;
					$scope.popupPreferences.type = Vgr.enumerations.audit.Notification.SupplierSolicitation;
					break;
			}
		}

		$scope.changeTab = function (tab) {
			$scope.tabIndex = tab;
		};

		$scope.click = function () {
			if (!validate()) {
				return;
			}
			const dto = createDto();
			$scope.sendingEmailToSupplier = true;
			httpService.getDTOFromServiceV2(auditService.getAuditNotification, dto).then(
				function (response) {
					$scope.htmlObject = $sce.trustAsHtml(response.Template);
					setDisabledTab(false);
					$scope.changeTab("observations");
					$scope.sendingEmailToSupplier = false;
				},
				function () {
					$scope.sendingEmailToSupplier = false;
				}
			);
		};

		function loadSupplier() {
			$scope.sendingEmailToSupplier = true;
			httpService.getDTOFromServiceV2(supplierService.getOrganizationSupplier, $scope.audit.AuditedClientId).then(
				function (supplier) {
					$scope.auditNotificationModel.SupplierName = supplier.name;
					$scope.auditNotificationModel.SupplierEmail = supplier.email;
					$scope.sendingEmailToSupplier = false;
				},
				function () {
					$scope.sendingEmailToSupplier = false;
				}
			);
		}

		$scope.back = function () {
			$scope.changeTab("emailTemplate");
			setDisabledTab(true);
		};

		$scope.close = function () {
			dialogService.hide($scope.dialog);
			reloadAudit();
		};

		$scope.cancel = function () {
			dialogService.hide($scope.dialog);
		};

		$scope.sendEmailToSupplier = function () {
			if ($scope.popupPreferences.type == Vgr.enumerations.audit.Notification.Effect) {
				sendEffectEmailToSupplier();
			} else {
				sendSolicitationEmailToSupplier();
			}
		};

		function sendEffectEmailToSupplier() {
			processAudit().then(function (response) {
				const dto = applyAuditDto();
				httpService.postDTOToServiceV2(auditService.applySupplierAudit, dto).then(
					function (response) {
						$scope.goBack();
					},
					function (response) {}
				);
			});
		}

		function applyAuditDto() {
			return {
				auditId: $scope.auditId,
				supplierEmail: $scope.auditNotificationModel.SupplierEmail ? $scope.auditNotificationModel.SupplierEmail : null,
				supplierName: $scope.auditNotificationModel.SupplierName ? $scope.auditNotificationModel.SupplierName : null
			};
		}

		function sendSolicitationEmailToSupplier() {
			$scope.sendingEmailToSupplier = true;
			const dto = createDto();
			httpService.postDTOToServiceV2(auditService.transferAuditControl, dto).then(
				function () {
					processAudit();
					$scope.sendAuditToSupplier = true;
					$scope.cancel();
					$scope.sendingEmailToSupplier = false;
					engagementScoreService.track(Vgr.trackings.supplier.audit.requestSupplier);
					const dialog = dialogService
						.showDialogFromTemplateWithScope(
							null,
							"views/audit/popups/auditNotificationPopup_template.html",
							"auditNotificationPopupCtrl",
							$scope,
							"#supplier-solicitation-popup-small",
							false,
							function () {}
						)
						.then(
							function () {},
							function () {}
						);
					$scope.dialog = dialog;
				},
				function (response) {
					$scope.sendingEmailToSupplier = false;
				}
			);
		}

		function validate() {
			if (
				$scope.auditNotificationModel.SupplierEmail &&
				Vgr.util.isInvalidEmail($scope.auditNotificationModel.SupplierEmail)
			) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.WRONG_EMAIL_FORMAT);
				return false;
			}
			return true;
		}

		function processAudit() {
			const deferred = $q.defer();
			const auditDTO = buildAuditDTO();
			httpService.postDTOToServiceV2(auditService.updateSupplierAudit, auditDTO).then(
				function (response) {
					deferred.resolve();
				},
				function (response) {
					deferred.reject();
				}
			);
			return deferred.promise;
		}

		function buildAuditDTO() {
			const dto = {
				auditId: $scope.audit.Id,
				toCancel: false,
				toApply: false,
				audit: $scope.audit
			};

			return dto;
		}

		function reloadAudit() {
			window.location.reload();
		}

		function setDisabledTab(emailTabState) {
			$scope.emailTabDisabled = emailTabState;
			$scope.observationTabDisable = !$scope.emailTabDisabled;
		}

		function createDto() {
			return {
				AuditId: $scope.auditId,
				Type: $scope.popupPreferences.type,
				Observations: $scope.auditNotificationModel.Observations,
				SupplierName: $scope.auditNotificationModel.SupplierName,
				SupplierEmail: $scope.auditNotificationModel.SupplierEmail
			};
		}

		function initializePopup() {
			$scope.auditNotificationModel = {};
			$scope.popupPreferences = {};
			$scope.tabObservationsEnabled = true;
			setDisabledTab(true);
			$scope.tabIndex = "observations";
			$scope.isSupplier = clientService.isSupplier();
			loadSupplier();
			setPopupPreferences();
		}

		initializePopup();
	}
]);
