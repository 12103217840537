angular.module("vgresiduos").controller("MtrFollowUpCtrl", [
	"$scope",
	"$rootScope",
	"localStorageService",
	"dialogService",
	"engagementScoreService",
	function ($scope, $rootScope, localStorageService, dialogService, engagementScoreService) {
		"use strict";

		this.$onInit = function () {
			buildNewFeaturesPopups();
			Vgr.util.redirectSingleton($rootScope, `acompanhamento-mtrs`);
		};

		function buildNewFeaturesPopups() {
			const reportData = buildCustomReportNewFeaturesPopup();
			if (reportData) {
				openNewFeaturesPopups(reportData);
			}
		}

		function buildCustomReportNewFeaturesPopup() {
			if (!localStorageService.get(Vgr.enumerations.newFeaturesKey.enhancedMtrMonitoringScreen)) {
				return buildPopup();
			}
		}

		function buildPopup() {
			const featurePopupModel = getEnhancedMtrMonitoringScreen();
			return {
				key: featurePopupModel.key,
				imageLink: featurePopupModel.imageLink,
				knowMore: featurePopupModel.knowMore,
				buttons: featurePopupModel.buttons,
				isManualAccess: featurePopupModel.isManualAccess,
				isFepamAlert: featurePopupModel.isFepamAlert,
				isManualAccessKnowMoreBelow: featurePopupModel.isManualAccessKnowMoreBelow
			};
		}

		function getEnhancedMtrMonitoringScreen() {
			return {
				key: Vgr.enumerations.newFeaturesKey.enhancedMtrMonitoringScreen,
				imageLink: "/img/generalTopAlerts/enhancedMtrMonitoringScreen.png",
				isManualAccess: true,
				isManualAccessKnowMoreBelow: false,
				knowMore: function () {
					trackEventAction(Vgr.trackings.internal.transfer.accessManualNewFeature);
					const url = Vgr.constants.zendesk.baseUrl + Vgr.constants.zendesk.guides.enhancedMtrMonitoringScreen;
					window.open(url, "_blank");
				},
				buttons: [
					{
						label: $scope.labels.CLOSE,
						class: "md-primary button-large",
						click: function () {
							trackEventAction(Vgr.trackings.internal.transfer.closePopupNewFeature);
							dialogService.confirm();
						}
					}
				]
			};
		}

		function openNewFeaturesPopups(reportData) {
			const disablePopup = localStorageService.get(reportData.key);
			if (disablePopup || reportData == null) {
				return;
			}
			dialogService
				.showDialogFromTemplateV2(
					"views/components/generalTopAlerts/newFeatures/newFeaturesPopup_template.html",
					"NewFeaturesPopupCtrl",
					".vgr-page",
					{ popupModel: reportData },
					true
				)
				.then(
					function () {
						localStorageService.set(reportData.key, true);
					},
					function () {
						localStorageService.set(reportData.key, true);
					}
				);
		}

		function trackEventAction(event) {
			engagementScoreService.track(event);
		}
	}
]);
