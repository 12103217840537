angular.module("vgresiduos").controller("CustomizedInputFormCtrl", [
	"$rootScope",
	"$scope",
	function ($rootScope, $scope) {
		"use strict";
		$scope.labels = $rootScope.labels;

		this.$onInit = function () {
			if ($scope.ctrl.rows.length == 0) {
				initInputs();
			}

			$scope.addButton = [
				{
					click: function () {
						addNewInputRow();
					}
				}
			];
		};

		$scope.removeRow = function (index) {
			if ($scope.ctrl.rows.length == 1) {
				$scope.ctrl.rows.length = 0;
				addNewInputRow();
			} else {
				$scope.ctrl.rows.splice(index, 1);
			}
		};

		function initInputs() {
			addNewInputRow();
		}

		$scope.isDeleteButtonEnabled = function (index) {
			return $scope.ctrl.rows[index].field || $scope.ctrl.rows.length > 1;
		};

		$scope.showAddButton = function (index) {
			if (index == $scope.ctrl.rows.length - 1) {
				return true;
			}
			return false;
		};

		function addNewInputRow() {
			var availableFields = angular.copy($scope.ctrl.availableFields);
			var newRow = {
				fields: availableFields
			};
			$scope.ctrl.rows.push(newRow);
			return newRow;
		}

		$scope.$watch("ctrl.addNewRow", function (newVal, oldVal) {
			if (newVal != oldVal && newVal) {
				addNewInputRow();
			}
		});

		$scope.shouldFocusField = function (rowIndex, fieldIndex) {
			return rowIndex == 0 && fieldIndex == 0;
		};
	}
]);
