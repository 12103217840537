angular.module("vgresiduos").controller("movimentationReportCtrl", [
	"$rootScope",
	"$scope",
	"$q",
	"clientService",
	"httpService",
	"productV2Service",
	function ($rootScope, $scope, $q, clientService, httpService, productV2Service) {
		"use strict";

		this.$onInit = function () {
			$scope.labels = $rootScope.labels;
			$scope.movimentationReportFilter = {};
			$scope.movimentationReportFilter.dateId = 1;
			$scope.movimentationReportDTO = {
				format: "PDF"
			};

			$scope.movimentationReportFilter.showDestinationData = true;

			$scope.reportOptions = new Cmp.constructors.ReportOptionsV2(
				$rootScope.labels.RESIDUE_MOVIMENTATION_REPORT_TITLE,
				clientService.generateMovimentationReport,
				$scope.movimentationReportDTO
			);

			$scope.supplierSelected = function (supplier) {
				$scope.filter.supplierId = supplier ? supplier.ID : "";
			};

			$scope.productOptions = {
				take: 10,
				pageNumber: 1,
				query: ""
			};
			$scope.getProductList = function () {
				const deferred = $q.defer();
				httpService.getListFromServiceCore(productV2Service.listProducts, $scope.productOptions).then(function (resp) {
					resp.list.forEach((p) => {
						p.ID = p.code;
						p.Code = p.code;
					});
					deferred.resolve(resp.list);
				});
				return deferred.promise;
			};
			$scope.intervalTypeList = [
				{
					ID: 1,
					Description: $rootScope.labels.CURRENT_MONTH
				},
				{
					ID: 2,
					Description: $rootScope.labels.CURRENT_YEAR
				},
				{
					ID: 3,
					Description: $rootScope.labels.CUSTOM
				}
			];

			$scope.generateReport = function () {
				if ($scope.validationFields()) {
					formatMovimentationReportFilter();
					if ($scope.reportOptions) {
						$scope.reportOptions.filter = $scope.movimentationReportDTO;
						$scope.options.filter = $scope.movimentationReportDTO;
					}
					$scope.reportOptions.onFilterClick();
				}
			};

			$scope.validationFields = function () {
				if ($scope.movimentationReportFilter.dateId == 3) {
					if (!$scope.movimentationReportFilter.beginDate && !$scope.movimentationReportFilter.endDate) {
						$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $rootScope.labels["ERR_USR_0005"]);
						return false;
					}
				}
				return true;
			};
		};

		const formatMovimentationReportFilter = function () {
			$scope.movimentationReportDTO = {
				PeriodID: $scope.movimentationReportFilter.dateId,
				InitDate: $scope.movimentationReportFilter.beginDate
					? Vgr.date.fromDateToUTCString($scope.movimentationReportFilter.beginDate)
					: null,
				EndDate: $scope.movimentationReportFilter.endDate
					? Vgr.date.fromDateToUTCString($scope.movimentationReportFilter.endDate)
					: null,
				ShowDestinationData: $scope.movimentationReportFilter.showDestinationData,
				ProductID: $scope.movimentationReportFilter.selectedProduct
					? $scope.movimentationReportFilter.selectedProduct.code
					: null,
				ShowDailyProduction: $scope.movimentationReportFilter.showDailyProduction,
				Format: $scope.movimentationReportFilter.format,
				AreaID: $scope.movimentationReportFilter.area ? $scope.movimentationReportFilter.area.id : "",
				ShowProductionData: $scope.movimentationReportFilter.showProductionData
			};
		};

		$scope.onDisposeDataSwitchChange = function () {
			$scope.movimentationReportFilter.showDailyProduction = false;
			$scope.allSwitchesFalse();
		};

		$scope.allSwitchesFalse = function () {
			if (
				!$scope.movimentationReportFilter.showDailyProduction &&
				!$scope.movimentationReportFilter.showDestinationData &&
				!$scope.movimentationReportFilter.showProductionData
			) {
				$scope.movimentationReportFilter.showDestinationData = true;
			}
		};

		$scope.productSwitchOnChange = function () {
			$scope.movimentationReportFilter.selectedProduct = null;
			$scope.allSwitchesFalse();
		};
	}
]);
