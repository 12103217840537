angular.module("vgresiduos").factory("processService", [
	"$http",
	"accountService",
	($http, accountService) => {
		"use strict";

		function getBaseUrl(resourceKey) {
			var client = accountService.getCurrentClient();
			var organizationId = client.organization.id;
			var organizationUnitCode = client.code;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core[resourceKey]
					.replace("[ORGANIZATION]", organizationId)
					.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			);
		}

		var _getProcessList = () => {
			var req = {
				method: "get",
				url: getBaseUrl("process")
			};
			return $http(req);
		};

		return {
			getProcessList: _getProcessList
		};
	}
]);
