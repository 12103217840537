angular.module("vgresiduos").controller("IngredientFormCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		$rootScope.contentNavBarGrid = false;

		$scope.$watch("ingredientForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});

		$scope.verifyMinMaxValidity = function verifyMinMaxValidity() {
			const min = $scope.inputIngredient.minPercentage;
			const max = $scope.inputIngredient.maxPercentage;
			if (typeof min !== "undefined" && typeof max !== "undefined") {
				const minLessThanMax = min <= max;
				$scope.ingredientForm.Min.$setValidity("minLessThanMax", minLessThanMax);
				$scope.ingredientForm.Max.$setValidity("minLessThanMax", minLessThanMax);
			}
		};
	}
]);
