angular.module("vgresiduos").controller("ChangePasswordPopupCtrl", [
	"$scope",
	"$rootScope",
	"dialogService",
	"httpService",
	"accountService",
	function ($scope, $rootScope, dialogService, httpService, accountService) {
		"use strict";

		$scope.labels = $rootScope.labels;

		$scope.popupTitle = $scope.labels.RESET_PASSWORD;

		$scope.model = {};

		var cancelButton = {
			label: $scope.labels.CANCEL,
			class: "md-primary",
			click: function () {
				dialogService.cancel();
			},
			disabled: false
		};

		var confirmButton = {
			label: $scope.labels.SAVE,
			class: "md-primary md-raised",
			click: function () {
				$scope.changePasswordClick();
			},
			disabled: false
		};

		$scope.popupActionButtons = [cancelButton, confirmButton];

		$scope.changePasswordClick = function () {
			if (!validate()) {
				return;
			}

			changePassword().then(function () {
				$rootScope.$broadcast(Vgr.constants.evtShowSuccessMessage, $scope.labels.SAVE_SUCCESS_MESSAGE);
				dialogService.confirm();
			});
		};

		function changePassword() {
			$scope.loading = true;

			var dto = getDto();
			var promise = httpService.postDTOToServiceV2(accountService.changePasswordLoggedUser, dto);
			promise.then(
				function (data) {
					$scope.loading = false;
				},
				function () {
					$scope.loading = false;
				}
			);

			return promise;
		}

		function getDto() {
			return {
				OldPassword: $scope.model.currentPassword,
				NewPassword: $scope.model.newPassword
			};
		}

		function validate() {
			if (!$scope.model.currentPassword || !$scope.model.newPassword || !$scope.model.repeatNewPassword) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.VALIDATION_ERROR_MESSAGE);
				return false;
			}

			if ($scope.model.newPassword != $scope.model.repeatNewPassword) {
				$rootScope.$broadcast(Vgr.constants.evtShowErrorMessage, $scope.labels.PASSWORD_AND_CONFIRMATION_DONT_MATCH);
				return false;
			}

			return true;
		}
	}
]);
