angular.module("vgresiduos").directive("productionGrid", function () {
	return {
		scope: true,
		controller: "productionGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/product/production/productionComponents/productionGrid/productionGrid_template.html",
		bindToController: {
			product: "=?",
			productionEdit: "=",
			gridOptions: "=?"
		}
	};
});
