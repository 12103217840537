angular.module("vgresiduos").factory("stockControlPreferenceService", [
	"$http",
	"$q",
	"accountService",
	"rootScopeService",
	function ($http, $q, accountService, rootScopeService) {
		"use strict";

		const stockControlPreferenceService = {};

		const _purgeStockPreferences = function () {
			rootScopeService.setStockControlPreferences(null);
		};

		function getBaseRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;
			return (
				Vgr.constants.residuesManagementHostUrl +
				Vgr.resources.residuesManagement.stockControlPreferences
					.replace("[ORGANIZATION_ID]", organizationId)
					.replace("[ORGANIZATION_UNIT]", organizationUnitCode)
			);
		}

		const getStockControlPreferencesFromService = function () {
			const req = {
				method: "get",
				url: getBaseRequestUrl()
			};

			return $http(req);
		};

		const _getStockControlPreferences = function () {
			const deferred = $q.defer();

			const stockControlPreferences = rootScopeService.getStockControlPreferences();
			if (stockControlPreferences != null) {
				deferred.resolve(stockControlPreferences);
			} else {
				getStockControlPreferencesFromService().then(
					function (response) {
						rootScopeService.setStockControlPreferences(response.data);
						deferred.resolve(response.data);
					},
					function () {
						deferred.reject();
					}
				);
			}

			return deferred.promise;
		};

		const _hasStockControlAccess = function () {
			const deferred = $q.defer();

			_getStockControlPreferences().then(
				function (preferences) {
					if (preferences.enableControlStock) {
						deferred.resolve(Vgr.enumerations.userAccess.Enabled);
					} else {
						deferred.resolve(Vgr.enumerations.userAccess.Hidden);
					}
				},
				function () {
					deferred.reject();
				}
			);
			return deferred.promise;
		};

		stockControlPreferenceService.getStockControlPreferences = _getStockControlPreferences;
		stockControlPreferenceService.purgeStockPreferences = _purgeStockPreferences;
		stockControlPreferenceService.hasStockControlAccess = _hasStockControlAccess;

		return stockControlPreferenceService;
	}
]);
