angular.module("vgresiduos").factory("productService", [
	"$http",
	function ($http) {
		"use strict";

		const _getFISPQByProduct = function GetFISPQByProduct(productCode) {
			const req = {
				method: "post",
				data: {},
				url: Vgr.constants.newHostUrl + Vgr.resources.api.product + productCode + "/getFISPQ"
			};
			return $http(req);
		};

		const _createFISPQ = function CreateFISPQ(fispq) {
			const req = {
				method: "post",
				data: fispq,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.product + "CreateFISPQ"
			};
			return $http(req);
		};

		const _updateFISPQ = function UpdateFISPQ(fispq) {
			const req = {
				method: "post",
				data: fispq,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.product + "UpdateFISPQ"
			};
			return $http(req);
		};

		return {
			getFISPQByProduct: _getFISPQByProduct,
			createFISPQ: _createFISPQ,
			updateFISPQ: _updateFISPQ
		};
	}
]);
