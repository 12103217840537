angular.module("vgresiduos").directive("fdsr", function () {
	return {
		scope: true,
		controller: "FDSRCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/residue/fdsr/fdsr_template.html",
		bindToController: {
			model: "=",
			residueName: "="
		}
	};
});
