angular.module("vgresiduos").controller("ProductFormCtrl", [
	"$scope",
	"$rootScope",
	function ($scope, $rootScope) {
		"use strict";

		this.$onInit = function () {
			$rootScope.contentNavBarGrid = false;
			$scope.isEditing = $scope.ctrl.inputProduct.code;
		};

		$scope.$watch("productForm.$valid", function (newVal) {
			$scope.ctrl.saveOptions.valid = newVal;
		});

		$scope.getCodeLabel = function () {
			var label = $scope.labels.ID;
			if ($scope.isEditing) {
				label += "*";
			}
			return label;
		};

		$scope.onAreasChanged = function (areaCodes) {
			$scope.ctrl.inputProduct.areaCodes = areaCodes;
		};
	}
]);
