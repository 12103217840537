angular.module("vgresiduos").factory("recipientService", [
	"$http",
	"accountService",
	function ($http, accountService) {
		"use strict";

		function getBaseCoreRequestUrl() {
			const client = accountService.getCurrentClient();
			const organizationId = client.organization.id;
			const organizationUnitCode = client.code;
			return (
				Vgr.constants.coreHostUrl +
				Vgr.resources.core.organizationUnitRecipient
					.replace("[ORGANIZATION_ID]", organizationId)
					.replace("[ORGANIZATIONUNIT_CODE]", organizationUnitCode)
			);
		}

		const _listSystemRecipients = function () {
			const req = {
				method: "get",
				url: Vgr.constants.coreHostUrl + Vgr.resources.core.systemRecipients
			};
			return $http(req);
		};

		const _listClientRecipientsCore = function () {
			const req = {
				method: "get",
				url: getBaseCoreRequestUrl()
			};
			return $http(req);
		};

		const _createClientRecipientCore = function (dto) {
			const req = {
				method: "post",
				data: dto.model,
				url: getBaseCoreRequestUrl()
			};
			return $http(req);
		};

		const _updateClientRecipient = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: getBaseCoreRequestUrl() + "/" + dto.Id
			};
			return $http(req);
		};

		const _getClientRecipientById = function (dto) {
			const req = {
				method: "get",
				url: getBaseCoreRequestUrl() + "/" + dto.Id
			};
			return $http(req);
		};

		const _deleteClientRecipient = function (id) {
			const req = {
				method: "delete",
				url: getBaseCoreRequestUrl() + "/" + id
			};
			return $http(req);
		};

		const _listOrganizationRecipients = function () {
			const organizationId = accountService.getCurrentClient().organization.id;
			const req = {
				method: "get",
				url:
					Vgr.constants.coreHostUrl +
					Vgr.resources.core.organizationRecipients.replace("[ORGANIZATION_ID]", organizationId)
			};
			return $http(req);
		};

		return {
			listSystemRecipients: _listSystemRecipients,
			listClientRecipientsCore: _listClientRecipientsCore,
			createClientRecipientCore: _createClientRecipientCore,
			updateClientRecipient: _updateClientRecipient,
			getClientRecipientById: _getClientRecipientById,
			deleteClientRecipient: _deleteClientRecipient,
			listOrganizationRecipients: _listOrganizationRecipients
		};
	}
]);
