angular.module("vgresiduos").directive("permissionGroupUserGrid", function () {
	return {
		scope: true,
		controller: "PermissionGroupUserGridCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/permission/grid/permissionGroupUserGrid_template.html",
		bindToController: {
			model: "=",
			gridOptions: "="
		}
	};
});
