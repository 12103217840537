angular.module("vgresiduos").factory("residueService", [
	"$http",
	"engagementScoreService",
	"$q",
	"localStorageWithTimeoutService",
	function ($http, engagementScoreService, $q, localStorageWithTimeoutService) {
		"use strict";

		const residueClassCacheKey = Vgr.constants.cacheKeysPrefix.residueClass;
		const cacheTimeoutInMinutes = 43200;

		const _listOnuDangerousProducts = function (params) {
			let parameters = "";
			if (params) {
				parameters = $.param({
					query: params.query,
					skip: params.pageNumber - 1,
					take: params.pageSize
				});
			}

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.default + "onu-dangerous-products" + "?" + parameters
			};
			return $http(req);
		};

		const _listOnuPackingGroups = function () {
			return [
				{ Id: 1, Code: "I", Description: "I - Substâncias que apresentam alto risco" },
				{ Id: 2, Code: "II", Description: "II - Substâncias que apresentam médio risco" },
				{ Id: 3, Code: "III", Description: "III - Substâncias que apresentam baixo risco" }
			];
		};

		const _getConama313List = function GetConama313(type) {
			const cacheKey = getConama313ListCacheKey(type);
			const cachedEntities = localStorageWithTimeoutService.getFromCacheWithAutoExpiry(cacheKey);
			const deferred = $q.defer();

			if (cachedEntities) {
				deferred.resolve(cachedEntities);
			} else {
				const parameters = $.param({ type: type });

				const req = {
					method: "post",
					url: Vgr.constants.newHostUrl + Vgr.resources.api.residue + "GetConama313?" + parameters
				};
				$http(req).then(
					function (list) {
						localStorageWithTimeoutService.setInCacheWithExpiry(cacheKey, list, cacheTimeoutInMinutes, null);
						deferred.resolve(list);
					},
					function () {
						deferred.reject();
					}
				);
			}
			return deferred.promise;
		};

		function getConama313ListCacheKey(type) {
			switch (type) {
				case Vgr.enumerations.conama313.type.DangerousAndNotDangerousResidues:
					return Vgr.constants.cacheKeysPrefix.conama313List.DangerousAndNotDangerousResidues;
				case Vgr.enumerations.conama313.type.Recipient:
					return Vgr.constants.cacheKeysPrefix.conama313List.Recipient;
				case Vgr.enumerations.conama313.type.UncommonDestinatios:
					return Vgr.constants.cacheKeysPrefix.conama313List.UncommonDestinatios;
				case Vgr.enumerations.conama313.type.RecycleTreatmment:
					return Vgr.constants.cacheKeysPrefix.conama313List.RecycleTreatmment;
				case Vgr.enumerations.conama313.type.CommonDestinatios:
					return Vgr.constants.cacheKeysPrefix.conama313List.CommonDestinatios;
			}
		}

		const _getPhysicStateList = function GetPhysicStateList() {
			const req = {
				method: "post",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.residue + "GetPhysicStateList"
			};
			return $http(req);
		};

		const _generateManagementInventoryReport = function GetManagementInventoryReport(filter) {
			const req = {
				method: "POST",
				data: filter,
				url: Vgr.constants.newHostUrl + Vgr.resources.api.report + "GenerateManagementInventoryReport"
			};
			engagementScoreService.track(Vgr.trackings.bi.reports.generateManagementInventory);
			return $http(req);
		};

		const _getStoredAmountbyResidueInClient = function GetStoredAmountbyResidueInClient(dto) {
			const parameters = $.param(dto);

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.api.residue + "GetStoredAmountbyResidueInClient?" + parameters
			};

			return $http(req);
		};

		//new api

		const _getResidue = function (id) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.residues + id
			};
			return $http(req);
		};

		const _listClientResidues = function (params) {
			let parameters = "";
			if (params) {
				parameters = $.param(params);
			}

			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + "?" + parameters
			};
			return $http(req);
		};

		const _getClientResidue = function (id) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + id
			};
			return $http(req);
		};

		const _createClientResidue = function (model) {
			const req = {
				method: "post",
				data: model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues
			};
			return $http(req);
		};

		const _updateClientResidue = function (dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + dto.id
			};
			return $http(req);
		};

		const _getHommelDiamond = function (residueId) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + residueId + "/hommel-diamond"
			};
			return $http(req);
		};

		const _updateHommelDiamond = function UpdateHommelDiamond(dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + dto.id + "/hommel-diamond"
			};
			return $http(req);
		};

		const _getEmergencySheet = function (residueId) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + residueId + "/emergency-sheet"
			};
			return $http(req);
		};

		const _updateEmergencySheet = function UpdateEmergencySheet(dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + dto.id + "/emergency-sheet"
			};
			return $http(req);
		};

		const _getFDSR = function (residueId) {
			const req = {
				method: "get",
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + residueId + "/fdsr"
			};
			return $http(req);
		};

		const _updateFDSR = function UpdateFDSR(dto) {
			const req = {
				method: "put",
				data: dto.model,
				url: Vgr.constants.newHostUrl + Vgr.resources.apiV1.clientResidues + dto.id + "/fdsr"
			};
			return $http(req);
		};

		const _listResidueGroups = function () {
			return Vgr.util.getWithCache(
				Vgr.constants.coreHostUrl + Vgr.resources.core.residueGroups,
				null,
				Vgr.constants.cacheKeysPrefix.residueGroups,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		const _listResidueClassTypes = function () {
			return Vgr.util.getWithCache(
				Vgr.constants.coreHostUrl + Vgr.resources.core.residueClass,
				null,
				residueClassCacheKey,
				cacheTimeoutInMinutes,
				localStorageWithTimeoutService,
				$http,
				$q
			);
		};

		const _listResidueConversionFactorsHistory = function (dto) {
			let parameters = "";

			if (dto) {
				parameters = $.param({ measureUnitGroup: dto.measureUnitGroup });
			}

			const req = {
				method: "get",
				url:
					Vgr.constants.newHostUrl +
					Vgr.resources.apiV1.clientResidues +
					dto.id +
					"/conversion-factor-history?" +
					parameters
			};
			return $http(req);
		};

		const _getResidueQuantitiesByAreaNotControlStock = function (disposalResidueQuantities) {
			const result = [];
			if (disposalResidueQuantities) {
				for (const quantity of disposalResidueQuantities) {
					result.push({
						...quantity,
						AreaCode: GetAreaCode(quantity)
					});
				}
			}
			return result;
		};

		function GetAreaCode(quantity) {
			let areaCode;
			if (quantity.SelectedArea) {
				areaCode = quantity.SelectedArea.code;
			} else if (quantity.Area) {
				areaCode = quantity.Area.Code;
			}

			return areaCode;
		}

		return {
			generateManagementInventoryReport: _generateManagementInventoryReport,

			listOnuDangerousProducts: _listOnuDangerousProducts,
			listOnuPackingGroups: _listOnuPackingGroups,
			getConama313List: _getConama313List,
			getPhysicStateList: _getPhysicStateList,

			getStoredAmountbyResidueInClient: _getStoredAmountbyResidueInClient,

			getResidueQuantitiesByAreaNotControlStock: _getResidueQuantitiesByAreaNotControlStock,
			getResidue: _getResidue,

			listClientResidues: _listClientResidues,
			getClientResidue: _getClientResidue,
			createClientResidue: _createClientResidue,
			updateClientResidue: _updateClientResidue,

			getHommelDiamond: _getHommelDiamond,
			updateHommelDiamond: _updateHommelDiamond,
			getEmergencySheet: _getEmergencySheet,
			updateEmergencySheet: _updateEmergencySheet,
			getFDSR: _getFDSR,
			updateFDSR: _updateFDSR,

			listResidueGroups: _listResidueGroups,

			listResidueClassTypes: _listResidueClassTypes,
			listResidueConversionFactorsHistory: _listResidueConversionFactorsHistory
		};
	}
]);
