angular.module("vgresiduos").directive("permissionGroupPermissionsForm", function () {
	return {
		scope: true,
		controller: "PermissionGroupPermissionsFormCtrl",
		controllerAs: "ctrl",
		templateUrl: "views/permission/form/permissionGroupPermissionsForm_template.html",
		bindToController: {
			model: "=",
			saveOptions: "="
		}
	};
});
